/// <reference types="./copilot.d.mts" />
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $el from "../../../sketch/sketch/lustre/element.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { px } from "../../../sketch/sketch/size.mjs";
import * as $colors from "../../design_system/colors.mjs";
import * as $display from "../../design_system/typography/display.mjs";
import * as $text from "../../design_system/typography/text.mjs";
import { toList } from "../../gleam.mjs";
import * as $common from "../../view/common.mjs";

export function card(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([$s.compose($common.card()), $s.flex("1")]),
  );
}

export function card_wrapper(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.compose($common.card()),
      $s.flex("1"),
      $s.display("flex"),
      $s.gap(px(16)),
    ]),
  );
}

export function card_title(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([$s.compose($display.xs()), $s.color($colors.light.onyx)]),
  );
}

export function card_subtitle(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.compose($text.md($text.regular)),
      $s.color($colors.light.paragraph),
    ]),
  );
}

export function ai_search_title(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([$s.compose($display.md()), $s.color($colors.light.onyx)]),
  );
}

export function ai_search_subtitle(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([$s.compose($text.md($text.regular))]),
  );
}

export function icon_wrapper(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([$s.width(px(20)), $s.height(px(20))]),
  );
}

export function highlight_question(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.width(px(64)),
      $s.height(px(64)),
      $s.padding(px(12)),
      $s.background($colors.light.foam),
      $s.border_radius(px(500)),
    ]),
  );
}

export function buttons_grid(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.display("grid"),
      $s.gap(px(16)),
      $s.grid_template_rows("repeat(2, 1fr)"),
      $s.grid_template_columns("repeat(2, 1fr)"),
    ]),
  );
}

export function ai_search_disclaimer(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.compose($text.xs($text.regular)),
      $s.color($colors.light.grey_800),
      $s.text_align("center"),
    ]),
  );
}

export function history_column(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.width(px(250)),
      $s.background($colors.light.grey_100),
      $s.border_right("1px solid " + $colors.light.grey_400),
      $s.margin(px(-32)),
      $s.margin_right(px(16)),
      $s.padding(px(24)),
      $s.overflow("hidden"),
      $s.gap(px(16)),
    ]),
  );
}

export function history_day_title(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.color($colors.light.grey_800),
      $s.compose($text.sm($text.medium)),
    ]),
  );
}

export function history_question(attrs, children) {
  return $el.element(
    "a",
    attrs,
    children,
    toList([
      $s.color($colors.light.paragraph),
      $s.compose($text.xs($text.regular)),
      $s.text_decoration("none"),
    ]),
  );
}

export function question_bubble(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.compose($text.md($text.regular)),
      $s.color($colors.light.paragraph),
      $s.background($colors.light.grey_100),
      $s.align_self("end"),
      $s.padding_("10px 24px"),
      $s.border_radius(px(8)),
    ]),
  );
}

export function response_bubble(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.compose($text.md($text.regular)),
      $s.color($colors.light.paragraph),
    ]),
  );
}

export function error_response_bubble(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.compose($text.md($text.regular)),
      $s.color($colors.light.grey_800),
      $s.font_style("italic"),
      $s.white_space("pre-line"),
    ]),
  );
}

export function loading_indicator() {
  return $el.element(
    "div",
    toList([]),
    toList([$element.text("Loading…")]),
    toList([
      $s.compose($text.md($text.regular)),
      $s.color($colors.light.grey_800),
      $s.font_style("italic"),
      $s.white_space("pre-line"),
    ]),
  );
}

export function response_sources_title(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.compose($text.md($text.medium)),
      $s.text_transform("uppercase"),
      $s.color($colors.light.grey_800),
    ]),
  );
}

export function response_sources(attrs, children) {
  return $el.element("div", attrs, children, toList([]));
}

export function response_source(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.display("flex"),
      $s.gap(px(12)),
      $s.color($colors.light.grey_800),
      $s.align_items("center"),
      $s.compose($text.sm($text.medium)),
      $s.cursor("default"),
    ]),
  );
}
