/// <reference types="./overview.d.mts" />
import * as $a from "../../lustre/lustre/attribute.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $h from "../../lustre/lustre/element/html.mjs";
import * as $model from "../data/model.mjs";
import * as $colors from "../design_system/colors.mjs";
import * as $components from "../design_system/components.mjs";
import * as $button from "../design_system/components/button.mjs";
import * as $progress_circles from "../design_system/components/progress/circles.mjs";
import * as $icons from "../design_system/icons.mjs";
import * as $illustrations from "../design_system/illustrations.mjs";
import * as $pie_chart from "../elements/pie_chart.mjs";
import { toList } from "../gleam.mjs";
import * as $common from "../view/common.mjs";
import * as $proposals_table from "../view/components/proposals_table.mjs";
import * as $s from "../view/styles/overview.mjs";

function statistics() {
  return $s.section(
    toList([$a.style(toList([["align-items", "start"]]))]),
    toList([
      $s.section_title(toList([]), toList([$h.text("Win rate")])),
      $s.statistics_content_wrapper(
        toList([]),
        toList([
          $s.statistics_content(
            toList([]),
            toList([
              $pie_chart.pie_chart(
                toList([
                  new $pie_chart.Dataset(
                    toList([$colors.light.success_600, $colors.light.error_600]),
                    toList([78.2, 11.8]),
                  ),
                ]),
              ),
              $s.statistics_wrapper(
                toList([]),
                toList([
                  $s.statistics_value(
                    toList([]),
                    toList([
                      $s.statistics_label(
                        toList([]),
                        toList([
                          $s.statistics_color($colors.light.success_600),
                          $h.text("Won"),
                        ]),
                      ),
                      $h.text("78.2%"),
                    ]),
                  ),
                  $s.statistics_value(
                    toList([]),
                    toList([
                      $s.statistics_label(
                        toList([]),
                        toList([
                          $s.statistics_color($colors.light.error_600),
                          $h.text("Won"),
                        ]),
                      ),
                      $h.text("78.2%"),
                    ]),
                  ),
                  $common.separator(),
                  $s.statistics_value(
                    toList([]),
                    toList([
                      $s.statistics_colored_label(
                        $colors.light.success_800,
                        toList([]),
                        toList([$h.text("Won ARR")]),
                      ),
                      $h.text("$100,000"),
                    ]),
                  ),
                  $s.statistics_value(
                    toList([]),
                    toList([
                      $s.statistics_colored_label(
                        $colors.light.error_800,
                        toList([]),
                        toList([$h.text("Won ARR")]),
                      ),
                      $h.text("$100,000"),
                    ]),
                  ),
                ]),
              ),
            ]),
          ),
          $s.statistics_content(
            toList([]),
            toList([
              $pie_chart.pie_chart(toList([])),
              $s.statistics_wrapper(
                toList([]),
                toList([
                  $s.statistics_empty_text(
                    toList([]),
                    toList([
                      $h.text(
                        "A short text prompting the user to set things up and data will appear here.",
                      ),
                    ]),
                  ),
                ]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

function rfp_overview(model) {
  return $s.section(
    toList([]),
    toList([
      $s.section_title(toList([]), toList([$h.text("Your ongoing proposals")])),
      $s.section_content(
        toList([]),
        toList([
          $proposals_table.table(
            model.loading.proposals,
            model.proposals,
            model.users,
          ),
        ]),
      ),
      $button.secondary_link(
        toList([$a.href("/proposals")]),
        toList([$h.text("See all proposals")]),
      ),
    ]),
  );
}

export function view(model) {
  return $el.fragment(toList([rfp_overview(model)]));
}

const lorem = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.";

function onboarding_steps() {
  return $s.onboarding_wrapper(
    toList([]),
    toList([
      $h.div(
        toList([$a.style(toList([["grid-area", "stepper"]]))]),
        toList([
          $progress_circles.steps(
            toList([
              $progress_circles.step(1),
              $progress_circles.step(1),
              $progress_circles.step(1),
            ]),
          ),
        ]),
      ),
      $s.onboarding_icon(toList([]), toList([$illustrations.documents_stack()])),
      $s.onboarding_action(
        toList([]),
        toList([
          $s.onboarding_action_titles(
            toList([]),
            toList([
              $s.onboarding_action_title(
                toList([]),
                toList([$h.text("Add a new proposal")]),
              ),
              $s.onboarding_action_subtitle(
                toList([]),
                toList([$h.text(lorem)]),
              ),
            ]),
          ),
          $components.button.primary(
            toList([]),
            toList([$h.text("Add a new proposal")]),
          ),
        ]),
      ),
      $s.onboarding_skip(
        toList([]),
        toList([
          $components.button.text(
            toList([]),
            toList([
              $h.text("Skip"),
              $s.icon_wrapper(toList([]), toList([$icons.right_arrow()])),
            ]),
          ),
        ]),
      ),
    ]),
  );
}
