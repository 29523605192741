/// <reference types="./pdf.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function pdf() {
  let content = "<g clip-path=\"url(#clip0_6975_11785)\">\n<path d=\"M26 10.0001V28.0001C26 28.7367 25.4033 29.3334 24.6667 29.3334H7.33333C6.59667 29.3334 6 28.7367 6 28.0001V4.00008C6 3.26341 6.59667 2.66675 7.33333 2.66675H18.6667L26 10.0001Z\" fill=\"#FF5722\"/>\n<path d=\"M18.6666 2.66675V9.33341C18.6666 9.70141 18.9653 10.0001 19.3333 10.0001H26L18.6666 2.66675Z\" fill=\"#BE3B11\"/>\n<path d=\"M11.1654 22.75C10.9416 22.75 10.7738 22.694 10.6059 22.6381C9.99042 22.3024 9.93447 21.7988 10.0464 21.4071C10.2702 20.7357 11.5011 19.8964 13.1238 19.169C13.8511 17.8262 14.4107 16.4274 14.7464 15.2524C14.1868 14.1893 13.9071 13.1821 13.9071 12.4548C13.9071 12.0631 14.019 11.7274 14.1868 11.4476C14.4107 11.1679 14.7464 11 15.194 11C15.6976 11 16.0892 11.2798 16.2571 11.7833C16.5368 12.4548 16.369 13.6857 15.9773 15.0845C16.5368 16.0357 17.2083 16.931 17.9357 17.6024C18.9988 17.3786 19.9499 17.2667 20.5654 17.3786C21.6285 17.5464 21.7964 18.2738 21.7964 18.5536C21.7964 19.7286 20.5654 19.7286 20.1178 19.7286C19.2785 19.7286 18.4392 19.3929 17.7118 18.7774C16.369 19.1131 15.0261 19.5607 13.963 20.0643C13.4035 21.0155 12.844 21.7988 12.3404 22.2464C11.8368 22.6381 11.4452 22.75 11.1654 22.75ZM11.8368 21.1274C11.5571 21.2952 11.3333 21.4631 11.2214 21.631C11.3333 21.575 11.5571 21.4631 11.8368 21.1274ZM19.4464 18.4976C19.6702 18.5536 19.894 18.6095 20.1178 18.6095C20.4535 18.6095 20.6214 18.5536 20.6773 18.5536C20.6214 18.4976 20.2297 18.3857 19.4464 18.4976ZM15.5297 16.4833C15.3059 17.1548 14.9702 17.8821 14.6904 18.5536C15.3618 18.3298 16.0333 18.106 16.7047 17.9381C16.2571 17.4905 15.8654 16.9869 15.5297 16.4833ZM15.194 12.119C15.138 12.119 15.138 12.119 15.138 12.119C15.0821 12.175 15.0261 12.5667 15.2499 13.406C15.3059 12.7345 15.3059 12.231 15.194 12.119Z\" fill=\"white\"/>\n</g>\n<defs>\n<clipPath id=\"clip0_6975_11785\">\n<rect width=\"32\" height=\"32\" fill=\"white\"/>\n</clipPath>\n</defs>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 32 32"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
