/// <reference types="./circles.d.mts" />
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../../../gleam_stdlib/gleam/string.mjs";
import * as $element from "../../../../lustre/lustre/element.mjs";
import * as $h from "../../../../lustre/lustre/element/html.mjs";
import * as $s from "../../../../sketch/sketch.mjs";
import * as $el from "../../../../sketch/sketch/lustre/element.mjs";
import * as $size from "../../../../sketch/sketch/size.mjs";
import { px } from "../../../../sketch/sketch/size.mjs";
import * as $colors from "../../../design_system/colors.mjs";
import * as $icons from "../../../design_system/icons.mjs";
import * as $text from "../../../design_system/typography/text.mjs";
import { toList, CustomType as $CustomType } from "../../../gleam.mjs";

export class Pending extends $CustomType {}

export class Active extends $CustomType {}

export class Done extends $CustomType {}

class Step extends $CustomType {
  constructor(current_state, description) {
    super();
    this.current_state = current_state;
    this.description = description;
  }
}

export function step(state) {
  return new Step(state, new $option.None());
}

export function description(step, description) {
  return step.withFields({ description: new $option.Some(description) });
}

function view_steps(children) {
  return $el.element(
    "div",
    toList([]),
    children,
    toList([$s.display("flex"), $s.align_items("center"), $s.gap(px(16))]),
  );
}

function is_active_progress(step, expected) {
  return $bool.guard(
    step === expected,
    new Active(),
    () => {
      return $bool.guard(
        step > expected,
        new Done(),
        () => { return new Pending(); },
      );
    },
  );
}

function view_step_wrapper(state, children) {
  let id = "view_step_wrapper-" + $string.inspect(state);
  return $el.dynamic(
    "div",
    toList([]),
    children,
    id,
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.gap(px(8)),
      $s.compose($text.lg($text.medium)),
      $s.color(
        (() => {
          if (state instanceof Pending) {
            return $colors.light.grey_600;
          } else if (state instanceof Active) {
            return $colors.light.ai_800;
          } else {
            return $colors.light.white;
          }
        })(),
      ),
    ]),
  );
}

function view_description(child, state) {
  let id = "view-description-" + $string.inspect(state);
  return $el.dynamic(
    "div",
    toList([]),
    toList([$h.text(child)]),
    id,
    toList([
      $s.color(
        (() => {
          if (state instanceof Done) {
            return $colors.light.grey_600;
          } else {
            return "inherit";
          }
        })(),
      ),
    ]),
  );
}

function view_step(index, state) {
  let id = "onboarding-step-state-" + $string.inspect(state);
  let children = (() => {
    if (state instanceof Done) {
      return toList([$icons.checkmark()]);
    } else {
      return toList([$h.text($int.to_string(index))]);
    }
  })();
  return $el.dynamic(
    "div",
    toList([]),
    children,
    id,
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("center"),
      $s.width(px(36)),
      $s.height(px(36)),
      $s.property("flex-shrink", "0"),
      $s.border_radius(px(500)),
      $s.background(
        (() => {
          if (state instanceof Pending) {
            return $colors.light.grey_100;
          } else if (state instanceof Active) {
            return $colors.light.ai_100;
          } else {
            return $colors.light.grey_400;
          }
        })(),
      ),
      $s.compose($text.xl($text.medium)),
    ]),
  );
}

function view_step_description(step, index) {
  let current_state = step.current_state;
  let description$1 = step.description;
  let index$1 = index + 1;
  let state = is_active_progress(current_state, index$1);
  return view_step_wrapper(
    state,
    toList([
      (() => {
        if (description$1 instanceof $option.None) {
          return view_step(index$1, state);
        } else {
          let description$2 = description$1[0];
          return $element.fragment(
            toList([
              view_step(index$1, state),
              view_description(description$2, state),
            ]),
          );
        }
      })(),
    ]),
  );
}

function separator() {
  return $el.element(
    "div",
    toList([]),
    toList([]),
    toList([
      $s.flex_grow("1"),
      $s.flex_basis("0"),
      $s.height(px(2)),
      $s.background($colors.light.grey_400),
    ]),
  );
}

export function steps(children) {
  return view_steps(
    (() => {
      let _pipe = children;
      let _pipe$1 = $list.index_map(_pipe, view_step_description);
      return $list.intersperse(_pipe$1, separator());
    })(),
  );
}
