import app_config
import auth0/client as auth0
import auth0/client/options
import birl
import data/connector
import data/connector_settings
import data/content_library
import data/copilot_question
import data/data_point
import data/data_source
import data/model.{type Model, Model}
import data/msg.{type Msg}
import data/notification.{type Notification, Notification}
import data/proposal
import data/question.{type Question, Question}
import data/route
import data/tag
import data/ui/loading
import data/user
import design_system/components/dropdown
import form_data
import frontend/ask
import frontend/error
import frontend/error/info
import frontend/error/warn
import frontend/ffi
import gleam/bit_array
import gleam/bool
import gleam/dict
import gleam/dynamic
import gleam/fetch
import gleam/function
import gleam/http
import gleam/http/request
import gleam/io
import gleam/javascript/promise.{type Promise}
import gleam/json
import gleam/list
import gleam/option
import gleam/pair
import gleam/result
import gleam/string
import gleam/uri
import grille_pain
import grille_pain/lustre/toast
import grille_pain/toast as bread
import grille_pain/toast/level
import lustre
import lustre/effect
import mime_types
import modem
import plinth/browser/window
import plinth/javascript/global
import sentry
import sketch/lustre as sketch_lustre
import sketch/options as sketch_options
import update/connectors
import update/effects
import update/new_proposal as up_new_proposal
import utils.{to_path}
import view/layout
import view/login
import vitools

@external(javascript, "./frontend.ffi.mjs", "uuid")
fn uuid() -> String

@external(javascript, "./frontend.ffi.mjs", "reconstructXlsxAndDl")
fn reconstruct_xlsx_and_dl(
  body: BitArray,
  proposal: proposal.Proposal,
  questions: List(question.Question),
  responses: List(proposal.QuestionnaireResponse),
) -> Promise(Nil)

fn blur_active_element() {
  use _ <- effect.from()
  ffi.blur_active_element()
}

fn init_sentry() {
  vitools.get_env("SENTRY_DSN")
  |> result.map_error(fn(_) { io.print_error("No VITE_SENTRY_DSN found") })
  |> result.map(sentry.init)
}

pub fn main() {
  let assert Ok(_) = grille_pain.simple()
  let assert Ok(_) = ffi.lustre_portal_setup()
  let assert Ok(cache) = sketch_lustre.setup(sketch_options.node())
  let assert Ok(_) = dropdown.setup()
  let assert Ok(_) = init_sentry()
  let assert Ok(_dispatch) =
    view
    |> sketch_lustre.compose(cache)
    |> lustre.application(init, update, _)
    |> lustre.start("#app", Nil)
}

fn create_client(params) {
  let assert Ok(client_id) = vitools.get_env("AUTH0_CLIENT_ID")
  let assert Ok(domain) = vitools.get_env("AUTH0_DOMAIN")
  options.new(domain, client_id)
  |> options.use_refresh_token(True)
  |> options.use_refresh_token_fallback(False)
  |> options.with_authorization_params(params)
  |> auth0.create
}

fn auth_client() {
  options.authorization_params()
  |> options.audience("https://api.steerlab.ai/")
  |> options.scope("openid profile email")
  |> create_client()
}

fn invite_client(organization organization, invitation invitation) {
  options.authorization_params()
  |> options.audience("https://api.steerlab.ai/")
  |> options.scope("openid profile email")
  |> options.organization(organization)
  |> options.invitation(invitation)
  |> create_client()
}

fn init(_flags) {
  let assert Ok(route) = modem.initial_uri() |> result.map(route.from_uri)
  let modem_init =
    modem.init(fn(uri) { msg.OnRouteChange(route.from_uri(uri)) })
  let initial_effect = select_route_effect(route)
  let update_title = route.update_title(route)
  let client = auth_client()
  let eff = auth0.init(client, msg.AuthStateChanged)
  effect.batch([
    eff,
    modem_init,
    update_title,
    initial_effect,
    listen_popup_messages(),
    effect.from(fn(_) { ffi.subscribe_feed_scroll() }),
  ])
  |> pair.new(model.new(client, route), _)
}

fn listen_popup_messages() {
  use dispatch <- effect.from()
  window.add_event_listener("message", fn(event) {
    let data = ffi.get_data_from_event(event)
    case dynamic.string(data) {
      Ok("flow-ended") -> dispatch(msg.FetchUserData)
      _ -> Nil
    }
  })
}

fn select_route_effect(route: route.Route) {
  case route {
    route.Proposals(route.Show(proposal_id, _)) -> {
      use dispatch <- effect.from()
      dispatch(msg.QueryProposalQuestions(0, proposal_id))
    }
    route.Login(invitation:, organization:) -> {
      case invitation, organization {
        option.Some(invitation), option.Some(organization) -> {
          let client = invite_client(organization:, invitation:)
          auth0.login_with_redirect(client, msg.AuthStateChanged)
        }
        _, _ -> effect.none()
      }
    }
    _ -> effect.none()
  }
}

fn update(model: Model, msg: Msg) {
  case msg {
    msg.Back -> #(model, modem.back(1))

    msg.ProposalShow(id, sheet) -> {
      let new_entry = case model.route {
        route.Proposals(route.Show(pid, _)) if pid == id -> modem.replace
        _ -> modem.push
      }
      route.to_uri(route.Proposals(route.Show(id, sheet)))
      |> result.map(fn(u) { new_entry(u.path, u.query, u.fragment) })
      |> result.unwrap(effect.none())
      |> pair.new(model, _)
    }

    msg.OnProposalsQuestions(#(questions, data_sources, data_points)) -> {
      use <- require_not_modifying_question(model)
      model
      |> model.add_proposal_questions(questions)
      |> model.add_data_sources(data_sources)
      |> model.add_data_points(data_points)
      |> pair.new(effect.none())
    }

    msg.OnCopilotThreads(#(questions, data_sources, data_points)) -> {
      use <- require_not_modifying_question(model)
      model
      |> model.add_copilot_questions(questions)
      |> model.add_data_sources(data_sources)
      |> model.add_data_points(data_points)
      |> pair.new(effect.none())
    }

    msg.QueryProposalQuestions(timeout, proposal_id) -> {
      case model.route {
        route.Proposals(route.Show(prop_id, _)) if prop_id == proposal_id ->
          query_proposal_questions(model, timeout, proposal_id)
        _ -> effect.none()
      }
      |> pair.new(model, _)
    }

    msg.OnRouteChange(route) -> {
      let eff = select_route_effect(route)
      model
      |> model.update_route(route)
      |> model.empty_new_proposal
      |> model.empty_popup("all")
      |> pair.new(effect.batch([route.update_title(route), eff]))
    }

    msg.AuthStateChanged(user) ->
      case user {
        option.None -> #(model.new(model.client, model.route), effect.none())
        option.Some(#(access_token, user)) ->
          model
          |> model.update_access_token(access_token)
          |> model.update_user(user)
          |> fn(model) { #(model, fetch_user_data(model)) }
      }

    msg.ToggleMoreProposalMenu(question_id:) -> {
      let is_question_id =
        model.more_proposal_opened == option.Some(question_id)
      let more_proposal_opened = case is_question_id {
        True -> option.None
        False -> option.Some(question_id)
      }
      model.Model(..model, more_proposal_opened:)
      |> pair.new(case more_proposal_opened {
        option.None -> effect.none()
        option.Some(_) ->
          dropdown.subscribe_dom_click(msg.ClosePopups("ai-" <> question_id))
      })
    }

    msg.OnProposals(proposals) -> {
      use <- require_not_modifying_question(model)
      let proposals = list.map(proposals, fn(p) { #(p.id, p) })
      model.Model(..model, proposals:)
      |> model.mark_as_loaded(loading.proposals)
      |> pair.new(effect.none())
    }

    msg.Auth0(auth0) ->
      case auth0 {
        auth0.Authorize(_) -> #(model, effect.none())
        auth0.Authorized(_) -> #(model, effect.none())
        auth0.Login ->
          model.client
          |> auth0.login_with_redirect(msg.AuthStateChanged)
          |> pair.new(model, _)
        auth0.Logout ->
          model.client
          |> auth0.logout(msg.AuthStateChanged)
          |> pair.new(model, _)
      }

    msg.OnConnectors(msg) -> {
      use <- require_not_modifying_question(model)
      connectors.handle_on_connectors(model, msg)
    }

    msg.FetchUserData -> #(model, fetch_user_data(model))

    msg.OnNewProposal(msg) -> up_new_proposal.update(model, msg)

    msg.OnCopilotInput(content) ->
      model
      |> model.update_copilot_input(content)
      |> pair.new(effect.none())

    msg.ContentLibrary(msg) -> handle_content_library(model, msg)

    msg.UpdateProposalQuestion(question:, content:) -> {
      #(model, update_question(model, question, content))
    }

    msg.OnProposalQuestionUpdated(question:) -> {
      dict.get(model.questions, question.proposal_id)
      |> result.unwrap([])
      |> list.key_set(question.id, question)
      |> model.set_questions(model, question.proposal_id, _)
      |> pair.new(effect.none())
    }

    msg.OnCopilotQuestionUpdated(question:) ->
      model
      |> model.add_copilot_questions([question])
      |> pair.new(effect.none())

    msg.OpenLink(link: option.None) -> #(model, effect.none())
    msg.OpenLink(link: option.Some(#(filename, link))) -> #(
      model,
      open_link(model, filename, link),
    )

    msg.UpdateProposalTitle(id, name) -> {
      case list.key_find(model.proposals, id) {
        Error(_) -> #(model, effect.none())
        Ok(proposal) -> {
          proposal.Proposal(..proposal, name:)
          |> list.key_set(model.proposals, id, _)
          |> fn(proposals) { model.Model(..model, proposals:) }
          |> pair.new(effect.none())
        }
      }
    }

    msg.SaveProposalTitle(id) -> {
      [blur_active_element(), update_proposal_name(model, id)]
      |> effect.batch
      |> pair.new(model, _)
    }

    msg.DisplayToast(level:, message:) -> #(model, {
      toast.options()
      |> toast.level(level)
      |> toast.custom(message)
    })

    msg.HideToast(id) -> #(model, toast.hide(id))

    msg.Xlsx(xlsx) -> handle_xlsx(model, xlsx)

    msg.OnCopilotSubmit -> {
      let thread_id = uuid()
      route.to_uri(route.CoPilot(route.AiResponse(thread_id)))
      |> result.map(fn(u) { modem.push(u.path, u.query, u.fragment) })
      |> result.unwrap(effect.none())
      |> list.prepend([submit_copilot(model, thread_id)], _)
      |> effect.batch
      |> pair.new(model.reset_copilot_input(model), _)
    }

    msg.OnUsers(users) -> {
      use <- require_not_modifying_question(model)
      model.Model(..model, users:)
      |> model.mark_as_loaded(loading.users)
      |> pair.new(effect.none())
    }

    msg.ToggleProposalCollaboratorsPopup(question_id) -> {
      let qid = option.Some(question_id)
      let is_question = model.collaborators_proposal_opened == qid
      let collaborators_proposal_opened = case is_question {
        True -> option.None
        False -> qid
      }
      model.Model(..model, collaborators_proposal_opened:)
      |> pair.new(case collaborators_proposal_opened {
        option.None -> effect.none()
        option.Some(_) ->
          dropdown.subscribe_dom_click(msg.ClosePopups(
            "collaborator-" <> question_id,
          ))
      })
    }

    msg.AddQuestionOwner(question_id:, proposal_id:, user_id:) -> {
      let uid = option.Some(user_id)
      case model.find_question(model, proposal_id:, question_id:) {
        Error(_) -> #(model, effect.none())
        Ok(Question(owner:, ..)) if owner == uid -> #(model, effect.none())
        Ok(question) -> {
          let question = Question(..question, owner: uid, validated: False)
          let upsert = msg.UpsertQuestion(question)
          use e <- pair.map_second(update(model, upsert))
          let eff = update_question_owner(model:, question:, user_id:)
          effect.batch([e, eff])
        }
      }
    }

    msg.OnUserFunctionSelected(user_id:, function:) -> {
      let users = {
        let function = option.Some(function)
        use u <- list.map(model.users)
        use <- bool.guard(when: u.id != user_id, return: u)
        user.User(..u, function:)
      }
      model.Model(..model, users:)
      |> pair.new(update_user_function(model, user_id, function))
    }

    msg.AskAiInsights(proposal_id:, question_id:, asked:) -> {
      let question = model.find_question(model, proposal_id, question_id)
      case question {
        Error(_) -> #(model, effect.none())
        Ok(question) -> {
          case asked {
            "add-question-bank" -> {
              let answer =
                question.answer.custom
                |> option.or(question.answer.long)
                |> option.unwrap("")
              let question = question.content
              let new_qna =
                content_library.NewQna(
                  question:,
                  answer:,
                  existing_document: option.None,
                  loading: False,
                )
              content_library.ContentLibrary(..model.content_library, new_qna:)
              |> model.set_content_library(model, _)
              |> update(msg.ContentLibrary(msg.SubmitNewQna))
            }
            _ -> {
              let question = question.Question(..question, ai_processing: True)
              model
              |> model.empty_popup("ai-" <> question_id)
              |> update(msg.UpsertQuestion(question))
              |> pair.map_second(fn(e) {
                [ask_ai_rewording(model:, proposal_id:, question_id:, asked:)]
                |> list.prepend(e)
                |> effect.batch()
              })
            }
          }
        }
      }
    }

    msg.ToggleFeed -> {
      let feed_opened = !model.feed_opened
      #(model.Model(..model, feed_opened:), case feed_opened {
        True -> dropdown.subscribe_dom_click(msg.ClosePopups("feed"))
        False -> effect.none()
      })
    }

    msg.UpdateNotifications(notifications:) -> {
      use <- require_not_modifying_question(model)
      model
      |> model.set_notifications(notifications)
      |> pair.new(effect.none())
    }

    msg.UpdateNotification(notification:) ->
      model
      |> model.set_notification(notification)
      |> pair.new(effect.none())

    msg.MarkAllNotificationsAsRead ->
      model.notifications
      |> list.map(fn(n) { Notification(..n, read: True) })
      |> model.set_notifications(model, _)
      |> pair.new(mark_all_notifications_as_read(model))

    msg.GoToProposalQuestion(proposal_id:, question_id:, notification_id:) -> {
      let route = route.Proposals(route.Show(proposal_id, option.None))
      let assert Ok(uri) = route.to_uri(route)
      let notification =
        list.find(model.notifications, fn(n) { n.id == notification_id })
      model
      |> model.empty_popup("all")
      |> case notification {
        Ok(n) -> model.set_notification(_, Notification(..n, read: True))
        Error(_) -> function.identity
      }
      |> pair.new(
        effect.batch([
          modem.push(uri.path, uri.query, uri.fragment),
          effect.from(fn(_) { ffi.scroll_to_question(question_id:) }),
          mark_notification_as_read(model, notification_id),
        ]),
      )
    }

    msg.ClosePopups(id) ->
      model
      |> model.empty_popup(id)
      |> pair.new(effect.none())

    msg.MarkNotificationAsRead(notification_id:) -> {
      let eff = mark_notification_as_read(model, notification_id)
      case list.find(model.notifications, fn(n) { n.id == notification_id }) {
        Error(_) -> #(model, eff)
        Ok(notification) -> {
          Notification(..notification, read: True)
          |> model.set_notification(model, _)
          |> pair.new(eff)
        }
      }
    }

    msg.ToastUser(options:, content:) -> {
      #(model, toast.custom(options, content))
    }

    msg.UpsertQuestion(question:) -> {
      case dict.get(model.questions, question.proposal_id) {
        Error(_) ->
          [#(question.id, question)]
          |> model.set_questions(model, question.proposal_id, _)
          |> pair.new(effect.none())
        Ok(questions) -> {
          list.key_set(questions, question.id, question)
          |> model.set_questions(model, question.proposal_id, _)
          |> pair.new(effect.none())
        }
      }
    }

    msg.ValidateQuestion(question:) -> {
      let question.Question(proposal_id:, ..) = question
      let found = dict.get(model.questions, proposal_id) |> result.is_ok
      use <- bool.guard(when: !found, return: #(model, effect.none()))
      let question = question.Question(..question, validated: True)
      update(model, msg.UpsertQuestion(question))
      |> pair.map_second(fn(e) {
        effect.batch([e, mark_question_as_validated(model, question)])
      })
    }

    msg.UpdateTags(tags:) -> {
      use <- require_not_modifying_question(model)
      model.Model(..model, tags:)
      |> pair.new(effect.none())
    }

    msg.None -> #(model, effect.none())
  }
}

fn handle_content_library(model: Model, msg: msg.ContentLibrary) {
  case msg {
    msg.UpdateSource(source:) -> {
      model.content_library
      |> content_library.add_source(source)
      |> model.set_content_library(model, _)
      |> pair.new(effect.none())
    }
    msg.UpdateSources(sources:) -> {
      use <- require_not_modifying_question(model)
      model.content_library
      |> content_library.add_sources(sources)
      |> model.set_content_library(model, _)
      |> model.mark_as_loaded(loading.content_library)
      |> pair.new(effect.none())
    }
    msg.CheckSource(id:, checked:) -> {
      let handler = case checked {
        True -> content_library.select
        False -> content_library.deselect
      }
      model.content_library
      |> handler(id)
      |> content_library.close_popups
      |> model.set_content_library(model, _)
      |> pair.new(effect.none())
    }
    msg.ToggleChangeOwner -> {
      model.content_library
      |> content_library.toggle_change_owner
      |> model.set_content_library(model, _)
      |> pair.new(effect.none())
    }
    msg.ToggleChangeStatus -> {
      model.content_library
      |> content_library.toggle_change_status
      |> model.set_content_library(model, _)
      |> pair.new(effect.none())
    }
    msg.ToggleChangeTags -> {
      model.content_library
      |> content_library.toggle_change_tags
      |> model.set_content_library(model, _)
      |> pair.new(effect.none())
    }
    msg.ToggleChangeExpirationDate -> {
      model.content_library
      |> content_library.toggle_change_expiration_date
      |> model.set_content_library(model, _)
      |> pair.new(effect.none())
    }
    msg.GlobalCheckbox -> {
      let in_question_bank =
        model.route == route.ContentLibrary(route.QuestionBank)
      let selected = {
        let all_selected = case in_question_bank {
          True -> content_library.all_qna_selected(model.content_library)
          False -> content_library.all_non_qna_selected(model.content_library)
        }
        use <- bool.guard(when: all_selected, return: dict.new())
        let sources = case in_question_bank {
          True -> model.content_library.qna_sources
          False -> model.content_library.non_qna_sources
        }
        use selected, source <- list.fold(sources.all, dict.new())
        dict.insert(selected, source.id, True)
      }
      content_library.ContentLibrary(..model.content_library, selected:)
      |> content_library.close_popups
      |> model.set_content_library(model, _)
      |> pair.new(effect.none())
    }
    msg.AssignStatus(status:) -> {
      model.content_library
      |> content_library.attribute_status(status)
      |> model.set_content_library(model, _)
      |> pair.new(update_data_sources_status(model, status))
    }
    msg.AssignUser(user_id:) -> {
      model.content_library
      |> content_library.attribute_source(user_id)
      |> model.set_content_library(model, _)
      |> pair.new(update_data_sources_owner(model, user_id))
    }
    msg.AssignTag(tag:, add_remove:) -> {
      model.content_library
      |> content_library.close_popups
      |> content_library.attribute_tag(tag, add_remove)
      |> model.set_content_library(model, _)
      |> pair.new(add_remove_data_sources_tag(model, tag, add_remove))
    }
    msg.AssignExpirationDate(expiration_date:) -> {
      model.content_library
      |> content_library.attribute_expiration_date(expiration_date)
      |> model.set_content_library(model, _)
      |> update(msg.ContentLibrary(msg.ToggleChangeExpirationDate))
      |> pair.map_second(fn(e) {
        update_data_sources_expiration_date(model, expiration_date)
        |> list.prepend([e], _)
        |> effect.batch
      })
    }
    msg.SelectFilter(filter:) -> {
      let filter = option.Some(filter)
      case model.content_library.filter_selected == filter {
        True -> content_library.set_filter(_, option.None)
        False -> content_library.set_filter(_, filter)
      }
      |> function.apply1(model.content_library)
      |> model.set_content_library(model, _)
      |> pair.new(effect.none())
    }
    msg.UpdateSearch(search:) -> {
      content_library.ContentLibrary(..model.content_library, search:)
      |> model.set_content_library(model, _)
      |> pair.new(effect.none())
    }
    msg.UpdateExpirationDateInput(value:) -> {
      content_library.ContentLibrary(
        ..model.content_library,
        change_expiration_date_input: value,
      )
      |> model.set_content_library(model, _)
      |> pair.new(effect.none())
    }
    msg.UploadSources(sources:) -> {
      #(model, upload_content_library_sources(model, sources))
    }
    msg.UpdateNewQnaQuestion(question:) -> {
      let new_qna =
        content_library.NewQna(..model.content_library.new_qna, question:)
      content_library.ContentLibrary(..model.content_library, new_qna:)
      |> model.set_content_library(model, _)
      |> pair.new(effect.none())
    }
    msg.UpdateNewQnaAnswer(answer:) -> {
      let new_qna =
        content_library.NewQna(..model.content_library.new_qna, answer:)
      content_library.ContentLibrary(..model.content_library, new_qna:)
      |> model.set_content_library(model, _)
      |> pair.new(effect.none())
    }
    msg.SubmitNewQna -> {
      // TODO Optimistically update the UI
      let new_qna =
        content_library.NewQna(..model.content_library.new_qna, loading: True)
      content_library.ContentLibrary(..model.content_library, new_qna:)
      |> model.set_content_library(model, _)
      |> pair.new(upload_question_bank_question(model))
    }
    msg.QnaErrored -> {
      let new_qna =
        content_library.NewQna(..model.content_library.new_qna, loading: False)
      content_library.ContentLibrary(..model.content_library, new_qna:)
      |> model.set_content_library(model, _)
      |> update({
        msg.DisplayToast(level.Error, {
          "Internal server error. Please, retry later."
        })
      })
    }
    msg.QnaSubmitted -> {
      let modem_eff = case model.route {
        route.ContentLibrary(route.NewQuestion) -> modem.back(1)
        _ -> effect.none()
      }
      msg.DisplayToast(level.Success, "Question successfully added!")
      |> update(model, _)
      |> pair.map_second(fn(effect) { effect.batch([effect, modem_eff]) })
    }
    msg.CreateQuestion -> {
      let assert Ok(path) =
        route.to_uri(route.ContentLibrary(route.NewQuestion))
      let new_qna = content_library.init_new_qna()
      content_library.ContentLibrary(..model.content_library, new_qna:)
      |> model.set_content_library(model, _)
      |> pair.new(modem.push(path.path, path.query, path.fragment))
    }
    msg.EditQuestion(question_id:) -> {
      let source =
        list.find(model.content_library.qna_sources.all, fn(s) {
          s.id == question_id
        })
      case source {
        Error(_) -> #(model, effect.none())
        Ok(source) -> {
          let qna = source.display.qna
          let map = fn(mapper) { option.map(qna, mapper) |> option.unwrap("") }
          let question = map(fn(q) { q.question })
          let answer = map(fn(q) { q.answer })
          let new_qna =
            content_library.NewQna(
              question:,
              answer:,
              existing_document: option.Some(source),
              loading: False,
            )
          let assert Ok(path) =
            route.to_uri(route.ContentLibrary(route.NewQuestion))
          content_library.ContentLibrary(..model.content_library, new_qna:)
          |> model.set_content_library(model, _)
          |> pair.new(modem.push(path.path, path.query, path.fragment))
        }
      }
    }
  }
}

fn handle_xlsx(model: Model, msg: msg.Xlsx) {
  case msg {
    msg.DownloadXlsx(proposal_id:, toast_id:) -> {
      let proposal = model.proposals |> list.key_find(proposal_id)
      use proposal <- result.map(proposal)
      download_xlsx(model, proposal, toast_id)
    }
    msg.GenerateXlsx(proposal_id:) -> {
      let proposal = list.key_find(model.proposals, proposal_id)
      use proposal <- result.map(proposal)
      let name = "\"" <> proposal.name <> "\""
      let generating = "Generating XLSX file for proposal " <> name <> "."
      let msg = [generating, "Please, wait…"] |> string.join("\n")
      toast.options()
      |> toast.sticky()
      |> toast.notify(fn(t) { msg.Xlsx(msg.DownloadXlsx(proposal_id, t)) })
      |> toast.custom(msg)
    }
  }
  |> result.unwrap(effect.none())
  |> pair.new(model, _)
}

fn open_link(model: Model, filename: String, link: String) {
  use access_token <- effects.require_access_token(model)
  use _dispatch <- effect.from()
  let uri = ["https://api.steerlab.ai", "http://localhost"]
  let is_uploaded = list.any(uri, fn(u) { string.starts_with(link, u) })
  let link = case is_uploaded {
    False -> link
    True -> {
      [#("access_token", access_token), #("filename", filename)]
      |> list.map(pair.map_second(_, uri.percent_encode))
      |> uri.query_to_string
      |> list.wrap
      |> list.append([link], _)
      |> string.join("?")
    }
  }
  let _ = window.open(link, "_blank", "")
  Nil
}

fn ask_ai_rewording(
  model model: Model,
  proposal_id proposal_id: String,
  question_id question_id: String,
  asked asked: String,
) {
  use access_token <- effects.require_access_token(model)
  use _dispatch <- effect.from()
  let proposal_id = uri.percent_encode(proposal_id)
  let question_id = uri.percent_encode(question_id)
  let at =
    ["proposals", proposal_id, "questions", question_id, "ai"]
    |> string.join("/")
  ask.to(ask.Heimdall, at:)
  |> ask.via(method: http.Patch)
  |> ask.bearing(access_token:)
  |> ask.with(body: json.object([#("kind", json.string(asked))]))
  |> ask.run
  Nil
}

fn download_xlsx(model: Model, proposal: proposal.Proposal, toast_id: String) {
  use access_token <- effects.require_access_token(model)
  let questions =
    model.questions
    |> dict.get(proposal.id)
    |> result.unwrap([])
    |> list.map(pair.second)
  let responses = proposal.metadata.responses
  use dispatch <- effect.from()
  let url = app_config.heimdall_endpoint()
  let url = url <> "/proposals/" <> proposal.id <> "/export"
  let assert Ok(request) = request.to(url)
  let authorization = "Bearer " <> access_token
  request
  |> request.set_header("authorization", authorization)
  |> fetch.send
  |> promise.try_await(fetch.read_bytes_body)
  |> promise.map(result.map_error(_, with: error.FetchError))
  |> promise.try_await(fn(response) {
    let body = response.body
    reconstruct_xlsx_and_dl(body, proposal, questions, responses)
    |> promise.map(Ok)
  })
  |> promise.map(fn(_dyn) {
    dispatch(msg.HideToast(toast_id))
    let msg = "File downloaded successfully!"
    global.set_timeout(1000, fn() {
      dispatch(msg.DisplayToast(level.Success, msg))
    })
    Ok(Nil)
  })
  |> promise.rescue(fn(dyn) {
    sentry.capture(dyn)
    dispatch(msg.HideToast(toast_id))
    let msg = "Impossible to download the file. Please, retry later."
    global.set_timeout(1000, fn() {
      dispatch(msg.DisplayToast(level.Error, msg))
    })
    Ok(Nil)
  })
  Nil
}

fn query_proposal_questions(model: Model, timeout: Int, proposal_id: String) {
  case model.access_token {
    option.None -> {
      use dispatch <- effect.from()
      global.set_timeout(timeout, fn() {
        msg.QueryProposalQuestions(1000, proposal_id)
        |> dispatch
      })
      Nil
    }
    option.Some(access_token) -> {
      use dispatch <- effect.from()
      let at = string.join(["proposals", proposal_id, "questions"], "/")
      ask.to(ask.Heimdall, at:)
      |> ask.bearing(access_token:)
      |> ask.expect(format: dynamic.decode3(
        fn(a, b, c) { #(a, b, c) },
        dynamic.field("questions", dynamic.list(question.decode)),
        dynamic.field(
          "data_sources",
          dynamic.dict(dynamic.string, dynamic.list(data_source.decode)),
        ),
        dynamic.field(
          "data_points",
          dynamic.dict(dynamic.string, dynamic.list(data_point.decode)),
        ),
      ))
      |> ask.notify(call: fn(q) { dispatch(msg.OnProposalsQuestions(q)) })
      |> ask.run
      global.set_timeout(15_000, fn() {
        msg.QueryProposalQuestions(0, proposal_id)
        |> dispatch
      })
      Nil
    }
  }
}

fn update_question(model: Model, question: question.Question, content: String) {
  let content = string.trim(content)
  let answer = question.answer.custom |> option.or(question.answer.long)
  let is_identical = answer == option.Some(content)
  use <- bool.guard(when: is_identical, return: effect.none())
  use access_token <- effects.require_access_token(model)
  use dispatch <- effect.from()
  let at =
    to_path(["proposals", question.proposal_id, "questions", question.id])
  ask.to(ask.Heimdall, at:)
  |> ask.bearing(access_token:)
  |> ask.via(method: http.Patch)
  |> ask.with(body: json.object([#("answer", json.string(content))]))
  |> ask.expect(format: question.decode)
  |> ask.notify(call: fn(q) { dispatch(msg.OnProposalQuestionUpdated(q)) })
  |> ask.run
  Nil
}

fn update_proposal_name(model: Model, id: String) {
  let proposal = list.key_find(model.proposals, id)
  use access_token <- effects.require_access_token(model)
  case proposal {
    Error(_) -> effect.none()
    Ok(proposal) -> {
      use dispatch <- effect.from()
      let at = to_path(["proposals", id])
      ask.to(ask.Heimdall, at:)
      |> ask.bearing(access_token:)
      |> ask.via(method: http.Patch)
      |> ask.with(body: json.object([#("proposal", proposal.encode(proposal))]))
      |> ask.expect(format: question.decode)
      |> ask.notify(call: fn(q) { dispatch(msg.OnProposalQuestionUpdated(q)) })
      |> ask.run
      Nil
    }
  }
}

fn update_data_sources_owner(model: Model, user_id: String) {
  use access_token <- effects.require_access_token(model)
  let data_sources = {
    use ids, key, value <- dict.fold(model.content_library.selected, [])
    use <- bool.guard(when: !value, return: ids)
    [key, ..ids]
  }
  use dispatch <- effect.from()
  use data_source_id <- list.each(data_sources)
  let at = to_path(["content-library", data_source_id])
  ask.to(ask.Heimdall, at:)
  |> ask.bearing(access_token:)
  |> ask.via(method: http.Patch)
  |> ask.expect(format: data_source.decode)
  |> ask.with(body: json.object([#("owner_id", json.string(user_id))]))
  |> ask.notify(call: fn(s) {
    dispatch(msg.ContentLibrary(msg.UpdateSource(s)))
  })
  |> ask.run
}

fn update_data_sources_expiration_date(model: Model, expiration_date: birl.Time) {
  use access_token <- effects.require_access_token(model)
  let data_sources = {
    use ids, key, value <- dict.fold(model.content_library.selected, [])
    use <- bool.guard(when: !value, return: ids)
    [key, ..ids]
  }
  use dispatch <- effect.from()
  use data_source_id <- list.each(data_sources)
  let at = to_path(["content-library", data_source_id])
  ask.to(ask.Heimdall, at:)
  |> ask.bearing(access_token:)
  |> ask.via(method: http.Patch)
  |> ask.expect(format: data_source.decode)
  |> ask.with(
    body: json.object([
      #("expiration_date", json.string(birl.to_iso8601(expiration_date))),
    ]),
  )
  |> ask.notify(call: fn(s) {
    dispatch(msg.ContentLibrary(msg.UpdateSource(s)))
  })
  |> ask.run
}

fn add_remove_data_sources_tag(
  model: Model,
  tag: tag.Tag,
  method: msg.AddRemove,
) {
  use access_token <- effects.require_access_token(model)
  use _dispatch <- effect.from()
  let selected =
    dict.to_list(model.content_library.selected)
    |> list.filter(fn(a) { a.1 })
    |> list.map(fn(a) { a.0 })
  let tags = {
    use source_id <- list.map(selected)
    json.object([
      #("tag_id", json.string(tag.id)),
      #("source_id", json.string(source_id)),
    ])
  }
  let method = case method {
    msg.Add -> http.Post
    msg.Remove -> http.Delete
  }
  let at = to_path(["content-library", "tags"])
  ask.to(ask.Heimdall, at:)
  |> ask.via(method:)
  |> ask.bearing(access_token:)
  |> ask.with(body: json.preprocessed_array(tags))
  |> ask.run
  Nil
}

fn update_question_owner(
  model model: Model,
  question question: Question,
  user_id user_id: String,
) {
  use access_token <- effects.require_access_token(model)
  use dispatch <- effect.from()
  let Question(proposal_id:, id:, ..) = question
  let at = to_path(["proposals", proposal_id, "questions", id, "owner"])
  ask.to(ask.Heimdall, at:)
  |> ask.bearing(access_token:)
  |> ask.via(method: http.Patch)
  |> ask.expect(format: data_source.decode)
  |> ask.with(body: json.object([#("owner_id", json.string(user_id))]))
  |> ask.notify(call: fn(s) {
    dispatch(msg.ContentLibrary(msg.UpdateSource(s)))
  })
  |> ask.run
  Nil
}

fn update_user_function(model: Model, user_id: String, function: user.Function) {
  use access_token <- effects.require_access_token(model)
  use org_id <- effects.require_org_id(model)
  use _dispatch <- effect.from()
  let at = to_path(["organizations", org_id, "members", user_id])
  ask.to(ask.Heimdall, at:)
  |> ask.bearing(access_token:)
  |> ask.via(method: http.Patch)
  |> ask.expect(format: dynamic.dynamic)
  |> ask.with(
    body: json.object([#("function", user.encode_function(function))]),
  )
  |> ask.run
  Nil
}

fn update_data_sources_status(model: Model, status: data_source.Status) {
  use access_token <- effects.require_access_token(model)
  let data_sources = {
    use ids, key, value <- dict.fold(model.content_library.selected, [])
    use <- bool.guard(when: !value, return: ids)
    [key, ..ids]
  }
  use dispatch <- effect.from()
  use data_source_id <- list.each(data_sources)
  let at = to_path(["content-library", data_source_id])
  ask.to(ask.Heimdall, at:)
  |> ask.bearing(access_token:)
  |> ask.via(method: http.Patch)
  |> ask.expect(format: data_source.decode)
  |> ask.with(
    body: json.object([
      #("status", json.string(data_source.status_to_string(status))),
    ]),
  )
  |> ask.notify(call: fn(s) {
    dispatch(msg.ContentLibrary(msg.UpdateSource(s)))
  })
  |> ask.run
}

fn submit_copilot(model: Model, thread_id: String) {
  let input = model.copilot_input
  use access_token <- effects.require_access_token(model)
  use org_id <- effects.require_org_id(model)
  use user_id <- effects.require_user_id(model)
  use dispatch <- effect.from()
  let question =
    copilot_question.CopilotQuestion(
      id: "",
      thread_id:,
      display: copilot_question.Display(score: option.None),
      content: input.value,
      user_id:,
      answer: copilot_question.Answer(
        short: option.None,
        long: option.None,
        custom: option.None,
        yes_no: option.None,
      ),
      data_points_id: [],
      org_id:,
      loading: True,
      created_at: birl.utc_now(),
      ai_processing: True,
    )
  let at = to_path(["copilot", thread_id])
  ask.to(ask.Heimdall, at:)
  |> ask.bearing(access_token:)
  |> ask.via(method: http.Post)
  |> ask.with(
    body: json.object([#("question", copilot_question.encode(question))]),
  )
  |> ask.expect(format: copilot_question.decode)
  |> ask.notify(call: fn(q) { dispatch(msg.OnCopilotQuestionUpdated(q)) })
  |> ask.run
  Nil
}

fn fetch_user_data(model: Model) {
  effect.batch([
    fetch_proposals(model),
    fetch_copilot_questions(model),
    fetch_connectors_status(model),
    fetch_content_library(model),
    fetch_organization_members(model),
    fetch_connectors_settings(model),
    fetch_notifications(model),
    fetch_tags(model),
    effect.from(fn(dispatch) {
      global.set_timeout(5000, fn() { dispatch(msg.FetchUserData) })
      Nil
    }),
  ])
}

fn fetch_proposals(model: Model) {
  use access_token <- effects.require_access_token(model)
  use dispatch <- effect.from()
  ask.to(ask.Heimdall, at: to_path(["proposals"]))
  |> ask.bearing(access_token:)
  |> ask.expect(format: dynamic.list(proposal.decode))
  |> ask.notify(call: fn(p) { dispatch(msg.OnProposals(p)) })
  |> ask.run
  Nil
}

fn fetch_copilot_questions(model: Model) {
  use access_token <- effects.require_access_token(model)
  use dispatch <- effect.from()
  let decode_data_sources =
    dynamic.dict(dynamic.string, dynamic.list(data_source.decode))
  let decode_data_points =
    dynamic.dict(dynamic.string, dynamic.list(data_point.decode))
  ask.to(ask.Heimdall, at: to_path(["copilot"]))
  |> ask.bearing(access_token:)
  |> ask.expect(format: dynamic.decode3(
    fn(a, b, c) { #(a, b, c) },
    dynamic.field("questions", dynamic.list(copilot_question.decode)),
    dynamic.field("data_sources", decode_data_sources),
    dynamic.field("data_points", decode_data_points),
  ))
  |> ask.notify(call: fn(t) { dispatch(msg.OnCopilotThreads(t)) })
  |> ask.run
  Nil
}

fn fetch_connectors_status(model: Model) {
  use access_token <- effects.require_access_token(model)
  use dispatch <- effect.from()
  use connector <- list.each([connector.GoogleDrive, connector.Confluence])
  let on = fn(m) { dispatch(msg.OnConnectors(msg.UpdateFetched(connector, m))) }
  ask.to(ask.Loki, at: to_path(["registered"]))
  |> ask.bearing(access_token:)
  |> ask.query("connector", connector.to_string(connector))
  |> ask.expect(format: dynamic.field("registered", dynamic.bool))
  |> ask.notify(call: on)
  |> ask.run
}

fn fetch_content_library(model: Model) {
  use access_token <- effects.require_access_token(model)
  use dispatch <- effect.from()
  ask.to(ask.Heimdall, at: to_path(["content-library"]))
  |> ask.bearing(access_token:)
  |> ask.expect(format: dynamic.list(data_source.decode))
  |> ask.notify(call: fn(d) {
    dispatch(msg.ContentLibrary(msg.UpdateSources(d)))
  })
  |> ask.run
  Nil
}

fn fetch_organization_members(model: Model) {
  use access_token <- effects.require_access_token(model)
  use org_id <- effects.require_org_id(model)
  use dispatch <- effect.from()
  let at = to_path(["organizations", org_id, "members"])
  ask.to(ask.Heimdall, at:)
  |> ask.bearing(access_token:)
  |> ask.expect(format: dynamic.field("users", dynamic.list(user.decode)))
  |> ask.notify(call: fn(users) { dispatch(msg.OnUsers(users)) })
  |> ask.run
  Nil
}

fn fetch_connectors_settings(model: Model) {
  use access_token <- effects.require_access_token(model)
  use org_id <- effects.require_org_id(model)
  let is_admin = list.contains(model.permissions, "admin:organization")
  use <- bool.guard(when: !is_admin, return: effect.none())
  use dispatch <- effect.from()
  let at = to_path(["organizations", org_id, "connectors-settings"])
  ask.to(ask.Heimdall, at:)
  |> ask.bearing(access_token:)
  |> ask.expect(format: dynamic.list(connector_settings.decode))
  |> ask.notify(call: fn(d) {
    dispatch(msg.OnConnectors(msg.UpdateSettings(d)))
  })
  |> ask.run
  Nil
}

fn fetch_notifications(model: Model) {
  use access_token <- effects.require_access_token(model)
  use dispatch <- effect.from()
  let at = to_path(["notifications"])
  ask.to(ask.Heimdall, at:)
  |> ask.bearing(access_token:)
  |> ask.expect(format: dynamic.list(notification.decode))
  |> ask.notify(call: fn(n) { dispatch(msg.UpdateNotifications(n)) })
  |> ask.run
  Nil
}

fn fetch_tags(model: Model) {
  use access_token <- effects.require_access_token(model)
  use dispatch <- effect.from()
  ask.to(ask.Heimdall, at: to_path(["tags"]))
  |> ask.via(http.Get)
  |> ask.bearing(access_token:)
  |> ask.expect(dynamic.list(tag.decode))
  |> ask.notify(call: fn(t) { dispatch(msg.UpdateTags(t)) })
  |> ask.run
  Nil
}

fn mark_notification_as_read(model: Model, notification_id) {
  use access_token <- effects.require_access_token(model)
  use dispatch <- effect.from()
  let at = to_path(["notifications", notification_id])
  ask.to(ask.Heimdall, at:)
  |> ask.via(method: http.Patch)
  |> ask.bearing(access_token:)
  |> ask.expect(format: notification.decode)
  |> ask.notify(call: fn(n) { dispatch(msg.UpdateNotification(n)) })
  |> ask.run
  Nil
}

fn mark_all_notifications_as_read(model: Model) {
  use access_token <- effects.require_access_token(model)
  use _dispatch <- effect.from()
  let at = to_path(["notifications"])
  ask.to(ask.Heimdall, at:)
  |> ask.via(method: http.Patch)
  |> ask.bearing(access_token:)
  |> ask.run
  Nil
}

fn mark_question_as_validated(model: Model, question: question.Question) {
  use access_token <- effects.require_access_token(model)
  use dispatch <- effect.from()
  let question.Question(proposal_id:, id:, ..) = question
  let at = to_path(["proposals", proposal_id, "questions", id, "validate"])
  ask.to(ask.Heimdall, at:)
  |> ask.via(method: http.Patch)
  |> ask.bearing(access_token:)
  |> ask.expect(format: question.decode)
  |> ask.notify(call: fn(_n) { dispatch(msg.UpsertQuestion(question:)) })
  |> ask.run
  Nil
}

type FileStatus {
  Unprocessable
  TooLarge
  Correct
}

fn upload_question_bank_question(model: Model) {
  use access_token <- effects.require_access_token(model)
  use dispatch <- effect.from()
  let at = to_path(["question-bank"])
  ask.to(ask.Heimdall, at:)
  |> ask.via(http.Post)
  |> ask.bearing(access_token:)
  |> ask.with(
    body: json.object([
      #("question", json.string(model.content_library.new_qna.question)),
      #("answer", json.string(model.content_library.new_qna.answer)),
      #("document_id", {
        model.content_library.new_qna.existing_document
        |> option.map(fn(d) { d.document_id })
        |> json.nullable(json.string)
      }),
    ]),
  )
  |> ask.notify(fn(_) { dispatch(msg.ContentLibrary(msg.QnaSubmitted)) })
  |> ask.error(fn(_) { dispatch(msg.ContentLibrary(msg.QnaErrored)) })
  |> ask.run
  Nil
}

fn upload_content_library_sources(model: Model, sources: List(dynamic.Dynamic)) {
  use access_token <- effects.require_access_token(model)
  let sources =
    list.map(sources, ffi.file_to_bit_array)
    |> promise.await_list
    |> promise.map(list.zip(sources, _))
  use dispatch, files <- effects.from_promise(sources)
  use status, sources <- dict.each({
    list.group(files, fn(file) {
      let #(source, blob) = file
      let is_processable =
        ffi.file_mimetype(source)
        |> result.then(mime_types.from_string)
        |> result.map(mime_types.is_processable)
        |> fn(b) { b == Ok(True) }
      use <- bool.guard(when: !is_processable, return: Unprocessable)
      let size_25_mb = 25 * 1024 * 1024
      let is_too_huge = bit_array.byte_size(blob) >= size_25_mb
      use <- bool.guard(when: is_too_huge, return: TooLarge)
      Correct
    })
  })
  use <- bool.guard(when: list.is_empty(sources), return: Nil)
  case status {
    Unprocessable -> warn.unsupported_files(dispatch, list.length(sources))
    TooLarge -> warn.max_file_size(dispatch, list.length(sources))
    Correct -> {
      let toast_id =
        bread.options()
        |> bread.sticky
        |> bread.custom("Uploading files. This may take a while…")
      list.map(sources, fn(source) {
        let #(source, blob) = source
        let file_name = ffi.file_name(source) |> result.unwrap("")
        let mime_type = ffi.file_mimetype(source) |> result.unwrap("")
        ask.to(ask.Heimdall, at: to_path(["content-library"]))
        |> ask.via(method: http.Post)
        |> ask.bearing(access_token:)
        |> ask.data(form_data: {
          form_data.new()
          |> form_data.append("name", file_name)
          |> form_data.append("mime-type", mime_type)
          |> form_data.append_bits("blob", blob)
        })
        |> ask.run
      })
      |> ffi.all_settled
      |> promise.tap(fn(results) {
        bread.hide(toast_id)
        let total = list.length(results)
        let are_errors =
          results
          |> list.filter_map(dynamic.field("status", dynamic.string))
          |> list.filter(fn(s) { s == "rejected" })
          |> list.length
        case are_errors {
          0 -> info.success_uploading(dispatch, total)
          count if count != total -> {
            info.success_uploading(dispatch, total - count)
            warn.upload_error(dispatch, count)
          }
          count -> warn.upload_error(dispatch, count)
        }
      })
      Nil
    }
  }
}

fn view(model: Model) {
  case model.route, model.is_connected(model) {
    route.Login(..), _ -> login.invite()
    _, False -> login.view()
    _, True -> layout.view(model)
  }
}

fn require_not_modifying_question(model: Model, next) {
  case ffi.get_active_element_nearest_id() {
    Ok("question" <> _) -> #(model, effect.none())
    Ok(_) | Error(_) -> next()
  }
}
