/// <reference types="./toast.d.mts" />
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../gleam_stdlib/gleam/option.mjs";
import * as $lustre from "../../lustre/lustre.mjs";
import { CustomType as $CustomType } from "../gleam.mjs";
import { uuid } from "../grille_pain.ffi.mjs";
import * as $msg from "../grille_pain/internals/data/msg.mjs";
import * as $ffi from "../grille_pain/internals/ffi.mjs";
import * as $level from "../grille_pain/toast/level.mjs";

class Options extends $CustomType {
  constructor(timeout, level, sticky) {
    super();
    this.timeout = timeout;
    this.level = level;
    this.sticky = sticky;
  }
}

export function options() {
  return new Options(new None(), new None(), false);
}

export function timeout(options, timeout) {
  return options.withFields({ timeout: new Some(timeout) });
}

export function sticky(options) {
  return options.withFields({ sticky: true });
}

export function level(options, level) {
  return options.withFields({ level: new Some(level) });
}

function dispatch_toast(options, message) {
  let grille_pain_dispatch = $ffi.dispatcher();
  let timeout$1 = options.timeout;
  let level$1 = options.level;
  let sticky$1 = options.sticky;
  let level$2 = $option.unwrap(level$1, new $level.Standard());
  let uuid$1 = uuid();
  let _pipe = new $msg.NewToast(uuid$1, message, level$2, timeout$1, sticky$1);
  let _pipe$1 = $lustre.dispatch(_pipe);
  grille_pain_dispatch(_pipe$1)
  return uuid$1;
}

export function info(content) {
  let _pipe = options();
  let _pipe$1 = level(_pipe, new $level.Info());
  return dispatch_toast(_pipe$1, content);
}

export function success(content) {
  let _pipe = options();
  let _pipe$1 = level(_pipe, new $level.Success());
  return dispatch_toast(_pipe$1, content);
}

export function error(content) {
  let _pipe = options();
  let _pipe$1 = level(_pipe, new $level.Error());
  return dispatch_toast(_pipe$1, content);
}

export function toast(content) {
  let _pipe = options();
  let _pipe$1 = level(_pipe, new $level.Standard());
  return dispatch_toast(_pipe$1, content);
}

export function warning(content) {
  let _pipe = options();
  let _pipe$1 = level(_pipe, new $level.Warning());
  return dispatch_toast(_pipe$1, content);
}

export function custom(options, content) {
  return dispatch_toast(options, content);
}

export function hide(id) {
  let grille_pain_dispatch = $ffi.dispatcher();
  let _pipe = new $msg.ExternalHideToast(id);
  let _pipe$1 = $lustre.dispatch(_pipe);
  return grille_pain_dispatch(_pipe$1);
}
