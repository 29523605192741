/// <reference types="./right_arrow.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import { attribute } from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import * as $svg from "../../../lustre/lustre/element/svg.mjs";
import { toList } from "../../gleam.mjs";

export function right_arrow() {
  return $html.svg(
    toList([
      attribute("xmlns", "http://www.w3.org/2000/svg"),
      attribute("fill", "none"),
      attribute("viewBox", "0 0 20 20"),
    ]),
    toList([
      $svg.path(
        toList([
          attribute("fill", "currentColor"),
          attribute(
            "d",
            "M13.4766 9.16664L9.00665 4.69664L10.185 3.51831L16.6666 9.99998L10.185 16.4816L9.00665 15.3033L13.4766 10.8333H3.33331V9.16664H13.4766Z",
          ),
        ]),
      ),
    ]),
  );
}
