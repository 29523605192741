/// <reference types="./toast.d.mts" />
import * as $birl from "../../../../birl/birl.mjs";
import * as $shadow from "../../../../plinth/plinth/browser/shadow.mjs";
import { CustomType as $CustomType } from "../../../gleam.mjs";
import * as $ffi from "../../../grille_pain/internals/ffi.mjs";
import * as $level from "../../../grille_pain/toast/level.mjs";

export class Toast extends $CustomType {
  constructor(external_id, id, sticky, message, displayed, running, remaining, last_schedule, iteration, bottom, level, animation_duration) {
    super();
    this.external_id = external_id;
    this.id = id;
    this.sticky = sticky;
    this.message = message;
    this.displayed = displayed;
    this.running = running;
    this.remaining = remaining;
    this.last_schedule = last_schedule;
    this.iteration = iteration;
    this.bottom = bottom;
    this.level = level;
    this.animation_duration = animation_duration;
  }
}

export function new$(external_id, id, message, level, remaining, sticky, root) {
  return new Toast(
    external_id,
    id,
    sticky,
    message,
    false,
    false,
    remaining,
    $birl.utc_now(),
    0,
    $ffi.compute_bottom_position(root),
    level,
    remaining,
  );
}

export function running_to_string(running) {
  if (running) {
    return "running";
  } else {
    return "paused";
  }
}
