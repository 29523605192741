/// <reference types="./view.d.mts" />
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $function from "../../../gleam_stdlib/gleam/function.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import * as $event from "../../../lustre/lustre/event.mjs";
import * as $sketch from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { px } from "../../../sketch/sketch/size.mjs";
import { toList } from "../../gleam.mjs";
import * as $model from "../../grille_pain/internals/data/model.mjs";
import { Model } from "../../grille_pain/internals/data/model.mjs";
import * as $msg from "../../grille_pain/internals/data/msg.mjs";
import { HideToast, ResumeToast, StopToast } from "../../grille_pain/internals/data/msg.mjs";
import * as $toast from "../../grille_pain/internals/data/toast.mjs";
import * as $colors from "../../grille_pain/internals/view/colors.mjs";
import * as $progress_bar from "../../grille_pain/internals/view/progress_bar.mjs";
import * as $level from "../../grille_pain/toast/level.mjs";

function wrapper_position_style(toast) {
  let min_bot = $int.max(0, toast.bottom);
  let _pipe = toList([
    "toast",
    $bool.to_string(toast.displayed),
    $int.to_string(min_bot),
  ]);
  let _pipe$1 = $string.join(_pipe, "-");
  let _pipe$2 = $sketch.dynamic(
    _pipe$1,
    toList([
      $sketch.padding(px(12)),
      $sketch.position("fixed"),
      $sketch.top(px(min_bot)),
      $sketch.transition("right 0.7s, top 0.7s"),
      $sketch.z_index(1_000_000),
      (() => {
        let $ = toast.displayed;
        if ($) {
          return $sketch.right(px(0));
        } else {
          return $sketch.right_(
            "calc(-1 * var(--grille_pain-width, 320px) - 100px)",
          );
        }
      })(),
    ]),
  );
  return $sketch.to_lustre(_pipe$2);
}

function wrapper_dom_classes(toast) {
  let displayed = (() => {
    let $ = toast.displayed;
    if ($) {
      return "visible";
    } else {
      return "hidden";
    }
  })();
  return $attribute.classes(
    toList([
      ["grille_pain-toast", true],
      ["grille_pain-toast-" + $int.to_string(toast.id), true],
      ["grille_pain-toast-" + displayed, true],
    ]),
  );
}

function toast_class() {
  let _pipe = $sketch.class$(
    toList([
      $sketch.display("flex"),
      $sketch.flex_direction("column"),
      $sketch.width_("var(--grille_pain-toast-width, 320px)"),
      $sketch.min_height_("var(--grille_pain-toast-min-height, 64px)"),
      $sketch.max_height_("var(--grille_pain-toast-max-height, 800px)"),
      $sketch.border_radius_("var(--grille_pain-toast-border-radius, 6px)"),
      $sketch.box_shadow("0px 4px 12px rgba(0, 0, 0, 0.1)"),
      $sketch.overflow("hidden"),
      $sketch.cursor("pointer"),
    ]),
  );
  return $sketch.to_lustre(_pipe);
}

function toast_colors(level) {
  let $ = $colors.from_level(level);
  let background = $[0];
  let text_color = $[1];
  let level$1 = $level.to_string(level);
  let id = $string.join(toList(["grille_pain", level$1]), "-");
  return $sketch.to_lustre(
    $sketch.dynamic(
      id,
      toList([
        $sketch.background(
          ((("var(--grille_pain-" + level$1) + "-background, ") + background) + ")",
        ),
        $sketch.color(
          ((("var(--grille_pain-" + level$1) + "-text-color, ") + text_color) + ")",
        ),
      ]),
    ),
  );
}

function view_toast(toast, children) {
  return $function.flip($html.div)(
    children,
    toList([
      toast_colors(toast.level),
      toast_class(),
      $event.on_mouse_enter(new StopToast(toast.id)),
      $event.on_mouse_leave(new ResumeToast(toast.id)),
    ]),
  );
}

function text_wrapper() {
  let _pipe = $sketch.class$(
    toList([
      $sketch.display("flex"),
      $sketch.align_items("center"),
      $sketch.flex("1"),
      $sketch.padding_("8px 16px"),
      $sketch.font_size(px(14)),
    ]),
  );
  return $sketch.to_lustre(_pipe);
}

function view_toast_wrapper(toast) {
  let on_hide = (() => {
    let $ = toast.sticky;
    if (!$) {
      return $event.on_click(new HideToast(toast.id, toast.iteration));
    } else {
      return $attribute.none();
    }
  })();
  return $html.div(
    toList([wrapper_position_style(toast), wrapper_dom_classes(toast)]),
    toList([
      view_toast(
        toast,
        toList([
          $html.div(
            toList([text_wrapper(), on_hide]),
            toList([$html.text(toast.message)]),
          ),
          (() => {
            let $ = toast.sticky;
            if (!$) {
              return $progress_bar.view(toast);
            } else {
              return $element.none();
            }
          })(),
        ]),
      ),
    ]),
  );
}

export function view(model) {
  let toasts = model.toasts;
  return $element.keyed(
    (_capture) => {
      return $html.div(toList([$attribute.class$("grille-pain")]), _capture);
    },
    $list.map(
      toasts,
      (toast) => {
        let id = $int.to_string(toast.id);
        return [id, view_toast_wrapper(toast)];
      },
    ),
  );
}
