/// <reference types="./add.d.mts" />
import * as $birl from "../../../birl/birl.mjs";
import * as $spreadsheet from "../../../common/data/spreadsheet.mjs";
import * as $user from "../../../common/data/user.mjs";
import * as $decipher from "../../../decipher/decipher.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $function from "../../../gleam_stdlib/gleam/function.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $mime_types from "../../../mime_types/mime_types.mjs";
import * as $sketch from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import * as $model from "../../data/model.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $new_proposal from "../../data/ui/new_proposal.mjs";
import * as $colors from "../../design_system/colors.mjs";
import * as $button from "../../design_system/components/button.mjs";
import * as $dropdown from "../../design_system/components/dropdown.mjs";
import * as $input from "../../design_system/components/input.mjs";
import * as $layout from "../../design_system/components/layout.mjs";
import * as $pp from "../../design_system/components/profile_picture.mjs";
import * as $progress_circles from "../../design_system/components/progress/circles.mjs";
import * as $icons from "../../design_system/icons.mjs";
import * as $illustrations from "../../design_system/illustrations.mjs";
import * as $display from "../../design_system/typography/display.mjs";
import * as $text from "../../design_system/typography/text.mjs";
import { fromCharcode as from_charcode } from "../../frontend.ffi.mjs";
import { Ok, toList, prepend as listPrepend, makeError, isEqual } from "../../gleam.mjs";
import * as $s from "../../view/styles/proposals.mjs";

function center(children) {
  return $layout.column(
    toList([
      $layout.align("center"),
      $layout.justify("center"),
      $layout.flex("1"),
      $layout.gap(12),
    ]),
    toList([]),
    children,
  );
}

function loading() {
  return center(
    toList([
      $h.div(
        toList([$sketch.to_lustre($display.md())]),
        toList([$h.text("Loading…")]),
      ),
      $h.div(
        toList([$sketch.to_lustre($text.md($text.regular))]),
        toList([$h.text("Please wait")]),
      ),
    ]),
  );
}

function update_date(content) {
  let parts = (() => {
    let _pipe = content;
    let _pipe$1 = $string.split(_pipe, "-");
    let _pipe$2 = $list.map(_pipe$1, $int.parse);
    return $result.all(_pipe$2);
  })();
  if (parts.isOk() && parts[0].hasLength(3)) {
    let year = parts[0].head;
    let month = parts[0].tail.head;
    let day = parts[0].tail.tail.head;
    let _pipe = new $birl.Day(year, month, day);
    let _pipe$1 = ((_capture) => {
      return $birl.set_day($birl.utc_now(), _capture);
    })(_pipe);
    return new $msg.UpdateDate(_pipe$1);
  } else {
    return new $msg.NewProposalNone();
  }
}

function enter_proposal_details(proposal, users, owner_id) {
  let $ = $birl.get_day(proposal.due_date);
  let year = $.year;
  let month = $.month;
  let day = $.date;
  let datetime_value = (() => {
    let _pipe = toList([year, month, day]);
    let _pipe$1 = $list.map(_pipe, $int.to_string);
    let _pipe$2 = $list.map(
      _pipe$1,
      (_capture) => { return $string.pad_left(_capture, 2, "0"); },
    );
    return $string.join(_pipe$2, "-");
  })();
  let user = $list.find(users, (u) => { return u.id === owner_id; });
  let name = (() => {
    let _pipe = $result.map(user, (u) => { return u.name; });
    return $result.unwrap(_pipe, "Unknown Name");
  })();
  return $s.details_wrapper(
    toList([$e.on_submit(new $msg.SubmitDetails())]),
    toList([
      $illustrations.documents_stack(),
      $layout.column(
        toList([$layout.gap(64), $layout.flex("1"), $layout.overflow("auto")]),
        toList([]),
        toList([
          $layout.column(
            toList([$layout.gap(16)]),
            toList([]),
            toList([
              $s.details_title(
                toList([]),
                toList([$h.text("Enter proposal details")]),
              ),
              $input.input(
                toList([
                  $input.label("Name"),
                  $input.value(proposal.name),
                  $input.on_input(
                    (content) => { return new $msg.UpdateName(content); },
                  ),
                ]),
              ),
              $input.input(
                toList([
                  $input.label("Due Date"),
                  $input.type_("date"),
                  $input.value(datetime_value),
                  $input.on_input(update_date),
                ]),
              ),
              $input.input(
                toList([
                  $input.label("Client"),
                  $input.value(proposal.client),
                  $input.on_input(
                    (content) => { return new $msg.UpdateClient(content); },
                  ),
                ]),
              ),
              $layout.column(
                toList([$layout.gap(6)]),
                toList([]),
                toList([
                  $s.details_owner_label(toList([]), toList([$h.text("Owner")])),
                  $s.details_owner_picture(
                    toList([]),
                    toList([
                      $pp.from_user(
                        (() => {
                          let _pipe = $result.map(
                            user,
                            (u) => { return u.picture; },
                          );
                          return $result.unwrap(_pipe, new $option.None());
                        })(),
                        (() => {
                          let _pipe = $result.map(
                            user,
                            (u) => { return u.initials; },
                          );
                          return $result.unwrap(_pipe, "?");
                        })(),
                        new $pp.Medium(),
                      ),
                      $h.text(name),
                    ]),
                  ),
                ]),
              ),
              $layout.column(
                toList([
                  $layout.gap(6),
                  $layout.align("start"),
                  $layout.position("relative"),
                ]),
                toList([]),
                toList([
                  $s.details_owner_label(
                    toList([]),
                    toList([$h.text("Collaborators")]),
                  ),
                  $s.details_collaborators_button(
                    toList([
                      $e.on(
                        "click",
                        (event) => {
                          $e.prevent_default(event);
                          $e.stop_propagation(event);
                          return new Ok(new $msg.ToggleCollaboratorsPopup());
                        },
                      ),
                    ]),
                    toList([
                      $s.details_collaborators_icon($icons.add_user()),
                      $h.text("Add collaborators"),
                      (() => {
                        let $1 = proposal.collaborators_popup_opened;
                        if (!$1) {
                          return $el.none();
                        } else {
                          return $s.add_collaborators_popup_left(
                            toList([]),
                            listPrepend(
                              $s.add_collaborators_popup_title(
                                toList([]),
                                toList([$el.text("Select collaborators")]),
                              ),
                              (() => {
                                let _pipe = users;
                                let _pipe$1 = $list.filter(
                                  _pipe,
                                  (user) => {
                                    return $list.any(
                                      user.roles,
                                      (r) => { return r.name === "Contributor"; },
                                    ) && (user.id !== owner_id);
                                  },
                                );
                                return $list.map(
                                  _pipe$1,
                                  (user) => {
                                    let name$1 = user.name;
                                    let picture = user.picture;
                                    let initials = user.initials;
                                    return $s.add_collaborator_button(
                                      toList([
                                        $e.on_click(
                                          new $msg.AddCollaborator(user.id),
                                        ),
                                      ]),
                                      toList([
                                        $layout.row(
                                          toList([
                                            $layout.gap(8),
                                            $layout.align("center"),
                                          ]),
                                          toList([]),
                                          toList([
                                            $pp.from_user(
                                              picture,
                                              initials,
                                              new $pp.Medium(),
                                            ),
                                            $el.text(name$1),
                                          ]),
                                        ),
                                      ]),
                                    );
                                  },
                                );
                              })(),
                            ),
                          );
                        }
                      })(),
                    ]),
                  ),
                  $s.collaborators_list(
                    toList([]),
                    $list.map(
                      proposal.collaborators,
                      (user_id) => {
                        let user$1 = $list.find(
                          users,
                          (u) => { return u.id === user_id; },
                        );
                        return $s.details_owner_picture_deletion(
                          toList([]),
                          toList([
                            $pp.from_user(
                              (() => {
                                let _pipe = $result.map(
                                  user$1,
                                  (u) => { return u.picture; },
                                );
                                return $result.unwrap(_pipe, new $option.None());
                              })(),
                              (() => {
                                let _pipe = $result.map(
                                  user$1,
                                  (u) => { return u.initials; },
                                );
                                return $result.unwrap(_pipe, "?");
                              })(),
                              new $pp.Small(),
                            ),
                            $h.text(
                              (() => {
                                let _pipe = $result.map(
                                  user$1,
                                  (u) => { return u.name; },
                                );
                                return $result.unwrap(_pipe, "Unknown name");
                              })(),
                            ),
                            $s.details_collaborators_icon_delete(
                              toList([
                                $e.on(
                                  "click",
                                  (event) => {
                                    $e.prevent_default(event);
                                    $e.stop_propagation(event);
                                    return new Ok(
                                      new $msg.RemoveCollaborator(user_id),
                                    );
                                  },
                                ),
                              ]),
                              $icons.cross(),
                            ),
                          ]),
                        );
                      },
                    ),
                  ),
                ]),
              ),
            ]),
          ),
          $layout.row(
            toList([$layout.justify("end")]),
            toList([]),
            toList([
              $button.primary(
                toList([$a.type_("submit")]),
                toList([$h.text("Confirm")]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

function qualification_matrix() {
  let use_qualification_matrix = "Would you like to use a proposal qualification matrix?";
  let save_time = "This copy prompts that by using this feature the user can save a lot of time in the process.";
  return $s.layout_wrapper(
    toList([]),
    toList([
      $illustrations.lightbulb_flash(),
      $layout.column(
        toList([$layout.gap(16)]),
        toList([]),
        toList([
          $layout.column(
            toList([$layout.gap(8)]),
            toList([]),
            toList([
              $s.details_title(
                toList([]),
                toList([$h.text(use_qualification_matrix)]),
              ),
              $s.details_subtitle(toList([]), toList([$h.text(save_time)])),
            ]),
          ),
          $layout.row(
            toList([$layout.gap(16)]),
            toList([]),
            toList([
              $button.primary(
                toList([$e.on_click(new $msg.UseQualificationMatrix())]),
                toList([$h.text("Yes")]),
              ),
              $button.secondary(
                toList([$e.on_click(new $msg.SkipQualificationMatrix())]),
                toList([
                  $h.text("Maybe next time"),
                  $button.icon($icons.right_arrow()),
                ]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

function upload_file() {
  return $s.layout_wrapper(
    toList([]),
    toList([
      $s.drag_and_drop_zone(
        toList([]),
        toList([
          $s.icon_wrapper(toList([]), toList([$icons.upload()])),
          $layout.column(
            toList([$layout.gap(16), $layout.align("center")]),
            toList([]),
            toList([
              $s.drop_title(
                toList([]),
                toList([$h.text("Select your file to upload")]),
              ),
              $s.drop_subtitle(
                toList([]),
                toList([$h.text("We support excel questionnaires today.")]),
              ),
            ]),
          ),
          $h.label(
            toList([]),
            toList([
              $s.file_input(toList([]), toList([$h.text("Select files")])),
              $h.input(
                toList([
                  $a.type_("file"),
                  $a.style(toList([["display", "none"]])),
                  $a.attribute(
                    "accept",
                    (() => {
                      let _pipe = toList([
                        ".xls",
                        ".xlsx",
                        $mime_types.to_string(new $mime_types.Xlsx()),
                      ]);
                      return $string.join(_pipe, ",");
                    })(),
                  ),
                  $e.on(
                    "change",
                    (event) => {
                      let _pipe = $decipher.at(
                        toList(["target", "files", "0"]),
                        $dynamic.dynamic,
                      )(event);
                      return $result.map(
                        _pipe,
                        (var0) => { return new $msg.FetchXlsx(var0); },
                      );
                    },
                  ),
                ]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

function get_displayed_sheet(worksheets, selected_sheet) {
  let select = (_capture) => {
    return $result.map(_capture, (a) => { return a[2]; });
  };
  return $result.try_recover(
    (() => {
      let _pipe = $list.find(
        worksheets,
        (w) => { return w[0] === selected_sheet; },
      );
      return select(_pipe);
    })(),
    (_) => {
      let _pipe = $list.first(worksheets);
      return select(_pipe);
    },
  );
}

function invalid_sheet() {
  return $el.text("Invalid sheet");
}

function empty_sheet() {
  return $el.none();
}

function get_sheet_size(sheet) {
  return $list.fold(
    sheet,
    0,
    (size, item) => { return $int.max(size, $list.length(item)); },
  );
}

function select_border(border, content) {
  return $bool.guard(
    content !== 0,
    $a.none(),
    () => {
      return $a.style(toList([[border, "1px solid " + $colors.light.grey_200]]));
    },
  );
}

function spreadsheet_headers(size, sheet, columns_type) {
  return listPrepend(
    $h.div(toList([]), toList([])),
    (() => {
      let _pipe = $list.range(0, size - 1);
      return $list.index_map(
        _pipe,
        (content, index) => {
          let style = select_border("border-left", content);
          let selected = $dict.get(columns_type, index);
          return $s.spreadsheet_header(
            toList([style]),
            toList([
              $h.div(
                toList([]),
                toList([
                  $dropdown.dropdown(
                    toList([
                      $dropdown.on_selected(
                        (_capture) => {
                          return new $msg.ColumnTypeChanged(
                            sheet,
                            index,
                            _capture,
                          );
                        },
                      ),
                      $dropdown.placeholder("What is it?"),
                      (() => {
                        if (!selected.isOk()) {
                          return $dropdown.selected(new $option.None());
                        } else {
                          let type_ = selected[0];
                          return $dropdown.selected(new $option.Some(type_));
                        }
                      })(),
                    ]),
                    toList([
                      $dropdown.choice("Answer", new $new_proposal.Answer()),
                      $dropdown.choice("Question", new $new_proposal.Question()),
                    ]),
                  ),
                ]),
              ),
              $h.div(toList([]), toList([$h.text(from_charcode(65 + content))])),
            ]),
          );
        },
      );
    })(),
  );
}

function spreadsheet_line_header(y, max, sheet, invalid_lines) {
  let style = select_border("border-top", y);
  let selected = (() => {
    let _pipe = $dict.get(invalid_lines, y);
    return $result.unwrap(_pipe, true);
  })();
  return $s.spreadsheet_header_line(
    y,
    max,
    toList([style]),
    toList([
      $dropdown.dropdown(
        toList([
          $dropdown.selected(new $option.Some(selected)),
          $dropdown.on_selected(
            (_capture) => {
              return new $msg.InvalidLineChanged(sheet, y, _capture);
            },
          ),
        ]),
        toList([$dropdown.choice("✔", true), $dropdown.choice("❌", false)]),
      ),
      $h.text($int.to_string(y)),
    ]),
  );
}

function action_buttons(submit_enabled, next_disabled, next_sheet) {
  let text_class = $sketch.class$(
    toList([
      $sketch.hover(
        toList([
          (() => {
            let _pipe = $sketch.margin_right($size.px(0));
            return $sketch.important(_pipe);
          })(),
          $sketch.margin_left($size.px(-6)),
        ]),
      ),
    ]),
  );
  let update_selected_sheet = $e.on_click(
    new $msg.UpdateSelectedSheet(next_sheet),
  );
  return $layout.row(
    toList([$layout.gap(16), $layout.justify("end")]),
    toList([]),
    toList([
      $button.text(
        toList([
          $sketch.to_lustre(text_class),
          $e.on_click(new $msg.BackToUpload()),
        ]),
        toList([$button.icon($icons.left_arrow()), $h.text("Cancel")]),
      ),
      $button.secondary(
        toList([$a.disabled(next_disabled), update_selected_sheet]),
        toList([$h.text("Next sheet"), $button.icon($icons.right_arrow())]),
      ),
      $button.primary(
        toList([
          $a.disabled(!submit_enabled),
          $e.on_click(new $msg.SubmitProposal()),
        ]),
        toList([$h.text("Generate your responses")]),
      ),
    ]),
  );
}

function find_next_sheet(loop$worksheets, loop$selected_sheet) {
  while (true) {
    let worksheets = loop$worksheets;
    let selected_sheet = loop$selected_sheet;
    if (worksheets.atLeastLength(2) && (worksheets.head[0] === selected_sheet)) {
      let sheet = worksheets.head;
      let next = worksheets.tail.head;
      return next[0];
    } else if (worksheets.atLeastLength(1)) {
      let sheets = worksheets.tail;
      loop$worksheets = sheets;
      loop$selected_sheet = selected_sheet;
    } else {
      return "";
    }
  }
}

function view_spreadsheet(
  sheet,
  selected_sheet,
  columns_type,
  invalid_lines,
  worksheets
) {
  let size = get_sheet_size(sheet);
  let sheet_length = $list.length(sheet);
  return $bool.guard(
    size === 0,
    empty_sheet(),
    () => {
      return $el.fragment(
        toList([
          $s.spreadsheet_wrapper(
            toList([]),
            toList([
              $s.spreadsheet(
                size + 1,
                toList([]),
                toList([
                  $el.fragment(
                    spreadsheet_headers(
                      size,
                      selected_sheet,
                      (() => {
                        let _pipe = columns_type;
                        let _pipe$1 = $list.key_find(_pipe, selected_sheet);
                        return $result.unwrap(_pipe$1, $dict.new$());
                      })(),
                    ),
                  ),
                  $el.fragment(
                    $list.flatten(
                      $list.index_map(
                        sheet,
                        (row, y) => {
                          let _pipe = invalid_lines;
                          let _pipe$1 = $list.key_find(_pipe, selected_sheet);
                          let _pipe$2 = $result.unwrap(_pipe$1, $dict.new$());
                          let _pipe$3 = ((_capture) => {
                            return spreadsheet_line_header(
                              y,
                              sheet_length,
                              selected_sheet,
                              _capture,
                            );
                          })(_pipe$2);
                          return $function.flip($list.prepend)(
                            _pipe$3,
                            $list.index_map(
                              row,
                              (col, _) => {
                                if (col instanceof $option.None) {
                                  return $s.empty_cell(toList([]));
                                } else {
                                  let content = col[0];
                                  return $s.filled_cell(
                                    toList([]),
                                    toList([$el.text(content)]),
                                  );
                                }
                              },
                            ),
                          );
                        },
                      ),
                    ),
                  ),
                ]),
              ),
              $s.spreadsheet_sheets(
                toList([]),
                $list.map(
                  worksheets,
                  (sheet) => {
                    let on_click = $e.on_click(
                      new $msg.UpdateSelectedSheet(sheet[0]),
                    );
                    let $ = selected_sheet === sheet[0];
                    if ($) {
                      return $s.active_spreadsheet_sheet(
                        toList([on_click]),
                        toList([$el.text(sheet[0])]),
                      );
                    } else {
                      return $s.spreadsheet_sheet(
                        toList([on_click]),
                        toList([$el.text(sheet[0])]),
                      );
                    }
                  },
                ),
              ),
            ]),
          ),
          action_buttons(
            $bool.guard(
              $list.is_empty(columns_type),
              false,
              () => {
                return $list.fold(
                  worksheets,
                  true,
                  (valid, sheet) => {
                    let $ = $list.key_find(columns_type, sheet[0]);
                    if (!$.isOk()) {
                      return false;
                    } else {
                      let sheet$1 = $[0];
                      let values = $dict.values(sheet$1);
                      return (valid && $list.contains(
                        values,
                        new $new_proposal.Question(),
                      )) && $list.contains(values, new $new_proposal.Answer());
                    }
                  },
                );
              },
            ),
            (() => {
              let _pipe = $list.reverse(worksheets);
              let _pipe$1 = $list.first(_pipe);
              return ((s) => {
                return isEqual(
                  $result.map(s, (s) => { return s[0]; }),
                  new Ok(selected_sheet)
                );
              })(_pipe$1);
            })(),
            find_next_sheet(worksheets, selected_sheet),
          ),
        ]),
      );
    },
  );
}

function select_columns(new_proposal) {
  let $ = new_proposal.xlsx;
  let $1 = new_proposal.selected_sheet;
  if ($ instanceof $option.None) {
    return $el.none();
  } else if ($1 instanceof $option.None) {
    return $el.none();
  } else {
    let spreadsheet = $[0];
    let selected_sheet = $1[0];
    let worksheets = spreadsheet.data;
    let sheet = get_displayed_sheet(worksheets, selected_sheet);
    let _pipe = sheet;
    let _pipe$1 = $result.replace_error(_pipe, invalid_sheet());
    let _pipe$2 = $result.map(
      _pipe$1,
      (_capture) => {
        return view_spreadsheet(
          _capture,
          selected_sheet,
          new_proposal.columns_type,
          new_proposal.invalid_lines,
          worksheets,
        );
      },
    );
    return $result.unwrap_both(_pipe$2);
  }
}

export function view(model, is_loading) {
  let new_proposal = model.new_proposal;
  let $ = model.user;
  if (!($ instanceof $option.Some)) {
    throw makeError(
      "assignment_no_match",
      "view/proposals/add",
      42,
      "view",
      "Assignment pattern did not match",
      { value: $ }
    )
  }
  let user = $[0];
  return $el.fragment(
    toList([
      $progress_circles.steps(
        toList([
          (() => {
            let _pipe = $progress_circles.step(new_proposal.step);
            return $progress_circles.description(
              _pipe,
              "Enter proposal details",
            );
          })(),
          (() => {
            let _pipe = $progress_circles.step(new_proposal.step);
            return $progress_circles.description(_pipe, "Upload file");
          })(),
          (() => {
            let _pipe = $progress_circles.step(new_proposal.step);
            return $progress_circles.description(_pipe, "Select columns");
          })(),
        ]),
      ),
      $bool.guard(
        is_loading,
        loading(),
        () => {
          let $1 = new_proposal.step;
          if ($1 === 1) {
            return enter_proposal_details(new_proposal, model.users, user.sub);
          } else if ($1 === 2) {
            return upload_file();
          } else if ($1 === 3) {
            return select_columns(new_proposal);
          } else {
            return $el.none();
          }
        },
      ),
    ]),
  );
}
