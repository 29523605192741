export class Tooltip extends HTMLElement {
  #root: ShadowRoot
  #hovered: boolean

  constructor() {
    super()
    this.#root = this.attachShadow({ mode: 'open' })
    this.#hovered = false
  }

  connectedCallback() {
    const div = document.createElement('div')
    const slot1 = document.createElement('slot')
    const hidden = document.createElement('div')
    const slot2 = document.createElement('slot')
    slot1.setAttribute('name', 'tooltip-content')
    hidden.appendChild(slot2)
    hidden.setAttribute('class', 'absolute-wrapper')
    hidden.setAttribute('style', 'position: absolute;')
    div.appendChild(slot1)
    div.appendChild(hidden)
    div.setAttribute('style', 'position: relative;')
    div.addEventListener('mouseenter', event => {
      event.preventDefault()
      slot2.setAttribute('name', 'tooltip-tooltip')
      hidden.style.width = Math.min(450, window.innerWidth - 100) + 'px'
      if (window.innerWidth - event.clientX < 450) {
        hidden.style.right = '0px'
        hidden.style.left = 'unset'
      } else {
        hidden.style.right = 'unset'
        hidden.style.left = '0px'
      }
    })
    div.addEventListener('mouseleave', event => {
      event.preventDefault()
      slot2.removeAttribute('name')
    })
    this.#root.appendChild(div)
  }

  static register() {
    customElements.define('steerlab-tooltip', Tooltip)
  }
}
