/// <reference types="./grille_pain.d.mts" />
import * as $dynamic from "../gleam_stdlib/gleam/dynamic.mjs";
import * as $io from "../gleam_stdlib/gleam/io.mjs";
import * as $list from "../gleam_stdlib/gleam/list.mjs";
import * as $option from "../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../gleam_stdlib/gleam/result.mjs";
import * as $lustre from "../lustre/lustre.mjs";
import * as $effect from "../lustre/lustre/effect.mjs";
import * as $document from "../plinth/plinth/browser/document.mjs";
import * as $element from "../plinth/plinth/browser/element.mjs";
import * as $shadow from "../plinth/plinth/browser/shadow.mjs";
import * as $s from "../sketch/sketch.mjs";
import * as $sketch from "../sketch/sketch/lustre.mjs";
import * as $sketch_options from "../sketch/sketch/options.mjs";
import * as $model from "./grille_pain/internals/data/model.mjs";
import { Model } from "./grille_pain/internals/data/model.mjs";
import * as $t from "./grille_pain/internals/data/msg.mjs";
import * as $ffi from "./grille_pain/internals/ffi.mjs";
import * as $schedule from "./grille_pain/internals/lustre/schedule.mjs";
import { schedule } from "./grille_pain/internals/lustre/schedule.mjs";
import * as $view from "./grille_pain/internals/view.mjs";
import { view } from "./grille_pain/internals/view.mjs";
import * as $options from "./grille_pain/options.mjs";

function update(model, msg) {
  if (msg instanceof $t.RemoveToast) {
    let id = msg.id;
    return [$model.remove(model, id), $effect.none()];
  } else if (msg instanceof $t.StopToast) {
    let id = msg.id;
    return [$model.stop(model, id), $effect.none()];
  } else if (msg instanceof $t.ShowToast) {
    let id = msg.id;
    let timeout = msg.timeout;
    let sticky = msg.sticky;
    let time = $option.unwrap(timeout, model.timeout);
    let new_model = $model.show(model, id);
    let eff = (() => {
      if (sticky) {
        return $effect.none();
      } else {
        return schedule(time, new $t.HideToast(id, 0));
      }
    })();
    return [new_model, eff];
  } else if (msg instanceof $t.ExternalHideToast) {
    let uuid = msg.uuid;
    let _pipe = model.toasts;
    let _pipe$1 = $list.find(
      _pipe,
      (toast) => { return toast.external_id === uuid; },
    );
    let _pipe$2 = $result.map(
      _pipe$1,
      (toast) => {
        return schedule(1000, new $t.HideToast(toast.id, toast.iteration));
      },
    );
    let _pipe$3 = $result.map(
      _pipe$2,
      (_capture) => { return $pair.new$(model, _capture); },
    );
    return $result.unwrap(_pipe$3, [model, $effect.none()]);
  } else if (msg instanceof $t.HideToast) {
    let id = msg.id;
    let iteration = msg.iteration;
    let _pipe = model.toasts;
    let _pipe$1 = $list.find(
      _pipe,
      (toast) => { return (toast.id === id) && (toast.iteration === iteration); },
    );
    let _pipe$2 = $result.map(
      _pipe$1,
      (toast) => {
        let _pipe$2 = model;
        let _pipe$3 = $model.hide(_pipe$2, toast.id);
        let _pipe$4 = $model.decrease_bottom(_pipe$3, toast.id);
        return $pair.new$(_pipe$4, schedule(1000, new $t.RemoveToast(id)));
      },
    );
    return $result.unwrap(_pipe$2, [model, $effect.none()]);
  } else if (msg instanceof $t.ResumeToast) {
    let id = msg.id;
    let new_model = $model.resume(model, id);
    let _pipe = model.toasts;
    let _pipe$1 = $list.find(_pipe, (toast) => { return toast.id === id; });
    let _pipe$2 = $result.map(
      _pipe$1,
      (t) => {
        let $ = t.sticky;
        if ($) {
          return $effect.none();
        } else {
          return schedule(t.remaining, new $t.HideToast(id, t.iteration));
        }
      },
    );
    let _pipe$3 = $result.map(_pipe$2, (eff) => { return [new_model, eff]; });
    return $result.unwrap(_pipe$3, [new_model, $effect.none()]);
  } else {
    let uuid = msg.uuid;
    let message = msg.message;
    let level = msg.level;
    let timeout = msg.timeout;
    let sticky = msg.sticky;
    let old_id = model.id;
    let new_model = $model.add(model, uuid, message, level, sticky, timeout);
    return [new_model, schedule(100, new $t.ShowToast(old_id, timeout, sticky))];
  }
}

export function setup(opts) {
  let node = $document.create_element("grille-pain");
  let lustre_root_ = $document.create_element("div");
  let shadow_root = $shadow.attach_shadow(node, new $shadow.Open());
  let lustre_root = $dynamic.unsafe_coerce($dynamic.from(lustre_root_));
  $shadow.append_child(shadow_root, lustre_root_);
  let _pipe = $document.body();
  $element.append_child(_pipe, node)
  $ffi.add_keyframe(shadow_root);
  let cache = (() => {
    let _pipe$1 = $sketch_options.shadow();
    return $sketch.setup(_pipe$1);
  })();
  let $ = $result.map(
    cache,
    (_capture) => { return $s.attach(_capture, shadow_root); },
  );
  
  let render = (() => {
    let _pipe$1 = cache;
    let _pipe$2 = $result.map_error(_pipe$1, $io.debug);
    let _pipe$3 = $result.map(
      _pipe$2,
      (_capture) => { return $sketch.compose(view, _capture); },
    );
    return $result.unwrap(_pipe$3, view);
  })();
  let dispatcher = (() => {
    let _pipe$1 = (_) => {
      return [$model.new$(shadow_root, opts.timeout), $effect.none()];
    };
    let _pipe$2 = $lustre.application(_pipe$1, update, render);
    return $lustre.start(_pipe$2, lustre_root, undefined);
  })();
  let _pipe$1 = dispatcher;
  let _pipe$2 = $result.map_error(_pipe$1, $io.debug);
  return $result.map(_pipe$2, $ffi.store_dispatcher);
}

export function simple() {
  let _pipe = $options.default$();
  return setup(_pipe);
}
