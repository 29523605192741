/// <reference types="./data_point.d.mts" />
import * as $decode from "../../decode/decode.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $decode_ from "../decode/extra.mjs";
import { Ok, Error, toList, CustomType as $CustomType } from "../gleam.mjs";

export class DataPoint extends $CustomType {
  constructor(id, source_id, display, kind, text) {
    super();
    this.id = id;
    this.source_id = source_id;
    this.display = display;
    this.kind = kind;
    this.text = text;
  }
}

export class Text extends $CustomType {}

export class Qa extends $CustomType {}

export class DisplayText extends $CustomType {
  constructor(page_number, text_src_language) {
    super();
    this.page_number = page_number;
    this.text_src_language = text_src_language;
  }
}

export class DisplayQa extends $CustomType {
  constructor(row_number, sheet_name, question, answer, question_src_language, answer_src_language) {
    super();
    this.row_number = row_number;
    this.sheet_name = sheet_name;
    this.question = question;
    this.answer = answer;
    this.question_src_language = question_src_language;
    this.answer_src_language = answer_src_language;
  }
}

function decode_kind(kind) {
  let expected = "text | qa";
  if (kind === "text") {
    return new Ok(new Text());
  } else if (kind === "qa") {
    return new Ok(new Qa());
  } else {
    let found = kind;
    return new Error(
      toList([new $dynamic.DecodeError(expected, found, toList([]))]),
    );
  }
}

function encode_display(display) {
  if (display instanceof DisplayText) {
    let page_number = display.page_number;
    let text_src_language = display.text_src_language;
    return $json.object(
      toList([
        ["page_number", $json.int(page_number)],
        ["text_src_language", $json.string(text_src_language)],
      ]),
    );
  } else {
    let row_number = display.row_number;
    let sheet_name = display.sheet_name;
    let question = display.question;
    let answer = display.answer;
    let question_src_language = display.question_src_language;
    let answer_src_language = display.answer_src_language;
    return $json.object(
      toList([
        ["row_number", $json.int(row_number)],
        ["sheet_name", $json.string(sheet_name)],
        ["question", $json.string(question)],
        ["answer", $json.string(answer)],
        ["question_src_language", $json.string(question_src_language)],
        ["answer_src_language", $json.string(answer_src_language)],
      ]),
    );
  }
}

function encode_kind(kind) {
  if (kind instanceof Text) {
    return $json.string("text");
  } else {
    return $json.string("qa");
  }
}

export function encode(data_point) {
  return $json.object(
    toList([
      ["id", $json.string(data_point.id)],
      ["source_id", $json.string(data_point.source_id)],
      ["display", $decode_.encode_json(encode_display(data_point.display))],
      ["kind", encode_kind(data_point.kind)],
      ["text", $json.string(data_point.text)],
    ]),
  );
}

function decode_display(dyn) {
  let _pipe = $decode.one_of(
    toList([
      (() => {
        let _pipe = $decode.into(
          $decode.parameter(
            (page_number) => {
              return $decode.parameter(
                (text_src_language) => {
                  return new DisplayText(page_number, text_src_language);
                },
              );
            },
          ),
        );
        let _pipe$1 = $decode.field(_pipe, "page_number", $decode.int);
        return $decode.field(_pipe$1, "text_src_language", $decode.string);
      })(),
      (() => {
        let _pipe = $decode.into(
          $decode.parameter(
            (row_number) => {
              return $decode.parameter(
                (sheet_name) => {
                  return $decode.parameter(
                    (question) => {
                      return $decode.parameter(
                        (answer) => {
                          return $decode.parameter(
                            (question_src_language) => {
                              return $decode.parameter(
                                (answer_src_language) => {
                                  return new DisplayQa(
                                    row_number,
                                    sheet_name,
                                    question,
                                    answer,
                                    question_src_language,
                                    answer_src_language,
                                  );
                                },
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          ),
        );
        let _pipe$1 = $decode.field(_pipe, "row_number", $decode.int);
        let _pipe$2 = $decode.field(_pipe$1, "sheet_name", $decode.string);
        let _pipe$3 = $decode.field(_pipe$2, "question", $decode.string);
        let _pipe$4 = $decode.field(_pipe$3, "answer", $decode.string);
        let _pipe$5 = $decode.field(
          _pipe$4,
          "question_src_language",
          $decode.string,
        );
        return $decode.field(_pipe$5, "answer_src_language", $decode.string);
      })(),
    ]),
  );
  return $decode.from(_pipe, dyn);
}

export function decode(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (id) => {
        return $decode.parameter(
          (source_id) => {
            return $decode.parameter(
              (display) => {
                return $decode.parameter(
                  (kind) => {
                    return $decode.parameter(
                      (text) => {
                        return new DataPoint(id, source_id, display, kind, text);
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "id", $decode.string);
  let _pipe$2 = $decode.field(_pipe$1, "source_id", $decode.string);
  let _pipe$3 = $decode.field(_pipe$2, "display", $decode_.json(decode_display));
  let _pipe$4 = $decode.field(
    _pipe$3,
    "kind",
    $decode_.from_string(decode_kind),
  );
  let _pipe$5 = $decode.field(_pipe$4, "text", $decode.string);
  return $decode.from(_pipe$5, dyn);
}
