/// <reference types="./copilot.d.mts" />
import * as $el from "../../lustre/lustre/element.mjs";
import * as $model from "../data/model.mjs";
import * as $route from "../data/route.mjs";
import { toList, makeError } from "../gleam.mjs";
import * as $ai_response from "../view/copilot/ai_response.mjs";
import * as $ai_search from "../view/copilot/ai_search.mjs";

export function view(model) {
  let $ = model.route;
  if (!($ instanceof $route.CoPilot)) {
    throw makeError(
      "assignment_no_match",
      "view/copilot",
      8,
      "view",
      "Assignment pattern did not match",
      { value: $ }
    )
  }
  let route = $[0];
  return $el.fragment(
    toList([
      (() => {
        if (route instanceof $route.AiSearch) {
          return $ai_search.view(model);
        } else {
          let id = route[0];
          return $ai_response.view(model, id);
        }
      })(),
    ]),
  );
}
