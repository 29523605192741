/// <reference types="./style.d.mts" />
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import { toList, prepend as listPrepend, CustomType as $CustomType, makeError } from "../../gleam.mjs";
import * as $sketch_string from "../../sketch/internals/string.mjs";

export class ClassName extends $CustomType {
  constructor(class_name) {
    super();
    this.class_name = class_name;
  }
}

export class Media extends $CustomType {
  constructor(query, styles) {
    super();
    this.query = query;
    this.styles = styles;
  }
}

export class PseudoSelector extends $CustomType {
  constructor(pseudo_selector, styles) {
    super();
    this.pseudo_selector = pseudo_selector;
    this.styles = styles;
  }
}

export class Property extends $CustomType {
  constructor(key, value, important) {
    super();
    this.key = key;
    this.value = value;
    this.important = important;
  }
}

export class ComputedProperties extends $CustomType {
  constructor(properties, medias, classes, pseudo_selectors, indent) {
    super();
    this.properties = properties;
    this.medias = medias;
    this.classes = classes;
    this.pseudo_selectors = pseudo_selectors;
    this.indent = indent;
  }
}

export class MediaProperty extends $CustomType {
  constructor(query, properties, pseudo_selectors) {
    super();
    this.query = query;
    this.properties = properties;
    this.pseudo_selectors = pseudo_selectors;
  }
}

export class PseudoProperty extends $CustomType {
  constructor(pseudo_selector, properties) {
    super();
    this.pseudo_selector = pseudo_selector;
    this.properties = properties;
  }
}

export class ComputedClass extends $CustomType {
  constructor(class_def, medias_def, selectors_def, name) {
    super();
    this.class_def = class_def;
    this.medias_def = medias_def;
    this.selectors_def = selectors_def;
    this.name = name;
  }
}

function compute_property(indent, key, value, important) {
  let base_indent = $sketch_string.indent(indent);
  let important_ = (() => {
    if (important) {
      return " !important";
    } else {
      return "";
    }
  })();
  return ((((base_indent + key) + ": ") + value) + important_) + ";";
}

function init_computed_properties(indent) {
  return new ComputedProperties(
    toList([]),
    toList([]),
    toList([]),
    toList([]),
    indent,
  );
}

function handle_class_name(props, class_name) {
  let classes = listPrepend(class_name, props.classes);
  return props.withFields({ classes: classes });
}

function handle_property(props, style) {
  if (!(style instanceof Property)) {
    throw makeError(
      "assignment_no_match",
      "sketch/internals/style",
      77,
      "handle_property",
      "Assignment pattern did not match",
      { value: style }
    )
  }
  let key = style.key;
  let value = style.value;
  let important = style.important;
  let css_property = compute_property(props.indent, key, value, important);
  let properties = listPrepend(css_property, props.properties);
  return props.withFields({ properties: properties });
}

function wrap_pseudo_selectors(id, indent, pseudo_selectors) {
  return $list.map(
    pseudo_selectors,
    (p) => {
      return $sketch_string.wrap_class(
        id,
        p.properties,
        indent,
        new Some(p.pseudo_selector),
      );
    },
  );
}

export function compute_classes(class_name, computed_properties) {
  let properties = computed_properties.properties;
  let medias = computed_properties.medias;
  let classes = computed_properties.classes;
  let pseudo_selectors = computed_properties.pseudo_selectors;
  let class_def = $sketch_string.wrap_class(
    class_name,
    properties,
    0,
    new None(),
  );
  let medias_def = $list.map(
    medias,
    (_use0) => {
      let query = _use0.query;
      let properties$1 = _use0.properties;
      let pseudo_selectors$1 = _use0.pseudo_selectors;
      let selectors_def = wrap_pseudo_selectors(
        class_name,
        2,
        pseudo_selectors$1,
      );
      let _pipe = toList([
        query + " {",
        $sketch_string.wrap_class(class_name, properties$1, 2, new None()),
      ]);
      let _pipe$1 = ((_capture) => {
        return $list.prepend(toList([selectors_def, toList(["}"])]), _capture);
      })(_pipe);
      let _pipe$2 = $list.concat(_pipe$1);
      return $string.join(_pipe$2, "\n");
    },
  );
  let selectors_def = wrap_pseudo_selectors(class_name, 0, pseudo_selectors);
  let name = $string.trim(($string.join(classes, " ") + " ") + class_name);
  return new ComputedClass(class_def, medias_def, selectors_def, name);
}

function handle_media(props, style) {
  if (!(style instanceof Media)) {
    throw makeError(
      "assignment_no_match",
      "sketch/internals/style",
      84,
      "handle_media",
      "Assignment pattern did not match",
      { value: style }
    )
  }
  let query = style.query;
  let styles = style.styles;
  let computed_props = compute_properties(styles, props.indent + 2);
  let _pipe = new MediaProperty(
    query,
    computed_props.properties,
    computed_props.pseudo_selectors,
  );
  let _pipe$1 = ((_capture) => { return $list.prepend(props.medias, _capture); })(
    _pipe,
  );
  return ((m) => { return props.withFields({ medias: m }); })(_pipe$1);
}

export function compute_properties(properties, indent) {
  return $list.fold_right(
    properties,
    init_computed_properties(indent),
    (acc, prop) => {
      if (prop instanceof ClassName) {
        let class_name = prop.class_name;
        return handle_class_name(acc, class_name);
      } else if (prop instanceof Property) {
        return handle_property(acc, prop);
      } else if (prop instanceof Media) {
        return handle_media(acc, prop);
      } else {
        return handle_pseudo_selector(acc, prop);
      }
    },
  );
}

function handle_pseudo_selector(props, style) {
  if (!(style instanceof PseudoSelector)) {
    throw makeError(
      "assignment_no_match",
      "sketch/internals/style",
      96,
      "handle_pseudo_selector",
      "Assignment pattern did not match",
      { value: style }
    )
  }
  let pseudo_selector = style.pseudo_selector;
  let styles = style.styles;
  let computed_props = compute_properties(styles, props.indent + 2);
  let _pipe = new PseudoProperty(pseudo_selector, computed_props.properties);
  let _pipe$1 = ((_capture) => {
    return $list.prepend(computed_props.pseudo_selectors, _capture);
  })(_pipe);
  let _pipe$2 = $list.append(_pipe$1, props.pseudo_selectors);
  return ((p) => { return props.withFields({ pseudo_selectors: p }); })(_pipe$2);
}
