/// <reference types="./display.d.mts" />
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { rem_ } from "../../../sketch/sketch/size.mjs";
import { toList } from "../../gleam.mjs";

export function xxl() {
  let _pipe = $s.class$(
    toList([
      $s.font_weight("500"),
      $s.font_size(rem_(4.5)),
      $s.line_height("5.625rem"),
      $s.letter_spacing("-0.045rem"),
    ]),
  );
  return $s.memo(_pipe);
}

export function xl() {
  let _pipe = $s.class$(
    toList([
      $s.font_weight("500"),
      $s.font_size(rem_(3.75)),
      $s.line_height("4.5rem"),
      $s.letter_spacing("-0.0375rem"),
    ]),
  );
  return $s.memo(_pipe);
}

export function lg() {
  let _pipe = $s.class$(
    toList([
      $s.font_weight("500"),
      $s.font_size(rem_(3.0)),
      $s.line_height("3.75rem"),
      $s.letter_spacing("-0.03rem"),
    ]),
  );
  return $s.memo(_pipe);
}

export function md() {
  let _pipe = $s.class$(
    toList([
      $s.font_weight("500"),
      $s.font_size(rem_(2.25)),
      $s.line_height("2.75rem"),
      $s.letter_spacing("-0.0225rem"),
    ]),
  );
  return $s.memo(_pipe);
}

export function sm() {
  let _pipe = $s.class$(
    toList([
      $s.font_weight("500"),
      $s.font_size(rem_(1.875)),
      $s.line_height("2.375rem"),
      $s.letter_spacing("-0.01875rem"),
    ]),
  );
  return $s.memo(_pipe);
}

export function xs() {
  let _pipe = $s.class$(
    toList([
      $s.font_weight("500"),
      $s.font_size(rem_(1.5)),
      $s.line_height("2rem"),
      $s.letter_spacing("-0.015rem"),
    ]),
  );
  return $s.memo(_pipe);
}
