/// <reference types="./new_proposal.d.mts" />
import * as $app_config from "../../app_config/app_config.mjs";
import * as $proposal from "../../common/data/proposal.mjs";
import * as $question from "../../common/data/question.mjs";
import * as $spreadsheet from "../../common/data/spreadsheet.mjs";
import * as $fetch from "../../gleam_fetch/gleam/fetch.mjs";
import * as $http from "../../gleam_http/gleam/http.mjs";
import * as $request from "../../gleam_http/gleam/http/request.mjs";
import * as $promise from "../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $function from "../../gleam_stdlib/gleam/function.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $level from "../../grille_pain/grille_pain/toast/level.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $modem from "../../modem/modem.mjs";
import * as $model from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";
import * as $route from "../data/route.mjs";
import * as $new_proposal from "../data/ui/new_proposal.mjs";
import { Ok, toList, makeError } from "../gleam.mjs";

function send_new_proposal(new_proposal, access_token, org_id, user_id) {
  return $effect.from(
    (dispatch) => {
      let $ = $new_proposal.encode(new_proposal, org_id, user_id);
      if (!$.isOk()) {
        return undefined;
      } else {
        let encoded = $[0];
        let $1 = $request.to($app_config.heimdall_endpoint() + "/proposals");
        if (!$1.isOk()) {
          throw makeError(
            "assignment_no_match",
            "update/new_proposal",
            34,
            "",
            "Assignment pattern did not match",
            { value: $1 }
          )
        }
        let request = $1[0];
        let _pipe = request;
        let _pipe$1 = $request.set_header(
          _pipe,
          "authorization",
          "Bearer " + access_token,
        );
        let _pipe$2 = $request.set_method(_pipe$1, new $http.Post());
        let _pipe$3 = $request.set_body(_pipe$2, encoded);
        let _pipe$4 = $fetch.send(_pipe$3);
        let _pipe$5 = $promise.try_await(
          _pipe$4,
          (_capture) => { return $fetch.read_json_body(_capture); },
        );
        let _pipe$6 = $promise.map_try(
          _pipe$5,
          (r) => { return new Ok(r.body); },
        );
        let _pipe$7 = $promise.map(
          _pipe$6,
          (_capture) => { return $result.replace_error(_capture, toList([])); },
        );
        let _pipe$8 = $promise.map_try(
          _pipe$7,
          $dynamic.decode2(
            $pair.new$,
            $dynamic.field("proposal", $proposal.decode),
            $dynamic.field("questions", $dynamic.list($question.decode)),
          ),
        );
        let _pipe$9 = $promise.map(
          _pipe$8,
          (var0) => { return new $msg.ProposalSaved(var0); },
        );
        let _pipe$10 = $promise.map(
          _pipe$9,
          (var0) => { return new $msg.OnNewProposal(var0); },
        );
        $promise.tap(_pipe$10, dispatch)
        return undefined;
      }
    },
  );
}

function update_new_proposal_field(msg) {
  if (msg instanceof $msg.UseQualificationMatrix) {
    return $new_proposal.use_qualification_matrix;
  } else if (msg instanceof $msg.SubmitDetails) {
    return $new_proposal.next_step;
  } else if (msg instanceof $msg.SkipQualificationMatrix) {
    return $new_proposal.next_step;
  } else if (msg instanceof $msg.UpdateName) {
    let name = msg[0];
    return (_capture) => { return $new_proposal.update_name(_capture, name); };
  } else if (msg instanceof $msg.BackToUpload) {
    return $new_proposal.back_to_upload;
  } else if (msg instanceof $msg.UpdateClient) {
    let client = msg[0];
    return (_capture) => {
      return $new_proposal.update_client(_capture, client);
    };
  } else if (msg instanceof $msg.UpdateSelectedSheet) {
    let s = msg[0];
    return (_capture) => {
      return $new_proposal.update_selected_sheet(_capture, s);
    };
  } else if (msg instanceof $msg.UpdateDate) {
    let date = msg[0];
    return (_capture) => {
      return $new_proposal.update_due_date(_capture, date);
    };
  } else if (msg instanceof $msg.ColumnTypeChanged) {
    let sheet = msg[0];
    let index = msg[1];
    let value = msg[2];
    return (_capture) => {
      return $new_proposal.update_column_type(_capture, sheet, index, value);
    };
  } else if (msg instanceof $msg.InvalidLineChanged) {
    let sheet = msg[0];
    let index = msg[1];
    let value = msg[2];
    return (_capture) => {
      return $new_proposal.update_invalid_line(_capture, sheet, index, value);
    };
  } else if (msg instanceof $msg.XlsxFetched && msg[0].isOk()) {
    let xlsx = msg[0][0];
    return (new_proposal) => {
      let _pipe = new_proposal;
      let _pipe$1 = $new_proposal.update_xlsx(_pipe, xlsx);
      let _pipe$2 = (() => {
        let $ = $list.first(xlsx.data);
        if (!$.isOk()) {
          return $function.identity;
        } else {
          let name = $[0];
          return (_capture) => {
            return $new_proposal.update_selected_sheet(_capture, name[0]);
          };
        }
      })()(_pipe$1);
      return $new_proposal.next_step(_pipe$2);
    };
  } else if (msg instanceof $msg.AddCollaborator) {
    let u = msg.user_id;
    return (_capture) => { return $new_proposal.add_collaborator(_capture, u); };
  } else if (msg instanceof $msg.RemoveCollaborator) {
    let u = msg.user_id;
    return (_capture) => {
      return $new_proposal.remove_collaborator(_capture, u);
    };
  } else if (msg instanceof $msg.ToggleCollaboratorsPopup) {
    return $new_proposal.toggle_collaborators_popup;
  } else {
    throw makeError(
      "panic",
      "update/new_proposal",
      133,
      "update_new_proposal_field",
      "Should be handled in outer update",
      {}
    )
  }
}

export function update(model, msg) {
  if (msg instanceof $msg.NewProposalNone) {
    return [model, $effect.none()];
  } else if (msg instanceof $msg.XlsxFetched && !msg[0].isOk()) {
    return [
      model,
      $effect.from(
        (dispatch) => {
          return dispatch(
            new $msg.DisplayToast(
              new $level.Error(),
              "The spreadsheet is too huge. Please, upload a file with less questions, or contact us directly.",
            ),
          );
        },
      ),
    ];
  } else if (msg instanceof $msg.SubmitProposal) {
    let $ = model.access_token;
    if (!($ instanceof $option.Some)) {
      throw makeError(
        "assignment_no_match",
        "update/new_proposal",
        70,
        "update",
        "Assignment pattern did not match",
        { value: $ }
      )
    }
    let access_token = $[0];
    let $1 = model.user;
    if (!($1 instanceof $option.Some)) {
      throw makeError(
        "assignment_no_match",
        "update/new_proposal",
        71,
        "update",
        "Assignment pattern did not match",
        { value: $1 }
      )
    }
    let user = $1[0];
    let $2 = user.org_id;
    if (!($2 instanceof $option.Some)) {
      throw makeError(
        "assignment_no_match",
        "update/new_proposal",
        72,
        "update",
        "Assignment pattern did not match",
        { value: $2 }
      )
    }
    let org_id = $2[0];
    let loading = new $route.Proposals(new $route.Add(true));
    let _pipe = $model.update_route(model, loading);
    return $pair.new$(
      _pipe,
      send_new_proposal(model.new_proposal, access_token, org_id, user.sub),
    );
  } else if (msg instanceof $msg.ProposalSaved && msg[0].isOk()) {
    let proposal = msg[0][0][0];
    let questions = msg[0][0][1];
    let questions$1 = $list.map(questions, (q) => { return [q.id, q]; });
    let _pipe = new $route.Proposals(
      new $route.Show(proposal.id, new $option.None()),
    );
    let _pipe$1 = $route.to_uri(_pipe);
    let _pipe$2 = $result.map(
      _pipe$1,
      (u) => { return $modem.replace(u.path, u.query, u.fragment); },
    );
    let _pipe$3 = $result.unwrap(_pipe$2, $effect.none());
    return $function.flip($pair.new$)(
      _pipe$3,
      (() => {
        let proposals = $list.key_set(model.proposals, proposal.id, proposal);
        let questions$2 = $dict.insert(
          model.questions,
          proposal.id,
          questions$1,
        );
        return model.withFields({ proposals: proposals, questions: questions$2 });
      })(),
    );
  } else if (msg instanceof $msg.ProposalSaved && !msg[0].isOk()) {
    return [model, $effect.none()];
  } else if (msg instanceof $msg.FetchXlsx) {
    let xlsx = msg[0];
    let _pipe = $spreadsheet.from_file(
      xlsx,
      (var0) => { return new $msg.XlsxFetched(var0); },
    );
    let _pipe$1 = $effect.map(
      _pipe,
      (var0) => { return new $msg.OnNewProposal(var0); },
    );
    return ((_capture) => { return $pair.new$(model, _capture); })(_pipe$1);
  } else {
    let _pipe = model;
    let _pipe$1 = $model.update_new_proposal(
      _pipe,
      update_new_proposal_field(msg),
    );
    return $pair.new$(_pipe$1, $effect.none());
  }
}
