/// <reference types="./logo.d.mts" />
import * as $attribute from "../../lustre/lustre/attribute.mjs";
import * as $html from "../../lustre/lustre/element/html.mjs";
import * as $svg from "../../lustre/lustre/element/svg.mjs";
import * as $colors from "../design_system/colors.mjs";
import { toList } from "../gleam.mjs";

function a(name, value) {
  return $attribute.attribute(name, value);
}

export function round() {
  let content = "<circle cx=\"256\" cy=\"256\" r=\"256\" fill=\"#27272C\"/>\n    <path d=\"M256.895 337.823V402.872C256.895 402.872 346.856 366.888 398.064 252.014C398.064 244.897 398.064 242.524 398.064 235.406C398.064 235.406 328.864 304.607 256.895 337.823Z\" fill=\"white\"/>\n    <path d=\"M187.698 262.091V332.284C187.698 332.284 288.73 332.284 398.068 212.285C398.068 200.605 398.068 202.916 398.068 192.696C335.787 232.634 277.658 254.779 187.698 262.091Z\" fill=\"white\"/>\n    <path d=\"M398.066 148.211C302.569 174.775 202.92 174.775 121.263 148.211V237.533C121.263 237.533 245.824 270.004 398.066 174.775V148.211Z\" fill=\"white\"/>";
  return $html.svg(
    toList([
      a("dangerous-unescaped-html", content),
      a("viewBox", "0 0 512 512"),
      a("fill", "none"),
      a("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}

export function light() {
  return $html.svg(
    toList([
      a("viewBox", "0 0 252 252"),
      a("fill", "none"),
      a("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([
      $svg.rect(
        toList([
          a("width", "251.882"),
          a("height", "251.882"),
          a("rx", "27.3228"),
          a("fill", $colors.light.onyx),
        ]),
      ),
      $svg.path(
        toList([
          a(
            "d",
            "M123.046 164.984V197.379C123.046 197.379 167.848 179.458 193.35 122.25C193.35 118.705 193.35 117.523 193.35 113.978C193.35 113.978 158.887 148.441 123.046 164.984Z",
          ),
          a("fill", $colors.light.white),
        ]),
      ),
      $svg.path(
        toList([
          a(
            "d",
            "M88.5838 127.273V162.23C88.5838 162.23 138.9 162.23 193.351 102.469C193.351 96.6517 193.351 97.8028 193.351 92.7128C162.335 112.603 133.386 123.631 88.5838 127.273Z",
          ),
          a("fill", $colors.light.white),
        ]),
      ),
      $svg.path(
        toList([
          a("fill", $colors.light.white),
          a(
            "d",
            "M193.351 70.5567C145.792 83.7862 96.1652 83.7862 55.4989 70.5567V115.041C55.4989 115.041 117.532 131.212 193.351 83.7862V70.5567Z",
          ),
        ]),
      ),
    ]),
  );
}

export function dark() {
  return $html.svg(
    toList([
      a("viewBox", "0 0 252 252"),
      a("xmlns", "http://www.w3.org/2000/svg"),
      a("fill", "none"),
    ]),
    toList([
      $svg.path(
        toList([
          a("fill-rule", "evenodd"),
          a("clip-rule", "evenodd"),
          a(
            "d",
            "M27.3227 0.763199C12.2327 0.763199 -0.00012207 12.9961 -0.00012207 28.086V225.323C-0.00012207 240.413 12.2327 252.645 27.3227 252.645H224.559C239.649 252.645 251.882 240.413 251.882 225.323V28.086C251.882 12.996 239.649 0.763199 224.559 0.763199H27.3227ZM123.046 198.142V165.747C158.887 149.205 193.35 114.742 193.35 114.742V123.013C167.848 180.221 123.046 198.142 123.046 198.142ZM88.5839 128.036V162.993C88.5839 162.993 138.9 162.993 193.351 103.232V93.4763C162.335 113.366 133.386 124.395 88.5839 128.036ZM55.4989 71.3198C96.1652 84.5493 145.792 84.5493 193.351 71.3198V84.5493C117.532 131.975 55.4989 115.804 55.4989 115.804V71.3198Z",
          ),
          a("fill", "white"),
        ]),
      ),
    ]),
  );
}

export function colored() {
  return $html.svg(
    toList([
      a("viewBox", "0 0 252 252"),
      a("xmlns", "http://www.w3.org/2000/svg"),
      a("fill", "none"),
    ]),
    toList([
      $svg.rect(
        toList([
          a("width", "251.882"),
          a("height", "251.882"),
          a("rx", "27.3228"),
          a("fill", $colors.light.onyx),
        ]),
      ),
      $svg.path(
        toList([
          a("fill", "url(#first)"),
          a(
            "d",
            "M123.046 164.984V197.379C123.046 197.379 167.848 179.458 193.35 122.25C193.35 118.705 193.35 117.523 193.35 113.978C193.35 113.978 158.887 148.441 123.046 164.984Z",
          ),
        ]),
      ),
      $svg.path(
        toList([
          a("fill", "url(#second)"),
          a(
            "d",
            "M88.5838 127.273V162.23C88.5838 162.23 138.9 162.23 193.351 102.469C193.351 96.6517 193.351 97.8028 193.351 92.7128C162.335 112.603 133.386 123.631 88.5838 127.273Z",
          ),
        ]),
      ),
      $svg.path(
        toList([
          a("fill", "url(#third)"),
          a(
            "d",
            "M193.351 70.5567C145.792 83.7862 96.1652 83.7862 55.4989 70.5567V115.041C55.4989 115.041 117.532 131.212 193.351 83.7862V70.5567Z",
          ),
        ]),
      ),
      $svg.defs(
        toList([]),
        toList([
          $svg.linear_gradient(
            toList([
              a("id", "first"),
              a("x1", "124.094"),
              a("y1", "186.61"),
              a("x2", "192.403"),
              a("y2", "121.553"),
              a("gradientUnits", "userSpaceOnUse"),
            ]),
            toList([
              $svg.stop(
                toList([
                  a("offset", "0.160684"),
                  a("stop-color", $colors.light.success_400),
                ]),
              ),
              $svg.stop(
                toList([a("offset", "1"), a("stop-color", $colors.light.ai_400)]),
              ),
            ]),
          ),
          $svg.linear_gradient(
            toList([
              a("id", "second"),
              a("x1", "192.404"),
              a("y1", "98.7849"),
              a("x2", "88.3138"),
              a("y2", "147.577"),
              a("gradientUnits", "userSpaceOnUse"),
            ]),
            toList([
              $svg.stop(toList([a("stop-color", $colors.light.success_400)])),
              $svg.stop(
                toList([a("offset", "1"), a("stop-color", $colors.light.ai_400)]),
              ),
            ]),
          ),
          $svg.linear_gradient(
            toList([
              a("id", "third"),
              a("x1", "55.7848"),
              a("y1", "102.038"),
              a("x2", "193.368"),
              a("y2", "80.2676"),
              a("gradientUnits", "userSpaceOnUse"),
            ]),
            toList([
              $svg.stop(
                toList([
                  a("offset", "0.222758"),
                  a("stop-color", $colors.light.success_400),
                ]),
              ),
              $svg.stop(
                toList([a("offset", "1"), a("stop-color", $colors.light.ai_400)]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

export function text() {
  return $html.svg(
    toList([
      a("xmlns", "http://www.w3.org/2000/svg"),
      a("fill", "none"),
      a("viewBox", "0 0 102 20"),
    ]),
    toList([
      $svg.path(
        toList([
          a("fill", "#27272C"),
          a(
            "d",
            "M95.4535 19.9375C93.5831 19.9375 91.8227 19.0298 90.9975 17.6545V19.7175H88.3293V0.050293H90.9975V7.88965C91.8502 6.51433 93.5831 5.63412 95.426 5.63412C99.607 5.63412 102 8.68734 102 12.7858C102 16.8843 99.607 19.9375 95.4535 19.9375ZM95.0409 17.5995C97.599 17.5995 99.3044 15.674 99.3044 12.7858C99.3044 9.89763 97.599 7.97217 95.0409 7.97217C92.4828 7.97217 90.7774 9.89763 90.7774 12.7858C90.7774 15.674 92.4828 17.5995 95.0409 17.5995Z",
          ),
        ]),
      ),
      $svg.path(
        toList([
          a("fill", "#27272C"),
          a(
            "d",
            "M78.8386 19.9368C74.6576 19.9368 72.2645 16.8836 72.2645 12.7851C72.2645 8.68665 74.6301 5.63342 78.8111 5.63342C80.599 5.63342 82.3594 6.45862 83.2671 7.69641V5.85348H85.9353V19.7168H83.2671V17.8463C82.4144 19.1116 80.764 19.9368 78.8386 19.9368ZM79.2237 17.5988C81.7818 17.5988 83.4597 15.6733 83.4597 12.7851C83.4597 9.89693 81.7818 7.97148 79.2237 7.97148C76.6381 7.97148 74.9602 9.89693 74.9602 12.7851C74.9602 15.6733 76.6381 17.5988 79.2237 17.5988Z",
          ),
        ]),
      ),
      $svg.path(
        toList([
          a("fill", "#27272C"),
          a(
            "d",
            "M70.1005 19.7175C67.7624 19.7175 66.3046 18.2871 66.3046 15.9216V0.050293H68.9727V15.7015C68.9727 16.7467 69.5228 17.4069 70.5956 17.4069H71.4758V19.7175H70.1005Z",
          ),
        ]),
      ),
      $svg.path(
        toList([
          a("fill", "#27272C"),
          a(
            "d",
            "M56.3433 19.7169V5.85364H59.0114V8.52177C59.644 6.67883 60.7443 5.85364 62.6973 5.85364H64.3752V8.16418H62.3672C59.8366 8.16418 59.0114 10.4197 59.0114 13.4454V19.7169H56.3433Z",
          ),
        ]),
      ),
      $svg.path(
        toList([
          a("fill", "#27272C"),
          a(
            "d",
            "M47.4984 19.9368C43.2074 19.9368 40.5668 16.5535 40.5668 12.7026C40.5668 8.82418 42.9873 5.63342 47.2509 5.63342C51.5144 5.63342 53.7974 8.68665 53.7974 12.2075C53.7974 12.5651 53.7699 13.0327 53.7424 13.2802H43.1799C43.3999 15.8658 45.1053 17.6538 47.4984 17.6538C49.5339 17.6538 50.8542 16.7736 51.2393 15.2057H53.9074C53.4398 18.0664 50.9367 19.9368 47.4984 19.9368ZM43.2624 11.2172H51.1018C50.9367 9.09924 49.5339 7.77893 47.2509 7.77893C45.1053 7.77893 43.675 8.96171 43.2624 11.2172Z",
          ),
        ]),
      ),
      $svg.path(
        toList([
          a("fill", "#27272C"),
          a(
            "d",
            "M32.788 19.9368C28.4969 19.9368 25.8563 16.5535 25.8563 12.7026C25.8563 8.82418 28.2769 5.63342 32.5404 5.63342C36.8039 5.63342 39.087 8.68665 39.087 12.2075C39.087 12.5651 39.0594 13.0327 39.0319 13.2802H28.4694C28.6895 15.8658 30.3949 17.6538 32.788 17.6538C34.8234 17.6538 36.1438 16.7736 36.5288 15.2057H39.197C38.7294 18.0664 36.2263 19.9368 32.788 19.9368ZM28.552 11.2172H36.3913C36.2263 9.09924 34.8234 7.77893 32.5404 7.77893C30.3949 7.77893 28.9646 8.96171 28.552 11.2172Z",
          ),
        ]),
      ),
      $svg.path(
        toList([
          a("fill", "#27272C"),
          a(
            "d",
            "M22.8306 19.7167C20.0249 19.7167 18.2095 18.0113 18.2095 15.1782V8.16399H15.3763V5.85345H17.4118C17.9344 5.85345 18.2095 5.60589 18.2095 5.05576V1.50742H20.8776V5.85345H24.9761V8.16399H20.8776V15.0956C20.8776 16.581 21.7028 17.4062 23.0232 17.4062H24.9761V19.7167H22.8306Z",
          ),
        ]),
      ),
      $svg.path(
        toList([
          a("fill", "#27272C"),
          a(
            "d",
            "M7.62923 19.9373C3.06315 19.9373 0.450033 17.3242 0.22998 13.3357H3.00814C3.22819 15.8113 4.68604 17.4067 7.62923 17.4067C10.0498 17.4067 11.7552 16.4715 11.7552 14.381C11.7552 9.56735 0.807617 12.7306 0.807617 5.49639C0.807617 2.36065 3.53076 0.242645 7.40918 0.242645C11.3426 0.242645 14.0383 2.52569 14.2858 6.04652H11.5077C11.3151 4.06605 9.77474 2.77324 7.40918 2.77324C5.09863 2.77324 3.6958 3.81849 3.6958 5.46888C3.6958 10.31 14.6709 6.8167 14.6709 14.3535C14.6709 17.8743 11.7552 19.9373 7.62923 19.9373Z",
          ),
        ]),
      ),
    ]),
  );
}
