/// <reference types="./shorter.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function shorter() {
  let content = "<path d=\"M2.5 3.33337H17.5V5.00004H2.5V3.33337ZM2.5 9.16671H12.5V10.8334H2.5V9.16671Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 20 20"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
