/// <reference types="./proposal.d.mts" />
import * as $birl from "../../birl/birl.mjs";
import * as $decode from "../../decode/decode.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $status from "../data/status.mjs";
import * as $decode_ from "../decode/extra.mjs";
import { toList, prepend as listPrepend, CustomType as $CustomType, isEqual } from "../gleam.mjs";
import * as $dynamic_ from "../gleam/dynamic/extra.mjs";

export class QuestionnaireResponse extends $CustomType {
  constructor(name, column) {
    super();
    this.name = name;
    this.column = column;
  }
}

export class Questionnaire extends $CustomType {
  constructor(sheets, responses, qualification_matrix, worksheets) {
    super();
    this.sheets = sheets;
    this.responses = responses;
    this.qualification_matrix = qualification_matrix;
    this.worksheets = worksheets;
  }
}

export class Display extends $CustomType {
  constructor(type_) {
    super();
    this.type_ = type_;
  }
}

export class Proposal extends $CustomType {
  constructor(id, display, org_id, name, deadline, owner, client, collaborators, progress, status, metadata) {
    super();
    this.id = id;
    this.display = display;
    this.org_id = org_id;
    this.name = name;
    this.deadline = deadline;
    this.owner = owner;
    this.client = client;
    this.collaborators = collaborators;
    this.progress = progress;
    this.status = status;
    this.metadata = metadata;
  }
}

export function is_ongoing(proposal) {
  return (isEqual(proposal.status, new $status.Pending())) || (isEqual(
    proposal.status,
    new $status.InProgress()
  ));
}

export function is_finished(proposal) {
  return !is_ongoing(proposal);
}

export function encode_metadata(metadata) {
  return $json.object(
    toList([
      ["type", $json.string("questionnaire")],
      ["sheets", $json.array(metadata.sheets, $json.string)],
      [
        "responses",
        $json.array(
          metadata.responses,
          (q) => {
            return $json.object(
              toList([
                ["name", $json.string(q.name)],
                ["column", $json.int(q.column)],
              ]),
            );
          },
        ),
      ],
      [
        "worksheets",
        $json.array(
          metadata.worksheets,
          (w) => {
            return $json.object(
              toList([
                ["name", $json.string(w[0])],
                ["start_index", $json.int(w[1])],
                [
                  "content",
                  $json.array(
                    w[2],
                    (r) => {
                      return $json.array(
                        r,
                        (c) => { return $json.nullable(c, $json.string); },
                      );
                    },
                  ),
                ],
              ]),
            );
          },
        ),
      ],
      [
        "qualification_matrix",
        $json.nullable(
          metadata.qualification_matrix,
          (q) => {
            return $json.array(
              q,
              (q) => {
                return $json.object(
                  toList([
                    ["0", $json.string(q[0])],
                    ["1", $json.string(q[1])],
                    ["2", $json.bool(q[2])],
                  ]),
                );
              },
            );
          },
        ),
      ],
    ]),
  );
}

export function encode_display(display) {
  return $json.object(toList([["type", $json.string(display.type_)]]));
}

export function encode(proposal) {
  return $json.object(
    toList([
      ["id", $json.string(proposal.id)],
      ["display", $decode_.encode_json(encode_display(proposal.display))],
      ["org_id", $json.string(proposal.org_id)],
      ["name", $json.string(proposal.name)],
      [
        "deadline",
        (() => {
          let _pipe = proposal.deadline;
          let _pipe$1 = $birl.to_iso8601(_pipe);
          return $json.string(_pipe$1);
        })(),
      ],
      ["owner", $json.string(proposal.owner)],
      ["client", $json.string(proposal.client)],
      ["collaborators", $json.array(proposal.collaborators, $json.string)],
      ["progress", $json.int(proposal.progress)],
      ["status", $status.encode(proposal.status)],
      ["metadata", $decode_.encode_json(encode_metadata(proposal.metadata))],
    ]),
  );
}

export function is_collaborator(proposal, user_id) {
  let all_collaborators = listPrepend(proposal.owner, proposal.collaborators);
  return $list.contains(all_collaborators, user_id);
}

export function decode_metadata(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (_) => {
        return $decode.parameter(
          (sheets) => {
            return $decode.parameter(
              (responses) => {
                return $decode.parameter(
                  (worksheets) => {
                    return $decode.parameter(
                      (qualification_matrix) => {
                        return new Questionnaire(
                          sheets,
                          responses,
                          qualification_matrix,
                          worksheets,
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(
    _pipe,
    "type",
    (() => {
      let _pipe$1 = $decode.string;
      return $decode.then$(
        _pipe$1,
        (t) => {
          if (t === "questionnaire") {
            return $decode.into(t);
          } else {
            return $decode.fail("type");
          }
        },
      );
    })(),
  );
  let _pipe$2 = $decode.field(_pipe$1, "sheets", $decode.list($decode.string));
  let _pipe$3 = $decode.field(
    _pipe$2,
    "responses",
    $decode.list(decode_questionnaire_response()),
  );
  let _pipe$4 = $decode.field(
    _pipe$3,
    "worksheets",
    $decode.list(
      (() => {
        let _pipe$4 = $decode.into(
          $decode.parameter(
            (a) => {
              return $decode.parameter(
                (b) => {
                  return $decode.parameter((c) => { return [a, b, c]; });
                },
              );
            },
          ),
        );
        let _pipe$5 = $decode.field(_pipe$4, "name", $decode.string);
        let _pipe$6 = $decode.field(
          _pipe$5,
          "start_index",
          (() => {
            let _pipe$6 = $decode.optional($decode.int);
            return $decode.map(
              _pipe$6,
              (_capture) => { return $option.unwrap(_capture, 0); },
            );
          })(),
        );
        return $decode.field(
          _pipe$6,
          "content",
          $decode.list($decode.list($decode.optional($decode.string))),
        );
      })(),
    ),
  );
  let _pipe$5 = $decode.field(
    _pipe$4,
    "qualification_matrix",
    $decode.optional($decode.list(decode_qualification_matrix())),
  );
  return $decode.from(_pipe$5, dyn);
}

export function decode(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (id) => {
        return $decode.parameter(
          (display) => {
            return $decode.parameter(
              (org_id) => {
                return $decode.parameter(
                  (name) => {
                    return $decode.parameter(
                      (deadline) => {
                        return $decode.parameter(
                          (owner) => {
                            return $decode.parameter(
                              (client) => {
                                return $decode.parameter(
                                  (collaborators) => {
                                    return $decode.parameter(
                                      (progress) => {
                                        return $decode.parameter(
                                          (status) => {
                                            return $decode.parameter(
                                              (metadata) => {
                                                return new Proposal(
                                                  id,
                                                  display,
                                                  org_id,
                                                  name,
                                                  deadline,
                                                  owner,
                                                  client,
                                                  collaborators,
                                                  progress,
                                                  status,
                                                  metadata,
                                                );
                                              },
                                            );
                                          },
                                        );
                                      },
                                    );
                                  },
                                );
                              },
                            );
                          },
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(
    _pipe,
    "id",
    (() => {
      let _pipe$1 = $decode_.dynamic($dynamic_.uuid);
      return $decode.map(_pipe$1, $string.lowercase);
    })(),
  );
  let _pipe$2 = $decode.field(_pipe$1, "display", $decode_.json(decode_display));
  let _pipe$3 = $decode.field(_pipe$2, "org_id", $decode.string);
  let _pipe$4 = $decode.field(_pipe$3, "name", $decode.string);
  let _pipe$5 = $decode.field(_pipe$4, "deadline", $decode_.time());
  let _pipe$6 = $decode.field(_pipe$5, "owner", $decode.string);
  let _pipe$7 = $decode.field(_pipe$6, "client", $decode.string);
  let _pipe$8 = $decode.field(
    _pipe$7,
    "collaborators",
    $decode.list($decode.string),
  );
  let _pipe$9 = $decode.field(_pipe$8, "progress", $decode.int);
  let _pipe$10 = $decode.field(
    _pipe$9,
    "status",
    $decode_.dynamic($status.decode),
  );
  let _pipe$11 = $decode.field(
    _pipe$10,
    "metadata",
    $decode_.json(decode_metadata),
  );
  return $decode.from(_pipe$11, dyn);
}

function decode_questionnaire_response() {
  let _pipe = $decode.into(
    $decode.parameter(
      (name) => {
        return $decode.parameter(
          (column) => { return new QuestionnaireResponse(name, column); },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "name", $decode.string);
  return $decode.field(_pipe$1, "column", $decode.int);
}

function decode_qualification_matrix() {
  let _pipe = $decode.into(
    $decode.parameter(
      (category) => {
        return $decode.parameter(
          (question) => {
            return $decode.parameter(
              (yes_no) => { return [category, question, yes_no]; },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "0", $decode.string);
  let _pipe$2 = $decode.field(_pipe$1, "1", $decode.string);
  return $decode.field(_pipe$2, "2", $decode.bool);
}

export function decode_display(dyn) {
  let _pipe = $decode.into(
    $decode.parameter((type_) => { return new Display(type_); }),
  );
  let _pipe$1 = $decode.field(_pipe, "type", $decode.string);
  return $decode.from(_pipe$1, dyn);
}
