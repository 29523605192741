/// <reference types="./bell.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import { attribute } from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import * as $svg from "../../../lustre/lustre/element/svg.mjs";
import { toList } from "../../gleam.mjs";

export function bell() {
  return $html.svg(
    toList([
      attribute("xmlns", "http://www.w3.org/2000/svg"),
      attribute("fill", "none"),
      attribute("viewBox", "0 0 24 24"),
    ]),
    toList([
      $svg.path(
        toList([
          attribute("fill", "currentColor"),
          attribute(
            "d",
            "M22 20.8503H2V18.8503H3V11.8813C3 6.89334 7.03 2.85034 12 2.85034C16.97 2.85034 21 6.89334 21 11.8813V18.8503H22V20.8503ZM5 18.8503H19V11.8813C19 7.99834 15.866 4.85034 12 4.85034C8.134 4.85034 5 7.99834 5 11.8813V18.8503ZM9.5 21.8503H14.5C14.5 22.5134 14.2366 23.1493 13.7678 23.6181C13.2989 24.087 12.663 24.3503 12 24.3503C11.337 24.3503 10.7011 24.087 10.2322 23.6181C9.76339 23.1493 9.5 22.5134 9.5 21.8503Z",
          ),
        ]),
      ),
    ]),
  );
}
