/// <reference types="./spreadsheet.d.mts" />
import * as $promise from "../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $mime_types from "../../mime_types/mime_types.mjs";
import { fromFile as do_from_file } from "../common.ffi.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class Spreadsheet extends $CustomType {
  constructor(id, name, type_, data, blob) {
    super();
    this.id = id;
    this.name = name;
    this.type_ = type_;
    this.data = data;
    this.blob = blob;
  }
}

export function from_file(file, msg) {
  return $effect.from(
    (dispatch) => {
      let _pipe = do_from_file(file);
      $promise.tap(
        _pipe,
        (content) => {
          let _pipe$1 = content;
          let _pipe$2 = $result.try$(
            _pipe$1,
            (content) => {
              let name = content[0];
              let type_ = content[1];
              let data = content[2];
              let blob = content[3];
              let _pipe$2 = $mime_types.from_string(type_);
              return $result.map(
                _pipe$2,
                (type_) => {
                  return new Spreadsheet("", name, type_, data, blob);
                },
              );
            },
          );
          let _pipe$3 = msg(_pipe$2);
          return dispatch(_pipe$3);
        },
      )
      return undefined;
    },
  );
}

export function encode(spreadsheet) {
  return $json.object(
    toList([
      ["id", $json.string(spreadsheet.id)],
      ["name", $json.string(spreadsheet.name)],
      ["mime_type", $json.string($mime_types.to_string(spreadsheet.type_))],
      [
        "data",
        $json.preprocessed_array(
          $list.map(
            spreadsheet.data,
            (_use0) => {
              let name = _use0[0];
              let start_index = _use0[1];
              let content = _use0[2];
              return $json.object(
                toList([
                  ["name", $json.string(name)],
                  ["start_index", $json.int(start_index)],
                  [
                    "worksheet",
                    (() => {
                      let _pipe = content;
                      let _pipe$1 = $list.map(
                        _pipe,
                        (c) => {
                          return $json.array(
                            c,
                            (_capture) => {
                              return $json.nullable(_capture, $json.string);
                            },
                          );
                        },
                      );
                      return $json.preprocessed_array(_pipe$1);
                    })(),
                  ],
                ]),
              );
            },
          ),
        ),
      ],
    ]),
  );
}

function decode_data(dyn) {
  return $dynamic.list(
    $dynamic.decode3(
      (a, b, c) => { return [a, b, c]; },
      $dynamic.field("name", $dynamic.string),
      (dyn) => {
        let _pipe = $dynamic.optional_field(
          "start_index",
          (dyn) => {
            let _pipe = $dynamic.optional($dynamic.int)(dyn);
            return $result.map(
              _pipe,
              (_capture) => { return $option.unwrap(_capture, 0); },
            );
          },
        )(dyn);
        return $result.map(
          _pipe,
          (_capture) => { return $option.unwrap(_capture, 0); },
        );
      },
      $dynamic.field(
        "worksheet",
        $dynamic.list($dynamic.list($dynamic.optional($dynamic.string))),
      ),
    ),
  )(dyn);
}

export function decode(blob) {
  return (dyn) => {
    return $dynamic.decode4(
      (a, b, c, d) => { return new Spreadsheet(a, b, c, d, blob); },
      $dynamic.field("id", $dynamic.string),
      $dynamic.field("name", $dynamic.string),
      $dynamic.field("mime_type", $mime_types.decode),
      $dynamic.field("data", decode_data),
    )(dyn);
  };
}
