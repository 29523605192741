/// <reference types="./level.d.mts" />
import { CustomType as $CustomType } from "../../gleam.mjs";

export class Standard extends $CustomType {}

export class Info extends $CustomType {}

export class Warning extends $CustomType {}

export class Error extends $CustomType {}

export class Success extends $CustomType {}

export function to_string(level) {
  if (level instanceof Standard) {
    return "Standard";
  } else if (level instanceof Info) {
    return "Info";
  } else if (level instanceof Warning) {
    return "Warning";
  } else if (level instanceof Error) {
    return "Error";
  } else {
    return "Success";
  }
}
