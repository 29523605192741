/// <reference types="./file_list.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function file_list() {
  let content = "<path d=\"M20 22.7168H4C3.73478 22.7168 3.48043 22.6114 3.29289 22.4239C3.10536 22.2364 3 21.982 3 21.7168V3.7168C3 3.45158 3.10536 3.19723 3.29289 3.00969C3.48043 2.82215 3.73478 2.7168 4 2.7168H20C20.2652 2.7168 20.5196 2.82215 20.7071 3.00969C20.8946 3.19723 21 3.45158 21 3.7168V21.7168C21 21.982 20.8946 22.2364 20.7071 22.4239C20.5196 22.6114 20.2652 22.7168 20 22.7168ZM19 20.7168V4.7168H5V20.7168H19ZM8 7.7168H16V9.7168H8V7.7168ZM8 11.7168H16V13.7168H8V11.7168ZM8 15.7168H13V17.7168H8V15.7168Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 24 25"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
