/// <reference types="./extra.d.mts" />
import * as $birl from "../../birl/birl.mjs";
import * as $decipher from "../../decipher/decipher.mjs";
import * as $decode from "../../decode/decode.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $float from "../../gleam_stdlib/gleam/float.mjs";
import * as $io from "../../gleam_stdlib/gleam/io.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import { toList } from "../gleam.mjs";

export function dynamic(decoder) {
  return $decode.then$(
    $decode.dynamic,
    (dyn) => {
      let $ = decoder(dyn);
      if ($.isOk()) {
        let content = $[0];
        return $decode.into(content);
      } else {
        let e = $[0];
        return $decode.fail($string.inspect(e));
      }
    },
  );
}

function decode_float_in_int(dyn) {
  return $dynamic.any(
    toList([
      $dynamic.int,
      (dyn) => {
        let _pipe = $dynamic.float(dyn);
        return $result.map(_pipe, $float.round);
      },
    ]),
  )(dyn);
}

export function time() {
  return dynamic($dynamic.any(toList([$decipher.iso_8601])));
}

export function json(decode) {
  return $decode.then$(
    $decode.string,
    (json) => {
      let $ = $json.decode(json, decode);
      if ($.isOk()) {
        let content = $[0];
        return $decode.into(content);
      } else {
        let e = $[0];
        $io.debug(e);
        return $decode.fail("decoding failure");
      }
    },
  );
}

export function from_string(mapper) {
  return $decode.then$(
    $decode.string,
    (string) => {
      let $ = mapper(string);
      if ($.isOk()) {
        let m = $[0];
        return $decode.into(m);
      } else {
        return $decode.fail("decoding");
      }
    },
  );
}

export function encode_json(json) {
  let _pipe = json;
  let _pipe$1 = $json.to_string(_pipe);
  return $json.string(_pipe$1);
}
