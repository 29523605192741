/// <reference types="./utils.d.mts" />
import * as $birl from "../birl/birl.mjs";
import * as $int from "../gleam_stdlib/gleam/int.mjs";
import * as $list from "../gleam_stdlib/gleam/list.mjs";
import * as $result from "../gleam_stdlib/gleam/result.mjs";
import * as $string from "../gleam_stdlib/gleam/string.mjs";
import * as $uri from "../gleam_stdlib/gleam/uri.mjs";
import * as $event from "../lustre/lustre/event.mjs";
import { Error, toList } from "./gleam.mjs";

export function to_path(segments) {
  let _pipe = segments;
  let _pipe$1 = $list.map(
    _pipe,
    (_capture) => { return $string.replace(_capture, "/", ""); },
  );
  let _pipe$2 = $list.map(_pipe$1, $uri.percent_encode);
  let _pipe$3 = $string.join(_pipe$2, "/");
  return ((_capture) => { return $string.append("/", _capture); })(_pipe$3);
}

export function stop_propagation() {
  return $event.on(
    "click",
    (event) => {
      $event.stop_propagation(event);
      return new Error(toList([]));
    },
  );
}

export function time_to_qualified_day(time, separator) {
  let day = $birl.get_day(time);
  let month = $string.pad_left($int.to_string(day.month), 2, "0");
  let date = $string.pad_left($int.to_string(day.date), 2, "0");
  let year = $string.pad_left($int.to_string(day.year), 4, "0");
  return $string.join(toList([year, month, date]), separator);
}

export function parse_input_time(value) {
  let $ = $string.split(value, "-");
  if ($.hasLength(3)) {
    let year = $.head;
    let month = $.tail.head;
    let date = $.tail.tail.head;
    return $result.try$(
      $int.parse(year),
      (year) => {
        return $result.try$(
          $int.parse(month),
          (month) => {
            return $result.map(
              $int.parse(date),
              (date) => {
                let day = new $birl.Day(year, month, date);
                let time_of_day = new $birl.TimeOfDay(0, 0, 0, 0);
                let _pipe = $birl.utc_now();
                let _pipe$1 = $birl.set_day(_pipe, day);
                let _pipe$2 = $birl.set_time_of_day(_pipe$1, time_of_day);
                let _pipe$3 = $birl.to_unix(_pipe$2);
                return $birl.from_unix(_pipe$3);
              },
            );
          },
        );
      },
    );
  } else {
    return new Error(undefined);
  }
}
