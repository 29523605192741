/// <reference types="./content_library.d.mts" />
import * as $birl from "../../birl/birl.mjs";
import * as $duration from "../../birl/birl/duration.mjs";
import * as $data_source from "../../common/data/data_source.mjs";
import * as $user from "../../common/data/user.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $function from "../../gleam_stdlib/gleam/function.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $order from "../../gleam_stdlib/gleam/order.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $uri from "../../gleam_stdlib/gleam/uri.mjs";
import * as $a from "../../lustre/lustre/attribute.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $h from "../../lustre/lustre/element/html.mjs";
import * as $e from "../../lustre/lustre/event.mjs";
import * as $content_library from "../data/content_library.mjs";
import * as $model from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";
import * as $route from "../data/route.mjs";
import * as $button from "../design_system/components/button.mjs";
import * as $checkbox from "../design_system/components/checkbox.mjs";
import * as $input from "../design_system/components/input.mjs";
import * as $label from "../design_system/components/label.mjs";
import * as $layout from "../design_system/components/layout.mjs";
import * as $pp from "../design_system/components/profile_picture.mjs";
import * as $skeleton from "../design_system/components/skeleton.mjs";
import * as $table from "../design_system/components/table.mjs";
import * as $icons from "../design_system/icons.mjs";
import * as $ffi from "../frontend/ffi.mjs";
import { toList, prepend as listPrepend, makeError, isEqual } from "../gleam.mjs";
import * as $utils from "../utils.mjs";
import * as $common from "../view/common.mjs";
import * as $tab_bar from "../view/components/tab_bar.mjs";
import * as $cl_common from "../view/content_library/common.mjs";
import * as $new_question from "../view/content_library/new_question.mjs";
import * as $question_bank from "../view/content_library/question_bank.mjs";
import * as $show from "../view/content_library/show.mjs";
import * as $s from "../view/styles/content_library.mjs";
import * as $ps from "../view/styles/proposals.mjs";

function content_library_menu(model) {
  let selected = model.content_library.selected;
  let size = $dict.size(selected);
  let hidden = size === 0;
  let in_question_bank = isEqual(
    model.route,
    new $route.ContentLibrary(new $route.QuestionBank())
  );
  let all_selected = (() => {
    if (in_question_bank) {
      return $content_library.all_qna_selected(model.content_library);
    } else {
      return $content_library.all_non_qna_selected(model.content_library);
    }
  })();
  let check_state = (() => {
    if (all_selected) {
      return new $checkbox.Checked();
    } else if (!hidden) {
      return new $checkbox.Indeterminate();
    } else {
      return new $checkbox.Unchecked();
    }
  })();
  return $s.menu_wrapper(
    hidden,
    toList([]),
    toList([
      $s.menu(
        toList([]),
        toList([
          $layout.row(
            toList([$layout.gap(16)]),
            toList([]),
            toList([
              $checkbox.checkbox(
                check_state,
                (_) => { return new $msg.GlobalCheckbox(); },
              ),
              $s.number_selected(
                toList([]),
                toList([$h.text($int.to_string(size) + " selected")]),
              ),
            ]),
          ),
          $s.change_button(
            toList([$e.on_click(new $msg.ToggleChangeOwner())]),
            toList([
              $s.change_icon(false, $icons.user()),
              $h.text("Change owner"),
              $s.change_icon(
                !model.content_library.change_owner_popup_opened,
                $icons.arrow_dropdown(),
              ),
              (() => {
                let $ = model.content_library.change_owner_popup_opened;
                if (!$) {
                  return $el.none();
                } else {
                  return $s.change_owner_popup(
                    toList([]),
                    listPrepend(
                      $s.change_popup_title(
                        toList([]),
                        toList([$el.text("Select one people")]),
                      ),
                      (() => {
                        let _pipe = model.users;
                        let _pipe$1 = $list.filter(
                          _pipe,
                          (user) => {
                            return $list.any(
                              user.roles,
                              (r) => { return r.name === "Content Curator"; },
                            );
                          },
                        );
                        return $list.map(
                          _pipe$1,
                          (user) => {
                            let name = user.name;
                            let picture = user.picture;
                            let initials = user.initials;
                            return $s.change_popup_owner_select(
                              toList([$e.on_click(new $msg.AssignUser(user.id))]),
                              toList([
                                $layout.row(
                                  toList([
                                    $layout.gap(8),
                                    $layout.align("center"),
                                  ]),
                                  toList([]),
                                  toList([
                                    $pp.from_user(
                                      picture,
                                      initials,
                                      new $pp.Medium(),
                                    ),
                                    $el.text(name),
                                  ]),
                                ),
                              ]),
                            );
                          },
                        );
                      })(),
                    ),
                  );
                }
              })(),
            ]),
          ),
          $s.change_button(
            toList([$e.on_click(new $msg.ToggleChangeStatus())]),
            toList([
              $s.change_icon(false, $icons.checkmark()),
              $h.text("Change status"),
              $s.change_icon(
                !model.content_library.change_status_popup_opened,
                $icons.arrow_dropdown(),
              ),
              (() => {
                let $ = model.content_library.change_status_popup_opened;
                if (!$) {
                  return $el.none();
                } else {
                  return $s.change_status_popup(
                    toList([]),
                    toList([
                      $label.clickable_label(
                        new $label.Success(),
                        new $msg.AssignStatus(new $data_source.Verified()),
                        "Verified",
                      ),
                      $label.clickable_label(
                        new $label.Current(),
                        new $msg.AssignStatus(new $data_source.Pending()),
                        "Pending",
                      ),
                      $label.clickable_label(
                        new $label.Neutral(),
                        new $msg.AssignStatus(new $data_source.NotVerified()),
                        "Not verified",
                      ),
                      $label.clickable_label(
                        new $label.Error(),
                        new $msg.AssignStatus(new $data_source.Outdated()),
                        "Outdated",
                      ),
                    ]),
                  );
                }
              })(),
            ]),
          ),
          $s.change_button(
            toList([$e.on_click(new $msg.ToggleChangeExpirationDate())]),
            toList([
              $s.change_icon(false, $icons.calendar()),
              $h.text("Change expiration date"),
              $s.change_icon(
                !model.content_library.change_expiration_date_popup_opened,
                $icons.arrow_dropdown(),
              ),
              (() => {
                let $ = model.content_library.change_expiration_date_popup_opened;
                if (!$) {
                  return $el.none();
                } else {
                  return $s.change_expiration_date_popup(
                    toList([$utils.stop_propagation()]),
                    toList([
                      $layout.row(
                        toList([$layout.align("center"), $layout.gap(8)]),
                        toList([]),
                        (() => {
                          let value = model.content_library.change_expiration_date_input;
                          let time = $utils.parse_input_time(value);
                          let disabled = $a.disabled($result.is_error(time));
                          let on_click = (() => {
                            let _pipe = time;
                            let _pipe$1 = $result.map(
                              _pipe,
                              (var0) => {
                                return new $msg.AssignExpirationDate(var0);
                              },
                            );
                            let _pipe$2 = $result.map(_pipe$1, $e.on_click);
                            return $result.unwrap(_pipe$2, $a.none());
                          })();
                          return toList([
                            $input.input(
                              toList([
                                $input.value(value),
                                $input.type_("date"),
                                $input.on_input(
                                  (var0) => {
                                    return new $msg.UpdateExpirationDateInput(
                                      var0,
                                    );
                                  },
                                ),
                              ]),
                            ),
                            $button.primary(
                              toList([on_click, disabled]),
                              toList([$h.text("Submit")]),
                            ),
                          ]);
                        })(),
                      ),
                      $button.secondary(
                        toList([
                          (() => {
                            let _pipe = $birl.utc_now();
                            let _pipe$1 = $birl.add(_pipe, $duration.months(6));
                            let _pipe$2 = new $msg.AssignExpirationDate(_pipe$1);
                            return $e.on_click(_pipe$2);
                          })(),
                        ]),
                        toList([$h.text("6 months")]),
                      ),
                      $button.secondary(
                        toList([
                          (() => {
                            let _pipe = $birl.utc_now();
                            let _pipe$1 = $birl.add(_pipe, $duration.months(3));
                            let _pipe$2 = new $msg.AssignExpirationDate(_pipe$1);
                            return $e.on_click(_pipe$2);
                          })(),
                        ]),
                        toList([$h.text("3 month")]),
                      ),
                      $button.secondary(
                        toList([
                          (() => {
                            let _pipe = $birl.utc_now();
                            let _pipe$1 = $birl.add(_pipe, $duration.months(1));
                            let _pipe$2 = new $msg.AssignExpirationDate(_pipe$1);
                            return $e.on_click(_pipe$2);
                          })(),
                        ]),
                        toList([$h.text("1 month")]),
                      ),
                    ]),
                  );
                }
              })(),
            ]),
          ),
          $s.change_button(
            toList([$e.on_click(new $msg.ToggleChangeTags())]),
            toList([
              $s.change_icon(false, $icons.price_tag()),
              $h.text("Change tags"),
              $s.change_icon(
                !model.content_library.change_tags_popup_opened,
                $icons.arrow_dropdown(),
              ),
              (() => {
                let $ = model.content_library.change_tags_popup_opened;
                if (!$) {
                  return $el.none();
                } else {
                  return $s.change_tags_popup(
                    toList([]),
                    (() => {
                      let selected$1 = (() => {
                        let _pipe = model.content_library.selected;
                        let _pipe$1 = $dict.to_list(_pipe);
                        let _pipe$2 = $list.filter(
                          _pipe$1,
                          (a) => { return a[1]; },
                        );
                        return $list.filter_map(
                          _pipe$2,
                          (a) => {
                            return $list.find(
                              $list.append(
                                model.content_library.qna_sources.all,
                                model.content_library.non_qna_sources.all,
                              ),
                              (s) => { return s.id === a[0]; },
                            );
                          },
                        );
                      })();
                      return $list.map(
                        model.tags,
                        (tag) => {
                          let selected_tags = $list.fold(
                            selected$1,
                            [true, false],
                            (acc, value) => {
                              let all_selected$1 = acc[0];
                              let some_selected = acc[1];
                              let $1 = $list.find(
                                value.tags,
                                (t) => { return t === tag.id; },
                              );
                              if (!$1.isOk()) {
                                return [false, some_selected];
                              } else {
                                return [all_selected$1 && true, true];
                              }
                            },
                          );
                          let $1 = (() => {
                            if (selected_tags[0]) {
                              return [
                                new $checkbox.Checked(),
                                new $msg.Remove(),
                              ];
                            } else if (!selected_tags[0] && selected_tags[1]) {
                              return [
                                new $checkbox.Indeterminate(),
                                new $msg.Add(),
                              ];
                            } else {
                              return [new $checkbox.Unchecked(), new $msg.Add()];
                            }
                          })();
                          let state = $1[0];
                          let add_remove = $1[1];
                          let on_click = new $msg.AssignTag(tag, add_remove);
                          return $layout.row(
                            toList([$layout.align("center"), $layout.gap(8)]),
                            toList([$utils.stop_propagation()]),
                            toList([
                              $checkbox.checkbox(
                                state,
                                (_) => { return on_click; },
                              ),
                              $label.clickable_label(
                                new $label.Neutral(),
                                on_click,
                                tag.name,
                              ),
                            ]),
                          );
                        },
                      );
                    })(),
                  );
                }
              })(),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

export function view(model) {
  let $ = model.route;
  if (!($ instanceof $route.ContentLibrary)) {
    throw makeError(
      "assignment_no_match",
      "view/content_library",
      44,
      "view",
      "Assignment pattern did not match",
      { value: $ }
    )
  }
  let route = $[0];
  return $el.fragment(
    toList([
      (() => {
        if (route instanceof $route.NewQuestion) {
          return $el.none();
        } else {
          return $tab_bar.tab_bar(
            toList([
              (() => {
                let _pipe = new $route.ContentLibrary(new $route.DataSources());
                return $tab_bar.tab(_pipe, toList([$el.text("Sources")]));
              })(),
              (() => {
                let _pipe = new $route.ContentLibrary(new $route.QuestionBank());
                return $tab_bar.tab(_pipe, toList([$el.text("Question Bank")]));
              })(),
            ]),
          );
        }
      })(),
      (() => {
        if (route instanceof $route.DataSources) {
          return $show.view(model);
        } else if (route instanceof $route.QuestionBank) {
          return $question_bank.view(model);
        } else {
          return $new_question.view(model);
        }
      })(),
      (() => {
        let _pipe = content_library_menu(model);
        return $el.map(
          _pipe,
          (var0) => { return new $msg.ContentLibrary(var0); },
        );
      })(),
    ]),
  );
}
