import * as xlsx from 'xlsx'
import * as option from '../gleam_stdlib/gleam/option.mjs'
import * as gleam from './gleam.mjs'

export async function fromFile(file) {
  try {
    const content = await file.arrayBuffer()
    const workbook = xlsx.read(content)
    let questionAmount = 0
    const worksheets = workbook.SheetNames.map(sheet => {
      const rawSheet = workbook.Sheets[sheet]
      const worksheet = xlsx.utils.sheet_to_json(rawSheet, { header: 1 })
      const [start] = rawSheet['!ref'].split(':')
      const startIndex = xlsx.utils.decode_row(start.replace(/[A-Z]/g, ''))
      const maxSize = worksheet.reduce((acc, val) => Math.max(acc, val.length), 0)
      const final = []
      for (let i = 0; i < worksheet.length; i++) {
        const line = worksheet[i]
        const array = []
        for (let j = 0; j < maxSize; j++) {
          const cell = line[j]
          const cellExists = cell !== null && cell !== undefined
          array[j] = cellExists ? new option.Some(cell.toString()) : new option.None()
        }
        final[i] = array
      }
      for (let i = final.length - 1; i > 0; i--) {
        const isEmpty = final[i].reduce((acc, val) => acc && val instanceof option.None, true)
        if (isEmpty) final.splice(i, 1)
        else break
      }
      questionAmount += final.length
      if (questionAmount >= 300) throw new Error()
      return [sheet, startIndex, gleam.toList(final.map(a => gleam.toList(a)))]
    })
    return new gleam.Ok([
      file.name,
      file.type,
      gleam.List.fromArray(worksheets),
      gleam.toBitArray(new Uint8Array(content)),
    ])
  } catch (error) {
    console.error(error)
    return new gleam.Error()
  }
}

export function getStacktrace() {
  const error = new Error()
  return error.stack
}

export function logStacktrace(stack) {
  console.log(stack)
}
