/// <reference types="./drive_icons.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

export class DriveIcons extends $CustomType {
  constructor(docs, sheets) {
    super();
    this.docs = docs;
    this.sheets = sheets;
  }
}

export function docs() {
  let content = "<g clip-path=\"url(#clip0_6975_11765)\">\n<path d=\"M26 10.0001V28.0001C26 28.7367 25.4033 29.3334 24.6667 29.3334H7.33333C6.59667 29.3334 6 28.7367 6 28.0001V4.00008C6 3.26341 6.59667 2.66675 7.33333 2.66675H18.6667L26 10.0001Z\" fill=\"#438FFD\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.666 12V13.9993H22.0007V12H10.666Z\" fill=\"white\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.6674 16V17.9993H22.002V16H10.6674Z\" fill=\"white\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.666 20V21.9993H18.6667V20H10.666Z\" fill=\"white\"/>\n<path d=\"M18.6666 2.66675V9.33341C18.6666 9.70141 18.9653 10.0001 19.3333 10.0001H26L18.6666 2.66675Z\" fill=\"#2466C9\"/>\n</g>\n<defs>\n<clipPath id=\"clip0_6975_11765\">\n<rect width=\"32\" height=\"32\" fill=\"white\"/>\n</clipPath>\n</defs>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 32 32"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}

export function sheets() {
  let content = "<g clip-path=\"url(#clip0_6975_11657)\">\n<path d=\"M26 10.0001V28.0001C26 28.7367 25.4033 29.3334 24.6667 29.3334H7.33333C6.59667 29.3334 6 28.7367 6 28.0001V4.00008C6 3.26341 6.59667 2.66675 7.33333 2.66675H18.6667L26 10.0001Z\" fill=\"url(#paint0_linear_6975_11657)\"/>\n<path d=\"M18.6666 2.66675V9.33341C18.6666 9.70141 18.9653 10.0001 19.3333 10.0001H26L18.6666 2.66675Z\" fill=\"#107C42\"/>\n<path d=\"M21.3333 13.3333H10.6667C10.298 13.3333 10 13.6319 10 13.9999V20.6666C10 21.0346 10.298 21.3333 10.6667 21.3333H21.3333C21.702 21.3333 22 21.0346 22 20.6666V13.9999C22 13.6319 21.702 13.3333 21.3333 13.3333ZM20.6667 16.6666H16.6667V14.6666H20.6667V16.6666ZM15.3333 14.6666V16.6666H11.3333V14.6666H15.3333ZM11.3333 17.9999H15.3333V19.9999H11.3333V17.9999ZM16.6667 19.9999V17.9999H20.6667V19.9999H16.6667Z\" fill=\"white\"/>\n</g>\n<defs>\n<linearGradient id=\"paint0_linear_6975_11657\" x1=\"16\" y1=\"8.58008\" x2=\"16\" y2=\"63.2261\" gradientUnits=\"userSpaceOnUse\">\n<stop stop-color=\"#21AD64\"/>\n<stop offset=\"1\" stop-color=\"#088242\"/>\n</linearGradient>\n<clipPath id=\"clip0_6975_11657\">\n<rect width=\"32\" height=\"32\" fill=\"white\"/>\n</clipPath>\n</defs>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 32 32"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
