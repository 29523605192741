/// <reference types="./tag.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class Tag extends $CustomType {
  constructor(id, name, org_id) {
    super();
    this.id = id;
    this.name = name;
    this.org_id = org_id;
  }
}

export function decode(dyn) {
  return $dynamic.decode3(
    (var0, var1, var2) => { return new Tag(var0, var1, var2); },
    $dynamic.field("id", $dynamic.string),
    $dynamic.field("name", $dynamic.string),
    $dynamic.field("org_id", $dynamic.string),
  )(dyn);
}

export function encode(tag) {
  return $json.object(
    toList([
      ["id", $json.string(tag.id)],
      ["name", $json.string(tag.name)],
      ["org_id", $json.string(tag.org_id)],
    ]),
  );
}
