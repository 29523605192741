/// <reference types="./add_user.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function add_user() {
  let content = "<path d=\"M11.6663 11.8766V13.6183C10.9121 13.3516 10.1049 13.2698 9.31251 13.3797C8.52012 13.4897 7.76567 13.7882 7.11253 14.2501C6.45939 14.712 5.92663 15.3239 5.55898 16.0344C5.19132 16.7449 4.99952 17.5333 4.99967 18.3333L3.33301 18.3324C3.33275 17.3148 3.56544 16.3107 4.01326 15.3969C4.46107 14.4832 5.11212 13.684 5.91652 13.0608C6.72092 12.4375 7.65732 12.0067 8.65397 11.8013C9.65062 11.5959 10.6811 11.6214 11.6663 11.8758V11.8766ZM9.99967 10.8333C7.23717 10.8333 4.99967 8.59575 4.99967 5.83325C4.99967 3.07075 7.23717 0.833252 9.99967 0.833252C12.7622 0.833252 14.9997 3.07075 14.9997 5.83325C14.9997 8.59575 12.7622 10.8333 9.99967 10.8333ZM9.99967 9.16658C11.8413 9.16658 13.333 7.67492 13.333 5.83325C13.333 3.99159 11.8413 2.49992 9.99967 2.49992C8.15801 2.49992 6.66634 3.99159 6.66634 5.83325C6.66634 7.67492 8.15801 9.16658 9.99967 9.16658ZM14.9997 14.1666V11.6666H16.6663V14.1666H19.1663V15.8333H16.6663V18.3333H14.9997V15.8333H12.4997V14.1666H14.9997Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 20 20"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
