/// <reference types="./login.d.mts" />
import * as $auth0 from "../../auth0_client/auth0/client.mjs";
import * as $a from "../../lustre/lustre/attribute.mjs";
import * as $html from "../../lustre/lustre/element/html.mjs";
import * as $e from "../../lustre/lustre/event.mjs";
import * as $msg from "../data/msg.mjs";
import * as $button from "../design_system/components/button.mjs";
import * as $logo from "../design_system/logo.mjs";
import { toList } from "../gleam.mjs";
import * as $s from "../view/styles/login.mjs";

export function view() {
  return $s.wrapper(
    toList([]),
    toList([
      $s.background(toList([$a.src("/assets/background.png")])),
      $s.topbar(
        toList([]),
        toList([
          $s.topbar_logo(
            toList([$a.style(toList([]))]),
            toList([$logo.light()]),
          ),
          $s.topbar_name(toList([]), toList([$html.text("Steerlab")])),
        ]),
      ),
      $s.body(
        toList([]),
        toList([
          $s.body_glass(
            toList([]),
            toList([
              $s.body_title(
                toList([]),
                toList([$html.text("Welcome to Steerlab!")]),
              ),
              $button.primary(
                toList([$e.on_click(new $msg.Auth0(new $auth0.Login()))]),
                toList([$html.text("Log in")]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

export function invite() {
  return $s.wrapper(
    toList([]),
    toList([
      $s.background(toList([$a.src("/assets/background.png")])),
      $s.topbar(
        toList([]),
        toList([
          $s.topbar_logo(
            toList([$a.style(toList([]))]),
            toList([$logo.light()]),
          ),
          $s.topbar_name(toList([]), toList([$html.text("Steerlab")])),
        ]),
      ),
      $s.body(
        toList([]),
        toList([
          $s.body_glass(
            toList([]),
            toList([
              $s.body_title(
                toList([]),
                toList([$html.text("You've been invited to Steerlab!")]),
              ),
              $html.div(
                toList([]),
                toList([
                  $html.text(
                    "Unfortunately, it seems your invitation is not valid anymore. ",
                  ),
                  $html.text(
                    "Please, check with your administrator to get the correct ",
                  ),
                  $html.text(
                    "invitation link. If you're sure everything should be working ",
                  ),
                  $html.text("please, contact us at "),
                  $html.a(
                    toList([$a.href("mailto:support@steerlab.ai")]),
                    toList([$html.text("support@steerlab.ai")]),
                  ),
                  $html.text("."),
                ]),
              ),
              $s.thanks(toList([]), toList([$html.text("Thanks!")])),
            ]),
          ),
        ]),
      ),
    ]),
  );
}
