/// <reference types="./confluence.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function confluence() {
  let content = "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.13184 41.8535C8.66643 42.6433 8.14374 43.5599 7.69982 44.2901C7.30247 44.989 7.51578 45.8902 8.17954 46.317L17.4877 52.2783C17.8144 52.4882 18.2085 52.5528 18.5814 52.4576C18.9543 52.3623 19.2749 52.1151 19.471 51.7716C19.8434 51.1233 20.3231 50.2813 20.8458 49.3796C24.5333 43.0457 28.2422 43.8207 34.9297 47.1441L44.1591 51.712C44.5087 51.8851 44.9107 51.9036 45.2736 51.7632C45.6364 51.6228 45.9292 51.3355 46.0852 50.9668L50.5173 40.5345C50.8305 39.7894 50.5118 38.9207 49.8013 38.5822C47.8538 37.6284 43.9801 35.7282 40.4932 33.9771C27.9486 27.6357 17.2872 28.0456 9.13184 41.8535Z\" fill=\"url(#paint0_linear_6881_8406)\"/>\n    <path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M50.8682 18.1791C51.3336 17.3892 51.8563 16.4727 52.3002 15.7424C52.6976 15.0436 52.4843 14.1423 51.8205 13.7155L42.5123 7.75424C42.1829 7.52334 41.7762 7.44518 41.3893 7.53843C41.0024 7.63168 40.6701 7.88796 40.4717 8.24605C40.0994 8.89434 39.6197 9.73637 39.097 10.638C35.4095 16.9719 31.7006 16.1969 25.013 12.8735L15.8123 8.32801C15.4627 8.15486 15.0607 8.13637 14.6978 8.27676C14.335 8.41715 14.0422 8.70444 13.8862 9.07318L9.45408 19.5055C9.14095 20.2506 9.45957 21.1193 10.1701 21.4578C12.1176 22.4116 15.9913 24.3118 19.4782 26.0629C32.0514 32.3968 42.7128 31.9721 50.8682 18.1791Z\" fill=\"url(#paint1_linear_6881_8406)\"/>\n    <defs>\n    <linearGradient id=\"paint0_linear_6881_8406\" x1=\"50.2667\" y1=\"55.3558\" x2=\"39.1248\" y2=\"30.7947\" gradientUnits=\"userSpaceOnUse\">\n    <stop offset=\"0.18\" stop-color=\"#0052CC\"/>\n    <stop offset=\"1\" stop-color=\"#2684FF\"/>\n    </linearGradient>\n    <linearGradient id=\"paint1_linear_6881_8406\" x1=\"9.73333\" y1=\"4.66926\" x2=\"20.8927\" y2=\"29.2417\" gradientUnits=\"userSpaceOnUse\">\n    <stop offset=\"0.18\" stop-color=\"#0052CC\"/>\n    <stop offset=\"1\" stop-color=\"#2684FF\"/>\n    </linearGradient>\n    </defs>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 60 60"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
