/// <reference types="./feed.d.mts" />
import * as $birl from "../../birl/birl.mjs";
import * as $duration from "../../birl/birl/duration.mjs";
import * as $notification from "../../common/data/notification.mjs";
import * as $proposal from "../../common/data/proposal.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../lustre/lustre/attribute.mjs";
import * as $h from "../../lustre/lustre/element/html.mjs";
import * as $e from "../../lustre/lustre/event.mjs";
import * as $model from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";
import * as $layout from "../design_system/components/layout.mjs";
import * as $skeleton from "../design_system/components/skeleton.mjs";
import * as $icons from "../design_system/icons.mjs";
import { toList, prepend as listPrepend } from "../gleam.mjs";
import * as $common from "../view/common.mjs";
import * as $s from "../view/styles/feed.mjs";

function view_notification(proposals, notification) {
  let proposal = $list.key_find(proposals, notification.content.proposal_id);
  let active_dot = (() => {
    let $ = notification.read;
    if (!$) {
      return $s.active_dot;
    } else {
      return $s.inactive_dot;
    }
  })();
  let handler = $e.on_click(
    new $msg.GoToProposalQuestion(
      notification.content.proposal_id,
      notification.content.question_id,
      notification.id,
    ),
  );
  return $layout.row(
    toList([$layout.gap(32), $layout.padding(16)]),
    toList([handler]),
    toList([
      $layout.row(
        toList([$layout.gap(16), $layout.flex("1")]),
        toList([]),
        toList([
          $layout.column(
            toList([$layout.justify("center")]),
            toList([]),
            toList([active_dot()]),
          ),
          (() => {
            if (!proposal.isOk()) {
              return $layout.column(
                toList([$layout.gap(4)]),
                toList([]),
                toList([
                  $skeleton.skeleton(10, 250),
                  $skeleton.skeleton(10, 200),
                  $skeleton.skeleton(10, 200),
                ]),
              );
            } else {
              let name = proposal[0].name;
              let msg = ("You've been assigned to a question on " + name) + ".";
              let $ = notification.read;
              if ($) {
                return $s.dim_text($h.text(msg));
              } else {
                return $s.text($h.text(msg));
              }
            }
          })(),
        ]),
      ),
      $s.date(
        toList([]),
        toList([
          $h.text(
            (() => {
              let delta = $birl.difference($birl.utc_now(), notification.date);
              let hours = $duration.blur_to(delta, new $duration.Hour());
              if (hours < 24) {
                let x = hours;
                return "Today";
              } else if (hours < 48) {
                let x = hours;
                return "Yesterday";
              } else {
                let month = $birl.short_string_month(notification.date);
                let date = $int.to_string($birl.get_day(notification.date).date);
                return $string.join(toList([month, date]), " ");
              }
            })(),
          ),
        ]),
      ),
    ]),
  );
}

export function feed(model) {
  let left = (() => {
    let $ = model.feed_opened;
    if ($) {
      return "350px";
    } else {
      return "-100px";
    }
  })();
  let enabled = $list.any(model.notifications, (n) => { return !n.read; });
  return $s.wrapper(
    toList([$a.id("feed-wrapper"), $a.style(toList([["left", left]]))]),
    listPrepend(
      $s.feed_title(
        toList([$a.id("feed-title")]),
        toList([
          $s.title(toList([]), toList([$h.text("Feed")])),
          $s.mark_all_as_read(
            toList([
              $a.disabled(!enabled),
              $e.on_click(new $msg.MarkAllNotificationsAsRead()),
            ]),
            toList([
              $s.icon_wrapper($icons.double_fill()),
              $h.text("Mark all as read"),
            ]),
          ),
        ]),
      ),
      (() => {
        let _pipe = $list.map(
          model.notifications,
          (_capture) => { return view_notification(model.proposals, _capture); },
        );
        return $list.intersperse(_pipe, $common.separator());
      })(),
    ),
  );
}
