/// <reference types="./documents_stack.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import { attribute } from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import * as $svg from "../../../lustre/lustre/element/svg.mjs";
import { toList } from "../../gleam.mjs";

export function documents_stack() {
  return $html.svg(
    toList([
      attribute("xmlns", "http://www.w3.org/2000/svg"),
      attribute("fill", "none"),
      attribute("viewBox", "0 0 162 162"),
      attribute("height", "162"),
      attribute("width", "162"),
    ]),
    toList([
      $svg.path(
        toList([
          attribute("stroke-width", "1.72711"),
          attribute("stroke", "url(#paint1_linear_6842_2119)"),
          attribute("fill", "url(#paint0_linear_6842_2119)"),
          attribute(
            "d",
            "M37.8636 25.8636H81.9847L93.0687 37.7392L104.176 49.6404V119.716H37.8636V25.8636Z",
          ),
        ]),
      ),
      $svg.path(
        toList([
          attribute("stroke-width", "1.62"),
          attribute("stroke", "url(#paint3_linear_6842_2119)"),
          attribute("fill", "url(#paint2_linear_6842_2119)"),
          attribute("d", "M83.1704 48.49V27.055L103.176 48.49H83.1704Z"),
        ]),
      ),
      $svg.path(
        toList([
          attribute("stroke-width", "1.72711"),
          attribute("stroke", "url(#paint5_linear_6842_2119)"),
          attribute("fill", "url(#paint4_linear_6842_2119)"),
          attribute(
            "d",
            "M57.8636 41.8636H101.985L113.069 53.7392L124.176 65.6404V135.716H57.8636V41.8636Z",
          ),
        ]),
      ),
      $svg.path(
        toList([
          attribute("stroke-width", "1.62"),
          attribute("stroke", "url(#paint7_linear_6842_2119)"),
          attribute("fill", "url(#paint6_linear_6842_2119)"),
          attribute("d", "M103.17 64.49V43.055L123.176 64.49H103.17Z"),
        ]),
      ),
      $svg.defs(
        toList([]),
        toList([
          $svg.linear_gradient(
            toList([
              attribute("gradientUnits", "userSpaceOnUse"),
              attribute("y2", "82.698"),
              attribute("x2", "28.028"),
              attribute("y1", "47.9831"),
              attribute("x1", "166.885"),
              $attribute.id("paint0_linear_6842_2119"),
            ]),
            toList([
              $svg.stop(
                toList([
                  attribute("stop-opacity", "0"),
                  attribute("stop-color", "#E6FAB5"),
                ]),
              ),
              $svg.stop(
                toList([
                  attribute("stop-color", "#E6FAB5"),
                  attribute("offset", "1"),
                ]),
              ),
            ]),
          ),
          $svg.linear_gradient(
            toList([
              attribute("gradientUnits", "userSpaceOnUse"),
              attribute("y2", "-64.8452"),
              attribute("x2", "180.853"),
              attribute("y1", "-81.5284"),
              attribute("x1", "82.5024"),
              $attribute.id("paint1_linear_6842_2119"),
            ]),
            toList([
              $svg.stop(
                toList([
                  attribute("stop-opacity", "0.6"),
                  attribute("stop-color", "#B1DB4B"),
                ]),
              ),
              $svg.stop(
                toList([
                  attribute("stop-opacity", "0"),
                  attribute("stop-color", "#E6FAB5"),
                  attribute("offset", "1"),
                ]),
              ),
            ]),
          ),
          $svg.linear_gradient(
            toList([
              attribute("gradientUnits", "userSpaceOnUse"),
              attribute("y2", "39.1705"),
              attribute("x2", "85.1731"),
              attribute("y1", "35.5299"),
              attribute("x1", "116.02"),
              $attribute.id("paint2_linear_6842_2119"),
            ]),
            toList([
              $svg.stop(
                toList([
                  attribute("stop-opacity", "0"),
                  attribute("stop-color", "#E6FAB5"),
                ]),
              ),
              $svg.stop(
                toList([
                  attribute("stop-opacity", "0.3"),
                  attribute("stop-color", "#B1DB4B"),
                  attribute("offset", "1"),
                ]),
              ),
            ]),
          ),
          $svg.linear_gradient(
            toList([
              attribute("gradientUnits", "userSpaceOnUse"),
              attribute("y2", "-9.86512"),
              attribute("x2", "124.606"),
              attribute("y1", "-12.9516"),
              attribute("x1", "78.0198"),
              $attribute.id("paint3_linear_6842_2119"),
            ]),
            toList([
              $svg.stop(
                toList([
                  attribute("stop-opacity", "0.6"),
                  attribute("stop-color", "#B1DB4B"),
                ]),
              ),
              $svg.stop(
                toList([
                  attribute("stop-opacity", "0"),
                  attribute("stop-color", "#E6FAB5"),
                  attribute("offset", "1"),
                ]),
              ),
            ]),
          ),
          $svg.linear_gradient(
            toList([
              attribute("gradientUnits", "userSpaceOnUse"),
              attribute("y2", "98.698"),
              attribute("x2", "48.028"),
              attribute("y1", "63.9831"),
              attribute("x1", "186.885"),
              $attribute.id("paint4_linear_6842_2119"),
            ]),
            toList([
              $svg.stop(
                toList([
                  attribute("stop-opacity", "0"),
                  attribute("stop-color", "#D3C4FB"),
                ]),
              ),
              $svg.stop(
                toList([
                  attribute("stop-color", "#D3C4FB"),
                  attribute("offset", "1"),
                ]),
              ),
            ]),
          ),
          $svg.linear_gradient(
            toList([
              attribute("gradientUnits", "userSpaceOnUse"),
              attribute("y2", "-48.8452"),
              attribute("x2", "200.853"),
              attribute("y1", "-65.5284"),
              attribute("x1", "102.502"),
              $attribute.id("paint5_linear_6842_2119"),
            ]),
            toList([
              $svg.stop(
                toList([
                  attribute("stop-opacity", "0.3"),
                  attribute("stop-color", "#8565DB"),
                ]),
              ),
              $svg.stop(
                toList([
                  attribute("stop-opacity", "0"),
                  attribute("stop-color", "#D3C4FB"),
                  attribute("offset", "1"),
                ]),
              ),
            ]),
          ),
          $svg.linear_gradient(
            toList([
              attribute("gradientUnits", "userSpaceOnUse"),
              attribute("y2", "55.1705"),
              attribute("x2", "105.173"),
              attribute("y1", "51.5299"),
              attribute("x1", "136.02"),
              $attribute.id("paint6_linear_6842_2119"),
            ]),
            toList([
              $svg.stop(
                toList([
                  attribute("stop-opacity", "0"),
                  attribute("stop-color", "#D3C4FB"),
                ]),
              ),
              $svg.stop(
                toList([
                  attribute("stop-opacity", "0.3"),
                  attribute("stop-color", "#8565DB"),
                  attribute("offset", "1"),
                ]),
              ),
            ]),
          ),
          $svg.linear_gradient(
            toList([
              attribute("gradientUnits", "userSpaceOnUse"),
              attribute("y2", "6.13488"),
              attribute("x2", "144.606"),
              attribute("y1", "3.04841"),
              attribute("x1", "98.0198"),
              $attribute.id("paint7_linear_6842_2119"),
            ]),
            toList([
              $svg.stop(
                toList([
                  attribute("stop-opacity", "0.3"),
                  attribute("stop-color", "#8565DB"),
                ]),
              ),
              $svg.stop(
                toList([
                  attribute("stop-opacity", "0"),
                  attribute("stop-color", "#D3C4FB"),
                  attribute("offset", "1"),
                ]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}
