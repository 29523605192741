/// <reference types="./arrow_dropdown.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function arrow_dropdown() {
  let content = "<g clip-path=\"url(#clip0_6866_9358)\">\n    <path d=\"M10.0002 12.5L6.46436 8.96416L7.64352 7.78583L10.0002 10.1433L12.3569 7.78583L13.536 8.96416L10.0002 12.5Z\" fill=\"currentColor\"/>\n    </g>\n    <defs>\n    </defs>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 20 20"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
