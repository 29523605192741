/// <reference types="./user.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import { Ok, Error, toList, CustomType as $CustomType } from "../gleam.mjs";

export class Role extends $CustomType {
  constructor(id, name) {
    super();
    this.id = id;
    this.name = name;
  }
}

export class Engineering extends $CustomType {}

export class Product extends $CustomType {}

export class Security extends $CustomType {}

export class Sales extends $CustomType {}

export class Legal extends $CustomType {}

export class Marketing extends $CustomType {}

export class Other extends $CustomType {}

export class User extends $CustomType {
  constructor(id, email, name, picture, initials, roles, function$) {
    super();
    this.id = id;
    this.email = email;
    this.name = name;
    this.picture = picture;
    this.initials = initials;
    this.roles = roles;
    this.function = function$;
  }
}

export function encode_roles(role) {
  return $json.object(
    toList([["id", $json.string(role.id)], ["name", $json.string(role.name)]]),
  );
}

export function function_to_string(function$) {
  if (function$ instanceof Engineering) {
    return "engineering";
  } else if (function$ instanceof Product) {
    return "product";
  } else if (function$ instanceof Security) {
    return "security";
  } else if (function$ instanceof Sales) {
    return "sales";
  } else if (function$ instanceof Legal) {
    return "legal";
  } else if (function$ instanceof Marketing) {
    return "marketing";
  } else {
    return "other";
  }
}

export function encode_function(function$) {
  let _pipe = function_to_string(function$);
  return $json.string(_pipe);
}

export function encode(user) {
  return $json.object(
    toList([
      ["id", $json.string(user.id)],
      ["email", $json.string(user.email)],
      ["name", $json.string(user.name)],
      ["picture", $json.nullable(user.picture, $json.string)],
      ["roles", $json.array(user.roles, encode_roles)],
      ["function", $json.nullable(user.function, encode_function)],
    ]),
  );
}

export function decode_roles(dyn) {
  return $dynamic.decode2(
    (var0, var1) => { return new Role(var0, var1); },
    $dynamic.field("id", $dynamic.string),
    $dynamic.field("name", $dynamic.string),
  )(dyn);
}

export function decode_function(dyn) {
  return $result.try$(
    $dynamic.string(dyn),
    (function$) => {
      if (function$ === "engineering") {
        return new Ok(new Engineering());
      } else if (function$ === "product") {
        return new Ok(new Product());
      } else if (function$ === "security") {
        return new Ok(new Security());
      } else if (function$ === "sales") {
        return new Ok(new Sales());
      } else if (function$ === "legal") {
        return new Ok(new Legal());
      } else if (function$ === "marketing") {
        return new Ok(new Marketing());
      } else if (function$ === "other") {
        return new Ok(new Other());
      } else {
        return new Error(
          toList([
            new $dynamic.DecodeError(
              "function",
              function$,
              toList(["function"]),
            ),
          ]),
        );
      }
    },
  );
}

function initials(name) {
  let _pipe = name;
  let _pipe$1 = $string.split(_pipe, " ");
  let _pipe$2 = $list.map(
    _pipe$1,
    (_capture) => { return $string.slice(_capture, 0, 1); },
  );
  let _pipe$3 = $list.map(_pipe$2, $string.capitalise);
  return $string.join(_pipe$3, "");
}

export function decode(dyn) {
  return $dynamic.decode6(
    (id, email, name, picture, roles, function$) => {
      let initials$1 = initials(name);
      return new User(id, email, name, picture, initials$1, roles, function$);
    },
    $dynamic.field("id", $dynamic.string),
    $dynamic.field("email", $dynamic.string),
    $dynamic.field("name", $dynamic.string),
    $dynamic.field("picture", $dynamic.optional($dynamic.string)),
    $dynamic.field("roles", $dynamic.list(decode_roles)),
    $dynamic.field("function", $dynamic.optional(decode_function)),
  )(dyn);
}
