/// <reference types="./progress_bar.d.mts" />
import * as $function from "../../../../gleam_stdlib/gleam/function.mjs";
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $string from "../../../../gleam_stdlib/gleam/string.mjs";
import * as $html from "../../../../lustre/lustre/element/html.mjs";
import * as $sketch from "../../../../sketch/sketch.mjs";
import * as $size from "../../../../sketch/sketch/size.mjs";
import { px } from "../../../../sketch/sketch/size.mjs";
import { toList, divideInt } from "../../../gleam.mjs";
import * as $toast from "../../../grille_pain/internals/data/toast.mjs";
import * as $colors from "../../../grille_pain/internals/view/colors.mjs";
import * as $level from "../../../grille_pain/toast/level.mjs";

function pb_base() {
  let _pipe = toList([
    $sketch.animation_fill_mode("forwards"),
    $sketch.height(px(5)),
  ]);
  let _pipe$1 = $sketch.class$(_pipe);
  return $sketch.to_lustre(_pipe$1);
}

function pb_animation(duration) {
  let duration_ = $int.to_string(divideInt(duration, 1000));
  let _pipe = toList([$sketch.animation(duration_ + "s linear 0s progress_bar")]);
  let _pipe$1 = ((_capture) => {
    return $sketch.dynamic("toast-duration-" + duration_, _capture);
  })(_pipe);
  return $sketch.to_lustre(_pipe$1);
}

function pb_background_color(level) {
  let back_color = $colors.progress_bar_from_level(level);
  let level$1 = $level.to_string(level);
  let background = ((("var(--grille_pain-" + level$1) + "-progress-bar, ") + back_color) + ")";
  let _pipe = $string.join(
    toList(["grille_pain", "pb", "background", back_color]),
    "-",
  );
  let _pipe$1 = $sketch.dynamic(_pipe, toList([$sketch.background(background)]));
  return $sketch.to_lustre(_pipe$1);
}

function pb_play_state(running) {
  let running_str = $toast.running_to_string(running);
  let _pipe = $string.join(
    toList(["grille_pain", "pb", "play-state", running_str]),
    "-",
  );
  let _pipe$1 = $sketch.dynamic(
    _pipe,
    toList([$sketch.animation_play_state(running_str)]),
  );
  return $sketch.to_lustre(_pipe$1);
}

export function view(toast) {
  return $function.flip($html.div)(
    toList([]),
    toList([
      pb_play_state(toast.running),
      pb_background_color(toast.level),
      pb_animation(toast.animation_duration),
      pb_base(),
    ]),
  );
}
