/// <reference types="./layout.d.mts" />
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../gleam_stdlib/gleam/option.mjs";
import * as $a from "../../lustre/lustre/attribute.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $h from "../../lustre/lustre/element/html.mjs";
import * as $model from "../data/model.mjs";
import * as $route from "../data/route.mjs";
import * as $layout from "../design_system/components/layout.mjs";
import * as $skeleton from "../design_system/components/skeleton.mjs";
import { toList } from "../gleam.mjs";
import * as $content_library from "../view/content_library.mjs";
import * as $copilot from "../view/copilot.mjs";
import * as $feed from "../view/feed.mjs";
import * as $navbar from "../view/navbar.mjs";
import * as $organization from "../view/organization.mjs";
import * as $overview from "../view/overview.mjs";
import * as $proposals from "../view/proposals.mjs";
import * as $settings from "../view/settings.mjs";
import * as $s from "../view/styles.mjs";

function select_page_title(route) {
  if (route instanceof $route.Overview) {
    return new $option.Some("Overview");
  } else if (route instanceof $route.CoPilot) {
    return new $option.Some("Co-pilot");
  } else if (route instanceof $route.ContentLibrary) {
    return new $option.Some("Content Library");
  } else if (route instanceof $route.Proposals && route[0] instanceof $route.Add) {
    return new $option.Some("New Proposal");
  } else if (route instanceof $route.Proposals &&
  route[0] instanceof $route.OnGoing) {
    return new $option.Some("Proposals");
  } else if (route instanceof $route.Proposals &&
  route[0] instanceof $route.Previous) {
    return new $option.Some("Proposals");
  } else if (route instanceof $route.Settings) {
    return new $option.Some("Settings");
  } else if (route instanceof $route.Organization) {
    return new $option.Some("Organization");
  } else if (route instanceof $route.Support) {
    return new $option.Some("Support");
  } else if (route instanceof $route.NotFound) {
    return new $option.Some("Not Found");
  } else {
    return new $option.None();
  }
}

function select_catchphrase(model) {
  let user = $model.find_current_user(model);
  let $ = model.route;
  if ($ instanceof $route.Overview) {
    return new $option.Some(
      [
        $layout.row(
          toList([$layout.align("center"), $layout.gap(16)]),
          toList([]),
          toList([
            $el.text("Hello "),
            $skeleton.hide_loading(
              user,
              40,
              200,
              (user) => { return $el.text(user.name); },
            ),
          ]),
        ),
        $el.text("Your overview and requests at a glance."),
      ],
    );
  } else if ($ instanceof $route.NotFound) {
    return new Some([$el.text("Not Found"), $el.text("Not Found")]);
  } else {
    return new None();
  }
}

export function view(model) {
  let page_title = select_page_title(model.route);
  let catchphrase = select_catchphrase(model);
  return $s.layout(
    toList([]),
    toList([
      $navbar.navbar(model),
      $feed.feed(model),
      $s.main(
        toList([$a.id("layout-main")]),
        toList([
          (() => {
            if (page_title instanceof $option.None &&
            catchphrase instanceof $option.None) {
              return $el.none();
            } else if (page_title instanceof $option.None &&
            catchphrase instanceof $option.Some) {
              let title = catchphrase[0][0];
              let subtitle = catchphrase[0][1];
              return $s.page_header(
                toList([]),
                toList([
                  $s.page_catchphrase(
                    toList([]),
                    toList([
                      $s.catch_title(toList([]), toList([title])),
                      $s.catch_subtitle(toList([]), toList([subtitle])),
                    ]),
                  ),
                ]),
              );
            } else if (page_title instanceof $option.Some &&
            catchphrase instanceof $option.None) {
              let t = page_title[0];
              return $s.page_header(
                toList([]),
                toList([$s.page_title(toList([]), toList([$h.text(t)]))]),
              );
            } else {
              let t = page_title[0];
              let title = catchphrase[0][0];
              let subtitle = catchphrase[0][1];
              return $s.page_header(
                toList([]),
                toList([
                  $s.page_title(toList([]), toList([$h.text(t)])),
                  $s.page_catchphrase(
                    toList([]),
                    toList([
                      $s.catch_title(toList([]), toList([title])),
                      $s.catch_subtitle(toList([]), toList([subtitle])),
                    ]),
                  ),
                ]),
              );
            }
          })(),
          (() => {
            let $ = model.route;
            if ($ instanceof $route.Overview) {
              return $overview.view(model);
            } else if ($ instanceof $route.Organization) {
              return $organization.view(model);
            } else if ($ instanceof $route.ContentLibrary) {
              return $content_library.view(model);
            } else if ($ instanceof $route.Proposals) {
              return $proposals.view(model);
            } else if ($ instanceof $route.CoPilot) {
              return $copilot.view(model);
            } else if ($ instanceof $route.Settings) {
              return $settings.view(model);
            } else {
              return $el.none();
            }
          })(),
        ]),
      ),
    ]),
  );
}
