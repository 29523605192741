/// <reference types="./login.d.mts" />
import * as $s from "../../../sketch/sketch.mjs";
import * as $el from "../../../sketch/sketch/lustre/element.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { px, vh } from "../../../sketch/sketch/size.mjs";
import * as $colors from "../../design_system/colors.mjs";
import * as $display from "../../design_system/typography/display.mjs";
import * as $text from "../../design_system/typography/text.mjs";
import { toList } from "../../gleam.mjs";

export function wrapper(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([$s.display("flex"), $s.flex_direction("column"), $s.height(vh(100))]),
  );
}

export function background(attrs) {
  return $el.element(
    "img",
    attrs,
    toList([]),
    toList([
      $s.position("fixed"),
      $s.z_index(-1),
      $s.bottom(px(0)),
      $s.right(px(0)),
    ]),
  );
}

export function topbar(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.gap(px(12)),
      $s.padding(px(24)),
    ]),
  );
}

export function topbar_logo(attrs, children) {
  return $el.element("div", attrs, children, toList([$s.width(px(35))]));
}

export function topbar_name(attrs, children) {
  return $el.element(
    "span",
    attrs,
    children,
    toList([$s.compose($display.md())]),
  );
}

export function body(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.flex("1"),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(36)),
      $s.justify_content("center"),
      $s.align_items("center"),
      $s.max_width(px(500)),
      $s.margin_("0 auto"),
      $s.compose($text.md($text.regular)),
    ]),
  );
}

export function body_glass(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.background("#fff7"),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(48)),
      $s.padding(px(48)),
      $s.border_radius(px(20)),
      $s.border("1px solid " + $colors.light.grey_400),
      $s.property("backdrop_filter", "blur(16px)"),
    ]),
  );
}

export function body_title(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([$s.compose($display.lg())]),
  );
}

export function thanks(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([$s.compose($display.sm()), $s.text_align("right")]),
  );
}
