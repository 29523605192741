/// <reference types="./dropdown.d.mts" />
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $s from "../../../../sketch/sketch.mjs";
import * as $el from "../../../../sketch/sketch/lustre/element.mjs";
import * as $size from "../../../../sketch/sketch/size.mjs";
import { percent, px, vh } from "../../../../sketch/sketch/size.mjs";
import * as $colors from "../../../design_system/colors.mjs";
import * as $shadows from "../../../design_system/shadows.mjs";
import * as $text from "../../../design_system/typography/text.mjs";
import { toList } from "../../../gleam.mjs";

export function full_wrapper(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.position("relative"),
      $s.width(percent(100)),
    ]),
  );
}

export function wrapper(attrs, children) {
  return $el.element(
    "label",
    attrs,
    children,
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.align_items("start"),
      $s.gap(px(6)),
      $s.compose($text.sm($text.medium)),
      $s.width(percent(100)),
    ]),
  );
}

function dropdown_base() {
  return $s.class$(
    toList([
      $s.appearance("none"),
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("space-between"),
      $s.background($colors.light.white),
      $s.border("1px solid " + $colors.light.grey_400),
      $s.border_radius(px(8)),
      $s.gap(px(8)),
      $s.padding_("10px 14px"),
      $s.compose($text.md($text.medium)),
      $s.cursor("pointer"),
      $s.position("relative"),
      $s.transition("all .3s"),
      $s.white_space("nowrap"),
    ]),
  );
}

export function standard(attrs, children) {
  return $el.element(
    "button",
    attrs,
    children,
    toList([$s.compose(dropdown_base())]),
  );
}

export function disabled(attrs, children) {
  return $el.element(
    "button",
    attrs,
    children,
    toList([$s.compose(dropdown_base())]),
  );
}

export function active(attrs, children) {
  return $el.element(
    "button",
    attrs,
    children,
    toList([
      $s.compose(dropdown_base()),
      $s.box_shadow("0px 0px 0px 4px " + $colors.light.grey_100),
    ]),
  );
}

export function text_icon_wrapper(active, attrs, children) {
  let id = "icon-wrapper-active-" + $bool.to_string(active);
  return $el.dynamic(
    "div",
    attrs,
    children,
    id,
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.gap(px(8)),
      $s.color(
        (() => {
          if (active) {
            return $colors.light.onyx;
          } else {
            return $colors.light.grey_800;
          }
        })(),
      ),
    ]),
  );
}

export function full_icon_wrapper(child) {
  return $el.element(
    "div",
    toList([]),
    toList([child]),
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("center"),
      $s.width(px(20)),
      $s.height(px(20)),
    ]),
  );
}

export function children_wrapper(root_height, attrs, children) {
  let id = "children_wrapper-" + $int.to_string(root_height);
  return $el.dynamic(
    "div",
    attrs,
    children,
    id,
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.position("absolute"),
      $s.top(px(root_height + 8)),
      $s.background($colors.light.white),
      $s.width(percent(100)),
      $s.border("1px solid " + $colors.light.grey_100),
      $s.box_shadow($shadows.m()),
      $s.border_radius(px(8)),
      $s.max_height(vh(30)),
      $s.overflow("auto"),
    ]),
  );
}

export function choice_wrapper(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("space-between"),
      $s.padding_("10px 14px"),
      $s.cursor("pointer"),
      $s.color($colors.light.grey_800),
      $s.hover(toList([$s.background($colors.light.grey_200)])),
    ]),
  );
}

export function icon_wrapper(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([$s.width(px(20)), $s.height(px(20))]),
  );
}
