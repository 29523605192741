/// <reference types="./table.d.mts" />
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $string from "../../../../gleam_stdlib/gleam/string.mjs";
import * as $s from "../../../../sketch/sketch.mjs";
import * as $el from "../../../../sketch/sketch/lustre/element.mjs";
import * as $size from "../../../../sketch/sketch/size.mjs";
import { percent, px } from "../../../../sketch/sketch/size.mjs";
import * as $colors from "../../../design_system/colors.mjs";
import * as $text from "../../../design_system/typography/text.mjs";
import { toList } from "../../../gleam.mjs";

export function table(columns, attrs, children) {
  let template = columns[1];
  let id = "table-" + template;
  return $el.dynamic(
    "table",
    attrs,
    children,
    id,
    toList([
      $s.border_radius(px(8)),
      $s.border("1px solid " + $colors.light.grey_400),
      $s.display("grid"),
      $s.grid_template_columns(template),
      $s.overflow("auto"),
    ]),
  );
}

export function theader(attrs, children) {
  return $el.element(
    "th",
    attrs,
    children,
    toList([
      $s.padding_("10px 20px"),
      $s.background($colors.light.grey_100),
      $s.color($colors.light.onyx),
      $s.white_space("nowrap"),
      $s.compose($text.sm($text.medium)),
    ]),
  );
}

export function tfoot(columns, attrs, children) {
  let columns$1 = $int.to_string(columns);
  let id = "tfoot-" + columns$1;
  return $el.dynamic(
    "tfoot",
    attrs,
    children,
    id,
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.grid_column("span " + columns$1),
      $s.justify_content("space-between"),
      $s.padding_("16px 24px"),
      $s.pseudo_selector(
        "[disabled='True']",
        toList([$s.pointer_events("none"), $s.opacity(0.3)]),
      ),
    ]),
  );
}

export function tcell(custom_style, attrs, children) {
  let id = "tcell-" + $string.inspect(custom_style);
  return $el.dynamic(
    "td",
    attrs,
    children,
    id,
    $list.append(
      custom_style,
      toList([
        $s.overflow("visible"),
        $s.text_overflow("ellipsis"),
        $s.max_width(percent(100)),
        $s.padding(px(20)),
        $s.white_space("wrap"),
      ]),
    ),
  );
}

export function footer_dropdown(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([$s.display("flex"), $s.align_items("center"), $s.gap(px(24))]),
  );
}

export function footer_actions(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([$s.display("flex"), $s.align_items("center"), $s.gap(px(16))]),
  );
}

function subgrid(count) {
  let column_end = $int.to_string(count + 1);
  let id = "subgrid-columns-" + column_end;
  return $s.dynamic(
    id,
    toList([
      $s.display("grid"),
      $s.grid_template_columns("subgrid"),
      $s.grid_column("1 / " + column_end),
    ]),
  );
}

export function thead(columns, attrs, children) {
  let count = columns[0];
  let template = columns[1];
  let id = "thead-" + template;
  return $el.dynamic(
    "thead",
    attrs,
    children,
    id,
    toList([
      $s.compose(subgrid(count)),
      $s.border_bottom("1px solid " + $colors.light.grey_400),
      $s.text_align("left"),
      $s.last_child(toList([$s.border_bottom("none")])),
      $s.border_top_right_radius(px(7)),
      $s.border_top_left_radius(px(7)),
      $s.overflow("hidden"),
    ]),
  );
}

export function thr(column, attrs, children) {
  let count = column[0];
  let template = column[1];
  let id = "thr-" + template;
  return $el.dynamic(
    "tr",
    attrs,
    children,
    id,
    toList([$s.compose(subgrid(count))]),
  );
}

export function tbody(columns, attrs, children) {
  let count = columns[0];
  let template = columns[1];
  let id = "tbody-" + template;
  return $el.dynamic(
    "tbody",
    attrs,
    children,
    id,
    toList([
      $s.compose(subgrid(count)),
      $s.compose($text.sm($text.regular)),
      $s.color($colors.light.paragraph),
      $s.white_space("nowrap"),
      $s.overflow("auto"),
    ]),
  );
}

export function tr(columns, hover, attrs, children) {
  let count = columns[0];
  let template = columns[1];
  let id = (("tr-" + template) + "-") + $bool.to_string(hover);
  return $el.dynamic(
    "tr",
    attrs,
    children,
    id,
    toList([
      $s.compose(subgrid(count)),
      $s.align_items("center"),
      $s.hover(
        (() => {
          if (hover) {
            return toList([$s.background($colors.light.grey_100)]);
          } else {
            return toList([]);
          }
        })(),
      ),
    ]),
  );
}

export function separator(columns) {
  let count = columns[0];
  let template = columns[1];
  let id = "separator-" + template;
  return $el.dynamic(
    "tr",
    toList([]),
    toList([]),
    id,
    toList([
      $s.compose(subgrid(count)),
      $s.height(px(1)),
      $s.background($colors.light.grey_200),
    ]),
  );
}

export function icon_wrapper(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([$s.width(px(20)), $s.height(px(20))]),
  );
}
