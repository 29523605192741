/// <reference types="./colors.d.mts" />
import * as $ffi from "../../../grille_pain/internals/ffi.mjs";
import * as $level from "../../../grille_pain/toast/level.mjs";
import { Error, Info, Standard, Success, Warning } from "../../../grille_pain/toast/level.mjs";

export const info = "#3498db";

export const success = "#07bc0c";

export const warning = "#f1c40f";

export const error = "#e74c3c";

export const color_transparent = "rgba(255, 255, 255, 0.7)";

export const light_transparent = "rgb(0, 0, 0, 0.7)";

export const dark_transparent = "rgb(255, 255, 255, 0.7)";

export function progress_bar_from_level(level) {
  if (level instanceof Info) {
    return color_transparent;
  } else if (level instanceof Success) {
    return color_transparent;
  } else if (level instanceof Warning) {
    return color_transparent;
  } else if (level instanceof Error) {
    return color_transparent;
  } else {
    let $ = $ffi.is_dark_theme();
    if ($) {
      return dark_transparent;
    } else {
      return light_transparent;
    }
  }
}

export const dark = "#121212";

export const light = "#fff";

function from_standard() {
  let $ = $ffi.is_dark_theme();
  if ($) {
    return [dark, light];
  } else {
    return [light, dark];
  }
}

export function from_level(level) {
  if (level instanceof Standard) {
    return from_standard();
  } else if (level instanceof Info) {
    return [info, light];
  } else if (level instanceof Success) {
    return [success, light];
  } else if (level instanceof Warning) {
    return [warning, light];
  } else {
    return [error, light];
  }
}
