/// <reference types="./price_tag.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function price_tag() {
  let content = "<path d=\"M10.9 2.52136L20.799 3.93636L22.213 13.8364L13.021 23.0284C12.8334 23.2158 12.5791 23.3211 12.314 23.3211C12.0488 23.3211 11.7945 23.2158 11.607 23.0284L1.70697 13.1284C1.5195 12.9408 1.41418 12.6865 1.41418 12.4214C1.41418 12.1562 1.5195 11.9019 1.70697 11.7144L10.9 2.52136ZM11.607 4.64336L3.82797 12.4214L12.314 20.9064L20.092 13.1284L19.032 5.70336L11.607 4.64336ZM13.727 11.0074C13.3518 10.6321 13.1411 10.1231 13.1412 9.59251C13.1413 9.32977 13.1931 9.0696 13.2936 8.82688C13.3942 8.58415 13.5417 8.36362 13.7275 8.17786C13.9133 7.99211 14.1339 7.84477 14.3766 7.74427C14.6194 7.64376 14.8796 7.59206 15.1423 7.5921C15.673 7.5922 16.1818 7.80308 16.557 8.17836C16.9321 8.55364 17.1428 9.06258 17.1427 9.59322C17.1426 10.1239 16.9318 10.6327 16.5565 11.0079C16.1812 11.383 15.6723 11.5937 15.1416 11.5936C14.611 11.5935 14.1021 11.3826 13.727 11.0074Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 24 25"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
