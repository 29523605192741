/// <reference types="./content_library.d.mts" />
import * as $birl from "../../birl/birl.mjs";
import * as $connector from "../../common/data/connector.mjs";
import * as $data_source from "../../common/data/data_source.mjs";
import { DataSource } from "../../common/data/data_source.mjs";
import * as $tag from "../../common/data/tag.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $msg from "../data/msg.mjs";
import { toList, prepend as listPrepend, CustomType as $CustomType, isEqual } from "../gleam.mjs";
import * as $utils from "../utils.mjs";

export class NewQna extends $CustomType {
  constructor(question, answer, loading, existing_document) {
    super();
    this.question = question;
    this.answer = answer;
    this.loading = loading;
    this.existing_document = existing_document;
  }
}

export class Status extends $CustomType {
  constructor(verified, pending, not_verified, outdated) {
    super();
    this.verified = verified;
    this.pending = pending;
    this.not_verified = not_verified;
    this.outdated = outdated;
  }
}

export class Sources extends $CustomType {
  constructor(all, verified, pending, not_verified, outdated) {
    super();
    this.all = all;
    this.verified = verified;
    this.pending = pending;
    this.not_verified = not_verified;
    this.outdated = outdated;
  }
}

export class ContentLibrary extends $CustomType {
  constructor(non_qna_sources, non_qna_status, non_qna_size, qna_sources, qna_status, qna_size, selected, change_owner_popup_opened, change_status_popup_opened, change_tags_popup_opened, change_expiration_date_popup_opened, change_expiration_date_input, filter_selected, search, new_qna) {
    super();
    this.non_qna_sources = non_qna_sources;
    this.non_qna_status = non_qna_status;
    this.non_qna_size = non_qna_size;
    this.qna_sources = qna_sources;
    this.qna_status = qna_status;
    this.qna_size = qna_size;
    this.selected = selected;
    this.change_owner_popup_opened = change_owner_popup_opened;
    this.change_status_popup_opened = change_status_popup_opened;
    this.change_tags_popup_opened = change_tags_popup_opened;
    this.change_expiration_date_popup_opened = change_expiration_date_popup_opened;
    this.change_expiration_date_input = change_expiration_date_input;
    this.filter_selected = filter_selected;
    this.search = search;
    this.new_qna = new_qna;
  }
}

export function init_new_qna() {
  return new NewQna("", "", false, new $option.None());
}

export function set_filter(content_library, filter) {
  return content_library.withFields({ filter_selected: filter });
}

function do_add_source(sources, source) {
  if (sources.atLeastLength(1) && (sources.head.id === source.id)) {
    let s = sources.head;
    let rest = sources.tail;
    return listPrepend(source, rest);
  } else if (sources.atLeastLength(1)) {
    let s = sources.head;
    let rest = sources.tail;
    return listPrepend(s, do_add_source(rest, source));
  } else {
    return toList([source]);
  }
}

function compute_sources(all) {
  return new Sources(
    all,
    $list.filter(
      all,
      (s) => { return isEqual(s.status, new $data_source.Verified()); },
    ),
    $list.filter(
      all,
      (s) => { return isEqual(s.status, new $data_source.Pending()); },
    ),
    $list.filter(
      all,
      (s) => { return isEqual(s.status, new $data_source.NotVerified()); },
    ),
    $list.filter(
      all,
      (s) => { return isEqual(s.status, new $data_source.Outdated()); },
    ),
  );
}

function update_sources(sources, mapper) {
  let all = $list.map(sources.all, mapper);
  return compute_sources(all);
}

export function add_source(content_library, source) {
  let $ = source.kind;
  if ($ instanceof $connector.SteerlabQna) {
    let sources = do_add_source(content_library.qna_sources.all, source);
    let qna_size = $list.length(sources);
    let qna_sources = compute_sources(sources);
    return content_library.withFields({
      qna_sources: qna_sources,
      qna_size: qna_size
    });
  } else {
    let sources = do_add_source(content_library.non_qna_sources.all, source);
    let non_qna_size = $list.length(sources);
    let non_qna_sources = compute_sources(sources);
    return content_library.withFields({
      non_qna_sources: non_qna_sources,
      non_qna_size: non_qna_size
    });
  }
}

function compute_statuses(sources) {
  return $list.fold(
    sources,
    new Status(0, 0, 0, 0),
    (statuses, source) => {
      let $ = source.status;
      if ($ instanceof $data_source.Verified) {
        return statuses.withFields({ verified: statuses.verified + 1 });
      } else if ($ instanceof $data_source.Pending) {
        return statuses.withFields({ pending: statuses.pending + 1 });
      } else if ($ instanceof $data_source.Outdated) {
        return statuses.withFields({ outdated: statuses.outdated + 1 });
      } else if ($ instanceof $data_source.NotVerified) {
        return statuses.withFields({ not_verified: statuses.not_verified + 1 });
      } else {
        return statuses;
      }
    },
  );
}

export function add_sources(content_library, sources) {
  let qna_sources = $list.filter(
    sources,
    (s) => { return isEqual(s.kind, new $connector.SteerlabQna()); },
  );
  let sources$1 = $list.filter(
    sources,
    (s) => { return !isEqual(s.kind, new $connector.SteerlabQna()); },
  );
  let non_qna_size = $list.length(sources$1);
  let qna_size = $list.length(qna_sources);
  let non_qna_status = compute_statuses(sources$1);
  let non_qna_sources = compute_sources(sources$1);
  let qna_status = compute_statuses(qna_sources);
  let qna_sources$1 = compute_sources(qna_sources);
  return content_library.withFields({
    non_qna_sources: non_qna_sources,
    non_qna_status: non_qna_status,
    non_qna_size: non_qna_size,
    qna_sources: qna_sources$1,
    qna_status: qna_status,
    qna_size: qna_size
  });
}

function update_tags(s, tag, add_remove) {
  let tags = (() => {
    if (add_remove instanceof $msg.Remove) {
      return $list.filter(s.tags, (t) => { return t !== tag.id; });
    } else {
      let _pipe = $list.reverse(s.tags);
      let _pipe$1 = $list.prepend(_pipe, tag.id);
      let _pipe$2 = $list.reverse(_pipe$1);
      return $list.unique(_pipe$2);
    }
  })();
  return s.withFields({ tags: tags });
}

export function select(content_library, id) {
  let selected = $dict.insert(content_library.selected, id, true);
  return content_library.withFields({ selected: selected });
}

export function deselect(content_library, id) {
  let selected = $dict.delete$(content_library.selected, id);
  return content_library.withFields({ selected: selected });
}

export function is_selected(content_library, id) {
  let _pipe = $dict.get(content_library.selected, id);
  return $result.unwrap(_pipe, false);
}

function update_selected_sources(content_library, mapper) {
  return update_sources(
    content_library.non_qna_sources,
    (source) => {
      let is_selected$1 = is_selected(content_library, source.id);
      return $bool.guard(
        !is_selected$1,
        source,
        () => { return mapper(source); },
      );
    },
  );
}

function update_selected_qna_sources(content_library, mapper) {
  return update_sources(
    content_library.qna_sources,
    (source) => {
      let is_selected$1 = is_selected(content_library, source.id);
      return $bool.guard(
        !is_selected$1,
        source,
        () => { return mapper(source); },
      );
    },
  );
}

export function attribute_source(content_library, user_id) {
  let owner = new $option.Some(user_id);
  let update_owner = (s) => { return s.withFields({ owner: owner }); };
  let non_qna_sources = update_selected_sources(content_library, update_owner);
  let qna_sources = update_selected_qna_sources(content_library, update_owner);
  return content_library.withFields({
    non_qna_sources: non_qna_sources,
    qna_sources: qna_sources,
    selected: $dict.new$()
  });
}

export function attribute_status(content_library, status) {
  let update_status = (s) => { return s.withFields({ status: status }); };
  let non_qna_sources = update_selected_sources(content_library, update_status);
  let non_qna_status = compute_statuses(non_qna_sources.all);
  let qna_sources = update_selected_qna_sources(content_library, update_status);
  let qna_status = compute_statuses(qna_sources.all);
  return content_library.withFields({
    non_qna_sources: non_qna_sources,
    selected: $dict.new$(),
    non_qna_status: non_qna_status,
    qna_sources: qna_sources,
    qna_status: qna_status
  });
}

export function attribute_tag(content_library, tag, add_remove) {
  let update_tags$1 = (_capture) => {
    return update_tags(_capture, tag, add_remove);
  };
  let non_qna_sources = update_selected_sources(content_library, update_tags$1);
  let qna_sources = update_selected_qna_sources(content_library, update_tags$1);
  return content_library.withFields({
    non_qna_sources: non_qna_sources,
    qna_sources: qna_sources,
    selected: $dict.new$()
  });
}

export function attribute_expiration_date(content_library, expiration_date) {
  let expiration_date$1 = new $option.Some(expiration_date);
  let update_expiration_date = (s) => {
    return s.withFields({ expiration_date: expiration_date$1 });
  };
  let non_qna_sources = update_selected_sources(
    content_library,
    update_expiration_date,
  );
  let qna_sources = update_selected_qna_sources(
    content_library,
    update_expiration_date,
  );
  return content_library.withFields({
    non_qna_sources: non_qna_sources,
    qna_sources: qna_sources,
    selected: $dict.new$()
  });
}

export function all_non_qna_selected(content_library) {
  return $dict.size(content_library.selected) === content_library.non_qna_size;
}

export function all_qna_selected(content_library) {
  return $dict.size(content_library.selected) === content_library.qna_size;
}

function new_status() {
  return new Status(0, 0, 0, 0);
}

export function new$() {
  let now = $birl.utc_now();
  return new ContentLibrary(
    compute_sources(toList([])),
    new_status(),
    0,
    compute_sources(toList([])),
    new_status(),
    0,
    $dict.new$(),
    false,
    false,
    false,
    false,
    $utils.time_to_qualified_day(now, "-"),
    new $option.None(),
    "",
    init_new_qna(),
  );
}

export function toggle_change_owner(content_library) {
  return content_library.withFields({
    change_owner_popup_opened: !content_library.change_owner_popup_opened,
    change_status_popup_opened: false,
    change_tags_popup_opened: false,
    change_expiration_date_popup_opened: false
  });
}

export function toggle_change_expiration_date(content_library) {
  return content_library.withFields({
    change_owner_popup_opened: false,
    change_status_popup_opened: false,
    change_tags_popup_opened: false,
    change_expiration_date_popup_opened: !content_library.change_expiration_date_popup_opened,
    change_expiration_date_input: $utils.time_to_qualified_day(
      $birl.utc_now(),
      "-",
    )
  });
}

export function toggle_change_status(content_library) {
  return content_library.withFields({
    change_status_popup_opened: !content_library.change_status_popup_opened,
    change_owner_popup_opened: false,
    change_tags_popup_opened: false,
    change_expiration_date_popup_opened: false
  });
}

export function toggle_change_tags(content_library) {
  return content_library.withFields({
    change_tags_popup_opened: !content_library.change_tags_popup_opened,
    change_owner_popup_opened: false,
    change_status_popup_opened: false,
    change_expiration_date_popup_opened: false
  });
}

export function close_popups(content_library) {
  return content_library.withFields({
    change_tags_popup_opened: false,
    change_owner_popup_opened: false,
    change_status_popup_opened: false,
    change_expiration_date_popup_opened: false
  });
}
