/// <reference types="./options.d.mts" />
import { CustomType as $CustomType } from "../gleam.mjs";

class Node extends $CustomType {}

class Document extends $CustomType {}

class Shadow extends $CustomType {}

class Options extends $CustomType {
  constructor(stylesheet) {
    super();
    this.stylesheet = stylesheet;
  }
}

export function node() {
  return new Options(new Node());
}

export function document() {
  return new Options(new Document());
}

export function shadow() {
  return new Options(new Shadow());
}

export function stylesheet_to_string(stylesheet) {
  if (stylesheet instanceof Node) {
    return "node";
  } else if (stylesheet instanceof Document) {
    return "document";
  } else {
    return "shadow-root";
  }
}
