/// <reference types="./model.d.mts" />
import * as $auth0 from "../../auth0_client/auth0/client.mjs";
import * as $birl from "../../birl/birl.mjs";
import * as $connector from "../../common/data/connector.mjs";
import * as $confluence from "../../common/data/connector/confluence.mjs";
import * as $connector_settings from "../../common/data/connector_settings.mjs";
import * as $copilot_question from "../../common/data/copilot_question.mjs";
import * as $data_point from "../../common/data/data_point.mjs";
import * as $data_source from "../../common/data/data_source.mjs";
import * as $notification from "../../common/data/notification.mjs";
import * as $proposal from "../../common/data/proposal.mjs";
import * as $question from "../../common/data/question.mjs";
import * as $tag from "../../common/data/tag.mjs";
import * as $user from "../../common/data/user.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $float from "../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $content_library from "../data/content_library.mjs";
import * as $route from "../data/route.mjs";
import * as $copilot_input from "../data/ui/copilot_input.mjs";
import * as $loading from "../data/ui/loading.mjs";
import * as $new_proposal from "../data/ui/new_proposal.mjs";
import { readPermissions as read_permissions } from "../frontend.ffi.mjs";
import {
  toList,
  prepend as listPrepend,
  CustomType as $CustomType,
  divideFloat,
  isEqual,
} from "../gleam.mjs";

export class Connectors extends $CustomType {
  constructor(fetched, confluence, confluence_opened, google_folder_id, settings) {
    super();
    this.fetched = fetched;
    this.confluence = confluence;
    this.confluence_opened = confluence_opened;
    this.google_folder_id = google_folder_id;
    this.settings = settings;
  }
}

export class Model extends $CustomType {
  constructor(collaborators_proposal_opened, more_proposal_opened, route, client, access_token, permissions, tags, user, proposals, questions, data_sources, data_points, copilot_input, copilot_threads, connectors, new_proposal, content_library, users, feed_opened, notifications, notifications_unread, loading) {
    super();
    this.collaborators_proposal_opened = collaborators_proposal_opened;
    this.more_proposal_opened = more_proposal_opened;
    this.route = route;
    this.client = client;
    this.access_token = access_token;
    this.permissions = permissions;
    this.tags = tags;
    this.user = user;
    this.proposals = proposals;
    this.questions = questions;
    this.data_sources = data_sources;
    this.data_points = data_points;
    this.copilot_input = copilot_input;
    this.copilot_threads = copilot_threads;
    this.connectors = connectors;
    this.new_proposal = new_proposal;
    this.content_library = content_library;
    this.users = users;
    this.feed_opened = feed_opened;
    this.notifications = notifications;
    this.notifications_unread = notifications_unread;
    this.loading = loading;
  }
}

export function new$(client, route) {
  return new Model(
    new $option.None(),
    new $option.None(),
    route,
    client,
    new $option.None(),
    toList([]),
    toList([]),
    new $option.None(),
    toList([]),
    $dict.new$(),
    $dict.new$(),
    $dict.new$(),
    $copilot_input.new$(),
    toList([]),
    new Connectors(toList([]), $confluence.new$(), false, "", toList([])),
    $new_proposal.init(),
    $content_library.new$(),
    toList([]),
    false,
    toList([]),
    false,
    $loading.new$(),
  );
}

function remove_same_id(val, id) {
  if (val instanceof $option.Some && (isEqual(val[0], id))) {
    let old_id = val[0];
    return new $option.None();
  } else {
    let value = val;
    return value;
  }
}

export function empty_popup(model, id) {
  let is_feed = id === "feed";
  return $bool.guard(
    is_feed,
    model.withFields({ feed_opened: false }),
    () => {
      return $bool.guard(
        id === "all",
        model.withFields({
          feed_opened: false,
          collaborators_proposal_opened: new $option.None(),
          more_proposal_opened: new $option.None(),
          content_library: model.content_library.withFields({
            selected: $dict.new$()
          })
        }),
        () => {
          if (id.startsWith("collaborator-")) {
            let id$1 = id.slice(13);
            let collaborators_proposal_opened = remove_same_id(
              model.collaborators_proposal_opened,
              id$1,
            );
            return model.withFields({
              collaborators_proposal_opened: collaborators_proposal_opened
            });
          } else if (id.startsWith("ai-")) {
            let id$1 = id.slice(3);
            let more_proposal_opened = remove_same_id(
              model.more_proposal_opened,
              id$1,
            );
            return model.withFields({
              more_proposal_opened: more_proposal_opened
            });
          } else {
            return model;
          }
        },
      );
    },
  );
}

export function mark_as_loaded(model, mapper) {
  return model.withFields({ loading: mapper(model.loading) });
}

export function update_access_token(model, access_token) {
  let permissions = (() => {
    let $ = read_permissions(access_token);
    if (!$.isOk()) {
      return toList([]);
    } else {
      let permissions = $[0];
      let _pipe = $dynamic.list($dynamic.string)(permissions);
      return $result.unwrap(_pipe, toList([]));
    }
  })();
  return model.withFields({
    access_token: new $option.Some(access_token),
    permissions: permissions
  });
}

export function update_user(model, user) {
  return model.withFields({ user: new $option.Some(user) });
}

export function is_connected(model) {
  return $option.is_some(model.access_token);
}

export function update_route(model, route) {
  return model.withFields({ route: route });
}

export function update_copilot_input(model, value) {
  let copilot_input = model.copilot_input.withFields({ value: value });
  return model.withFields({ copilot_input: copilot_input });
}

export function add_proposal_questions(model, questions) {
  let $ = $list.first(questions);
  if (!$.isOk()) {
    return model;
  } else {
    let question = $[0];
    let questions$1 = $list.map(questions, (q) => { return [q.id, q]; });
    let _pipe = model.questions;
    let _pipe$1 = $dict.insert(_pipe, question.proposal_id, questions$1);
    return ((questions) => { return model.withFields({ questions: questions }); })(
      _pipe$1,
    );
  }
}

function upsert(qs, question) {
  if (qs.atLeastLength(1)) {
    let q = qs.head;
    let rest = qs.tail;
    let $ = q.id === question.id;
    if ($) {
      return listPrepend(question, rest);
    } else {
      return listPrepend(q, upsert(rest, question));
    }
  } else {
    return toList([question]);
  }
}

export function add_copilot_questions(model, questions) {
  let _pipe = $list.fold(
    questions,
    model.copilot_threads,
    (threads, question) => {
      let q = (() => {
        let _pipe = $list.key_find(threads, question.thread_id);
        return $result.unwrap(_pipe, toList([]));
      })();
      let _pipe = upsert(q, question);
      let _pipe$1 = $list.sort(
        _pipe,
        (a, b) => { return $birl.compare(a.created_at, b.created_at); },
      );
      return ((_capture) => {
        return $list.key_set(threads, question.thread_id, _capture);
      })(_pipe$1);
    },
  );
  return ((copilot_threads) => {
    return model.withFields({ copilot_threads: copilot_threads });
  })(_pipe);
}

export function reset_copilot_input(model) {
  return model.withFields({ copilot_input: $copilot_input.new$() });
}

export function add_data_sources(model, data_sources) {
  let _pipe = model.data_sources;
  let _pipe$1 = $dict.merge(_pipe, data_sources);
  return ((data_sources) => {
    return model.withFields({ data_sources: data_sources });
  })(_pipe$1);
}

export function add_data_points(model, data_points) {
  let _pipe = model.data_points;
  let _pipe$1 = $dict.merge(_pipe, data_points);
  return ((data_points) => {
    return model.withFields({ data_points: data_points });
  })(_pipe$1);
}

export function empty_new_proposal(model) {
  let $ = model.route;
  if ($ instanceof $route.Proposals &&
  $[0] instanceof $route.Add &&
  !$[0].loading) {
    return model.withFields({ new_proposal: $new_proposal.init() });
  } else {
    return model;
  }
}

export function update_new_proposal(model, mapper) {
  return model.withFields({ new_proposal: mapper(model.new_proposal) });
}

export function set_content_library(model, content_library) {
  return model.withFields({ content_library: content_library });
}

export function set_connectors(model, connectors) {
  return model.withFields({ connectors: connectors });
}

export function set_questions(model, proposal_id, questions) {
  let $ = $list.fold(
    questions,
    [0, 0],
    (acc, val) => {
      let $1 = val[1].validated;
      if ($1) {
        return [acc[0] + 1, acc[1] + 1];
      } else {
        return [acc[0], acc[1] + 1];
      }
    },
  );
  let validated = $[0];
  let total = $[1];
  let progress = (() => {
    let _pipe = (divideFloat(
      $int.to_float(validated),
      $int.to_float($int.max(total, 1))
    ));
    let _pipe$1 = $float.multiply(_pipe, 100.0);
    return $float.round(_pipe$1);
  })();
  let questions$1 = (() => {
    let _pipe = model.questions;
    return $dict.insert(_pipe, proposal_id, questions);
  })();
  let proposals = (() => {
    let _pipe = model.proposals;
    let _pipe$1 = $list.key_find(_pipe, proposal_id);
    let _pipe$2 = $result.map(
      _pipe$1,
      (p) => { return p.withFields({ progress: progress }); },
    );
    let _pipe$3 = $result.map(
      _pipe$2,
      (_capture) => {
        return $list.key_set(model.proposals, proposal_id, _capture);
      },
    );
    return $result.unwrap(_pipe$3, model.proposals);
  })();
  return model.withFields({ questions: questions$1, proposals: proposals });
}

export function find_question(model, proposal_id, question_id) {
  let _pipe = model.questions;
  let _pipe$1 = $dict.get(_pipe, proposal_id);
  return $result.then$(
    _pipe$1,
    (_capture) => { return $list.key_find(_capture, question_id); },
  );
}

function upsert_notification(notifications, notification) {
  if (notifications.atLeastLength(1)) {
    let n = notifications.head;
    let rest = notifications.tail;
    let $ = n.id === notification.id;
    if ($) {
      return listPrepend(notification, rest);
    } else {
      return listPrepend(n, upsert_notification(rest, notification));
    }
  } else {
    return toList([notification]);
  }
}

export function set_notifications(model, notifications) {
  let notifications_unread = $list.any(
    notifications,
    (n) => { return !n.read; },
  );
  return model.withFields({
    notifications: notifications,
    notifications_unread: notifications_unread
  });
}

export function set_notification(model, notification) {
  let _pipe = upsert_notification(model.notifications, notification);
  return ((_capture) => { return set_notifications(model, _capture); })(_pipe);
}

export function find_current_user(model) {
  return $list.find(
    model.users,
    (user) => {
      let connected_user = $option.map(model.user, (u) => { return u.sub; });
      return isEqual(new $option.Some(user.id), connected_user);
    },
  );
}

export function find_current_user_id(model) {
  let user = find_current_user(model);
  return $result.map(user, (u) => { return u.id; });
}
