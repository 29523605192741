/// <reference types="./schedule.d.mts" />
import * as $effect from "../../../../lustre/lustre/effect.mjs";
import * as $timer from "../../../grille_pain/internals/scheduler/timer.mjs";

export function schedule(duration, msg) {
  return $effect.from(
    (dispatch) => {
      return $timer.set_timeout(duration, () => { return dispatch(msg); });
    },
  );
}
