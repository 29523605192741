/// <reference types="./book.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import { attribute } from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import * as $svg from "../../../lustre/lustre/element/svg.mjs";
import { toList } from "../../gleam.mjs";

export function book() {
  return $html.svg(
    toList([
      attribute("xmlns", "http://www.w3.org/2000/svg"),
      attribute("fill", "none"),
      attribute("viewBox", "0 0 24 24"),
    ]),
    toList([
      $svg.g(
        toList([attribute("clip-path", "url(#clip0_6782_135)")]),
        toList([
          $svg.path(
            toList([
              attribute("fill", "currentColor"),
              attribute(
                "d",
                "M21 21.8503H13V6.85034C13 6.05469 13.3161 5.29163 13.8787 4.72902C14.4413 4.16641 15.2044 3.85034 16 3.85034H21C21.2652 3.85034 21.5196 3.9557 21.7071 4.14323C21.8946 4.33077 22 4.58513 22 4.85034V20.8503C22 21.1156 21.8946 21.3699 21.7071 21.5574C21.5196 21.745 21.2652 21.8503 21 21.8503ZM11 21.8503H3C2.73478 21.8503 2.48043 21.745 2.29289 21.5574C2.10536 21.3699 2 21.1156 2 20.8503V4.85034C2 4.58513 2.10536 4.33077 2.29289 4.14323C2.48043 3.9557 2.73478 3.85034 3 3.85034H8C8.79565 3.85034 9.55871 4.16641 10.1213 4.72902C10.6839 5.29163 11 6.05469 11 6.85034V21.8503ZM11 21.8503H13V23.8503H11V21.8503Z",
              ),
            ]),
          ),
        ]),
      ),
      $svg.defs(
        toList([]),
        toList([
          $svg.clip_path(
            toList([$attribute.id("clip0_6782_135")]),
            toList([
              $svg.rect(
                toList([
                  attribute("transform", "translate(0 0.850342)"),
                  attribute("fill", "white"),
                  attribute("height", "24"),
                  attribute("width", "24"),
                ]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}
