/// <reference types="./portal.d.mts" />
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $event from "../../../lustre/lustre/event.mjs";
import { Ok, toList } from "../../gleam.mjs";
import { interceptMessage as intercept_message } from "../../lustre-portal.ffi.mjs";

export function portal(id, child) {
  let id$1 = $attribute.id("portal-" + $int.to_string(id));
  let content = $attribute.property("content", child);
  let handler = $event.on(
    "portal-event",
    (msg) => { return new Ok(intercept_message(msg)); },
  );
  return $element.element(
    "lustre-portal",
    toList([id$1, content, handler]),
    toList([]),
  );
}
