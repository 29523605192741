/// <reference types="./map.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import { attribute } from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import * as $svg from "../../../lustre/lustre/element/svg.mjs";
import { toList } from "../../gleam.mjs";

export function map() {
  return $html.svg(
    toList([
      attribute("xmlns", "http://www.w3.org/2000/svg"),
      attribute("fill", "none"),
      attribute("viewBox", "0 0 24 24"),
    ]),
    toList([
      $svg.path(
        toList([
          attribute("fill", "currentColor"),
          attribute(
            "d",
            "M2 5.85034L9 2.85034L15 5.85034L21.303 3.14934C21.3791 3.11673 21.4621 3.10351 21.5445 3.11088C21.627 3.11826 21.7063 3.14599 21.7754 3.19159C21.8445 3.23719 21.9012 3.29923 21.9404 3.37214C21.9796 3.44505 22.0001 3.52656 22 3.60934V19.8503L15 22.8503L9 19.8503L2.697 22.5513C2.62091 22.584 2.53792 22.5972 2.45547 22.5898C2.37301 22.5824 2.29368 22.5547 2.22459 22.5091C2.1555 22.4635 2.09881 22.4015 2.05961 22.3285C2.02041 22.2556 1.99993 22.1741 2 22.0913V5.85034ZM15 20.6143V8.02634L14.935 8.05434L9 5.08634V17.6743L9.065 17.6463L15 20.6143Z",
          ),
        ]),
      ),
    ]),
  );
}
