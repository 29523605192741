/// <reference types="./upload.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function upload() {
  let content = "<g clip-path=\"url(#clip0_7003_13075)\">\n    <path d=\"M5.4 23.4002H24.6V15.0002H27V24.6002C27 24.9185 26.8736 25.2237 26.6485 25.4487C26.4235 25.6738 26.1183 25.8002 25.8 25.8002H4.2C3.88174 25.8002 3.57652 25.6738 3.35147 25.4487C3.12643 25.2237 3 24.9185 3 24.6002V15.0002H5.4V23.4002ZM16.2 11.4002V19.8002H13.8V11.4002H7.8L15 4.2002L22.2 11.4002H16.2Z\" fill=\"currentColor\"/>\n    </g>\n    <defs>\n    <clipPath id=\"clip0_7003_13075\">\n    <rect width=\"28.8\" height=\"28.8\" fill=\"none\" transform=\"translate(0.599609 0.600098)\"/>\n    </clipPath>\n    </defs>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 30 30"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
