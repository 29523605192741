/// <reference types="./menu.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function menu() {
  let content = "<path d=\"M2.5 3.33331H17.5V4.99998H2.5V3.33331ZM2.5 9.16665H12.5V10.8333H2.5V9.16665ZM2.5 15H17.5V16.6666H2.5V15Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 20 20"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
