/// <reference types="./dropdown.d.mts" />
import * as $dynamic_ from "../../../common/gleam/dynamic/extra.mjs";
import * as $sentry from "../../../common/sentry.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $dyn from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $float from "../../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $lustre from "../../../lustre/lustre.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $eff from "../../../lustre/lustre/effect.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $window from "../../../plinth/plinth/browser/window.mjs";
import * as $sketch_lustre from "../../../sketch/sketch/lustre.mjs";
import * as $sketch_options from "../../../sketch/sketch/options.mjs";
import * as $colors from "../../design_system/colors.mjs";
import * as $portal from "../../design_system/components/portal.mjs";
import * as $icons from "../../design_system/icons.mjs";
import * as $s from "../../design_system/internals/styles/dropdown.mjs";
import {
  uniqueId as unique_id,
  getEventBoundingClientRect as get_event_bounding_client_rect,
  coerce,
  subscribeDOMClick as do_subscribe_dom_click,
  toggleDomFreeze as do_toggle_dom_freeze,
} from "../../frontend.ffi.mjs";
import { Ok, toList, CustomType as $CustomType, makeError, divideInt, isEqual } from "../../gleam.mjs";

export class DomRect extends $CustomType {
  constructor(top, bottom, left, right, width, height, x, y) {
    super();
    this.top = top;
    this.bottom = bottom;
    this.left = left;
    this.right = right;
    this.width = width;
    this.height = height;
    this.x = x;
    this.y = y;
  }
}

class Label extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Placeholder extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Disabled extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Choices extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Selected extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Icon extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class OnSelected extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class NoAttribute extends $CustomType {}

class Choice extends $CustomType {
  constructor(title, icon, value) {
    super();
    this.title = title;
    this.icon = icon;
    this.value = value;
  }
}

export class ChoicesChanged extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class LabelChanged extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class PlaceholderChanged extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class DisabledChanged extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class SelectedChanged extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class IconChanged extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class SelectedChoice extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ToggleOpen extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Closing extends $CustomType {}

class Model extends $CustomType {
  constructor(selected, options, label, placeholder, disabled, opened, icon, id, rect) {
    super();
    this.selected = selected;
    this.options = options;
    this.label = label;
    this.placeholder = placeholder;
    this.disabled = disabled;
    this.opened = opened;
    this.icon = icon;
    this.id = id;
    this.rect = rect;
  }
}

export function subscribe_dom_click(msg) {
  return $eff.from(
    (dispatch) => {
      return do_subscribe_dom_click(() => { return dispatch(msg); });
    },
  );
}

function attributes_to_html_attributes(attrs) {
  if (attrs instanceof Label) {
    let content = attrs[0];
    return $a.property("label", content);
  } else if (attrs instanceof Placeholder) {
    let content = attrs[0];
    return $a.property("placeholder", content);
  } else if (attrs instanceof Disabled) {
    let content = attrs[0];
    return $a.property("disabled", content);
  } else if (attrs instanceof Choices) {
    let content = attrs[0];
    return $a.property("choices", content);
  } else if (attrs instanceof Selected) {
    let content = attrs[0];
    return $a.property("selected", content);
  } else if (attrs instanceof Icon) {
    let content = attrs[0];
    return $a.property("icon", content);
  } else if (attrs instanceof NoAttribute) {
    return $a.none();
  } else {
    let map = attrs[0];
    return $e.on(
      "selected",
      (event) => {
        let _pipe = $dyn.field("detail", $dyn.dynamic)(event);
        let _pipe$1 = $result.map(_pipe, coerce);
        return $result.map(_pipe$1, map);
      },
    );
  }
}

export function label(text) {
  return new Label(text);
}

export function placeholder(text) {
  return new Placeholder(text);
}

export function disabled(value) {
  return new Disabled(value);
}

function options_list(value) {
  return new Choices(value);
}

export function selected(value) {
  return new Selected(value);
}

export function icon(value) {
  return new Icon(value);
}

export function none() {
  return new NoAttribute();
}

export function on_selected(msg) {
  return new OnSelected(msg);
}

function succeed(dyn) {
  return new Ok(coerce(dyn));
}

function on_attributes_change() {
  return $dict.from_list(
    toList([
      [
        "choices",
        (() => {
          let _pipe = $dyn.list(succeed);
          return $dynamic_.map(
            _pipe,
            (var0) => { return new ChoicesChanged(var0); },
          );
        })(),
      ],
      [
        "label",
        (() => {
          let _pipe = $dyn.optional($dyn.string);
          return $dynamic_.map(
            _pipe,
            (var0) => { return new LabelChanged(var0); },
          );
        })(),
      ],
      [
        "placeholder",
        (() => {
          let _pipe = $dyn.string;
          return $dynamic_.map(
            _pipe,
            (var0) => { return new PlaceholderChanged(var0); },
          );
        })(),
      ],
      [
        "selected",
        (() => {
          let _pipe = $dyn.optional(succeed);
          return $dynamic_.map(
            _pipe,
            (var0) => { return new SelectedChanged(var0); },
          );
        })(),
      ],
      [
        "icon",
        (() => {
          let _pipe = $dyn.optional(succeed);
          return $dynamic_.map(
            _pipe,
            (var0) => { return new IconChanged(var0); },
          );
        })(),
      ],
      [
        "disabled",
        (() => {
          let _pipe = $dyn.optional($dyn.bool);
          return $dynamic_.map(
            _pipe,
            (var0) => { return new DisabledChanged(var0); },
          );
        })(),
      ],
    ]),
  );
}

export function choice(title, value) {
  return new Choice(title, new None(), value);
}

export function with_icon(choice, icon) {
  return choice.withFields({ icon: new Some(icon) });
}

function init(_) {
  let id = unique_id();
  return [
    new Model(
      new None(),
      toList([]),
      new None(),
      "Default placeholder",
      new None(),
      false,
      new None(),
      id,
      new None(),
    ),
    $eff.none(),
  ];
}

function toggle_dom_freeze() {
  return $eff.from((_) => { return do_toggle_dom_freeze(); });
}

function update(model, msg) {
  if (msg instanceof ChoicesChanged) {
    let content = msg[0];
    return [model.withFields({ options: content }), $eff.none()];
  } else if (msg instanceof LabelChanged) {
    let content = msg[0];
    return [model.withFields({ label: content }), $eff.none()];
  } else if (msg instanceof DisabledChanged) {
    let content = msg[0];
    return [model.withFields({ disabled: content }), $eff.none()];
  } else if (msg instanceof SelectedChanged) {
    let content = msg[0];
    return [model.withFields({ selected: content }), $eff.none()];
  } else if (msg instanceof Closing) {
    return [
      model.withFields({ opened: false }),
      (() => {
        let $ = model.opened;
        if (!$) {
          return $eff.none();
        } else {
          return toggle_dom_freeze();
        }
      })(),
    ];
  } else if (msg instanceof ToggleOpen) {
    let dom_rect = msg[0];
    let $ = model.opened;
    if ($) {
      return [model.withFields({ opened: false }), toggle_dom_freeze()];
    } else {
      return [
        model.withFields({ opened: true, rect: new Some(dom_rect) }),
        $eff.batch(
          toList([toggle_dom_freeze(), subscribe_dom_click(new Closing())]),
        ),
      ];
    }
  } else if (msg instanceof IconChanged) {
    let content = msg[0];
    return [model.withFields({ icon: content }), $eff.none()];
  } else if (msg instanceof SelectedChoice) {
    let value = msg[0];
    let _pipe = model.options;
    let _pipe$1 = $list.find(
      _pipe,
      (option) => { return option.value === value; },
    );
    let _pipe$2 = $result.map(
      _pipe$1,
      (choice) => {
        let _pipe$2 = $eff.batch(
          toList([
            $e.emit("selected", coerce($dyn.from(choice.value))),
            toggle_dom_freeze(),
          ]),
        );
        return ((_capture) => { return $pair.new$(model, _capture); })(_pipe$2);
      },
    );
    let _pipe$3 = $result.unwrap(
      _pipe$2,
      [model.withFields({ selected: new None() }), $eff.none()],
    );
    return $pair.map_first(
      _pipe$3,
      (model) => { return model.withFields({ opened: false }); },
    );
  } else {
    let content = msg[0];
    return [model.withFields({ placeholder: content }), $eff.none()];
  }
}

function on_toggle() {
  return $e.on(
    "click",
    (event) => {
      let _pipe = get_event_bounding_client_rect(event);
      let _pipe$1 = $result.map(
        _pipe,
        (var0) => { return new ToggleOpen(var0); },
      );
      let _pipe$2 = $result.map_error(_pipe$1, $sentry.capture);
      return $result.replace_error(_pipe$2, toList([]));
    },
  );
}

function select_input_class(model) {
  let $ = model.disabled;
  let $1 = model.opened;
  if ($ instanceof Some && $[0]) {
    return $s.disabled;
  } else if ($1) {
    return $s.active;
  } else {
    return $s.standard;
  }
}

function compute_position(rect) {
  let inner_height = $window.inner_height($window.self());
  let $ = $float.round(rect.y) > 3 * (divideInt(inner_height, 4));
  if ($) {
    let bottom = (inner_height - $float.round(rect.y)) + 6;
    let bottom$1 = $int.to_string(bottom) + "px";
    return ["bottom", bottom$1];
  } else {
    let top = ((0 + $float.round(rect.y)) + $float.round(rect.height)) + 6;
    let top$1 = $int.to_string(top) + "px";
    return ["top", top$1];
  }
}

function view_choice(value, option) {
  let style = $a.style(toList([["color", $colors.light.onyx]]));
  return $h.div(
    toList([
      $a.class$("choice-wrapper"),
      $e.on_click(new SelectedChoice(option.value)),
    ]),
    toList([
      $h.div(
        toList([$a.class$("text-icon-wrapper"), style]),
        toList([
          (() => {
            let _pipe = option.icon;
            let _pipe$1 = $option.map(
              _pipe,
              (i) => {
                return $h.div(
                  toList([$a.class$("full-icon-wrapper")]),
                  toList([i]),
                );
              },
            );
            return $option.unwrap(_pipe$1, $el.none());
          })(),
          $el.text(option.title),
        ]),
      ),
      (() => {
        let $ = isEqual(value, new Some(option.value));
        if ($) {
          return $h.div(
            toList([$a.class$("icon-wrapper")]),
            toList([$icons.checkmark()]),
          );
        } else {
          return $el.none();
        }
      })(),
    ]),
  );
}

function view_content(model) {
  let content = (() => {
    let _pipe = model.options;
    let _pipe$1 = $list.find(
      _pipe,
      (option) => { return isEqual(new Some(option.value), model.selected); },
    );
    return $option.from_result(_pipe$1);
  })();
  let input = select_input_class(model);
  let value = $option.map(content, (c) => { return c.value; });
  let disabled$1 = (() => {
    let _pipe = model.disabled;
    let _pipe$1 = $option.map(_pipe, $a.disabled);
    return $option.unwrap(_pipe$1, $a.none());
  })();
  return $s.full_wrapper(
    toList([]),
    toList([
      input(
        toList([disabled$1]),
        toList([
          $s.text_icon_wrapper(
            $option.is_some(model.selected),
            toList([]),
            toList([
              (() => {
                let _pipe = content;
                let _pipe$1 = $option.then$(
                  _pipe,
                  (content) => { return content.icon; },
                );
                let _pipe$2 = $option.or(_pipe$1, model.icon);
                let _pipe$3 = $option.map(
                  _pipe$2,
                  (_capture) => { return $s.full_icon_wrapper(_capture); },
                );
                return $option.unwrap(_pipe$3, $el.none());
              })(),
              (() => {
                let _pipe = content;
                let _pipe$1 = $option.map(
                  _pipe,
                  (choice) => { return choice.title; },
                );
                let _pipe$2 = $option.unwrap(_pipe$1, model.placeholder);
                return $el.text(_pipe$2);
              })(),
            ]),
          ),
          $s.icon_wrapper(toList([]), toList([$icons.arrow_dropdown()])),
        ]),
      ),
      (() => {
        let $ = model.opened;
        let $1 = model.rect;
        if (!$) {
          return $el.none();
        } else if ($ && $1 instanceof None) {
          return $el.none();
        } else {
          let rect = $1[0];
          let position = compute_position(rect);
          let left = ["left", $float.to_string(rect.left) + "px"];
          let fixed = ["position", "fixed"];
          let class$ = $a.class$("children-wrapper");
          return $portal.portal(
            model.id,
            $h.div(
              toList([class$, $a.style(toList([fixed, left, position]))]),
              $list.map(
                model.options,
                (option) => { return view_choice(value, option); },
              ),
            ),
          );
        }
      })(),
    ]),
  );
}

function view(model) {
  let id = $a.id("dropdown-" + $int.to_string(model.id));
  let class$ = $a.class$("dropdown-parent-wrapper");
  return $s.wrapper(
    toList([id, class$, on_toggle()]),
    (() => {
      let $ = model.label;
      if ($ instanceof Some) {
        let label$1 = $[0];
        return toList([$el.text(label$1), view_content(model)]);
      } else {
        return toList([view_content(model)]);
      }
    })(),
  );
}

const tag_name = "steerlab-dropdown";

export function dropdown(attrs, options) {
  let _pipe = options_list(options);
  let _pipe$1 = ((_capture) => { return $list.prepend(attrs, _capture); })(
    _pipe,
  );
  let _pipe$2 = $list.map(_pipe$1, attributes_to_html_attributes);
  return ((_capture) => { return $el.element(tag_name, _capture, toList([])); })(
    _pipe$2,
  );
}

export function setup() {
  let on_attribute_change = on_attributes_change();
  return $lustre.register(
    tag_name,
    on_attribute_change,
    (component) => {
      let $ = $sketch_lustre.setup($sketch_options.shadow());
      if (!$.isOk()) {
        throw makeError(
          "assignment_no_match",
          "design_system/components/dropdown",
          171,
          "",
          "Assignment pattern did not match",
          { value: $ }
        )
      }
      let cache = $[0];
      $sketch_lustre.register(component, cache);
      let _pipe = $sketch_lustre.compose(view, cache);
      return ((_capture) => { return $lustre.component(init, update, _capture); })(
        _pipe,
      );
    },
  );
}
