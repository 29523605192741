/// <reference types="./msg.d.mts" />
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import { CustomType as $CustomType } from "../../../gleam.mjs";
import * as $level from "../../../grille_pain/toast/level.mjs";

export class ShowToast extends $CustomType {
  constructor(id, timeout, sticky) {
    super();
    this.id = id;
    this.timeout = timeout;
    this.sticky = sticky;
  }
}

export class ExternalHideToast extends $CustomType {
  constructor(uuid) {
    super();
    this.uuid = uuid;
  }
}

export class HideToast extends $CustomType {
  constructor(id, iteration) {
    super();
    this.id = id;
    this.iteration = iteration;
  }
}

export class RemoveToast extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class StopToast extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class ResumeToast extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class NewToast extends $CustomType {
  constructor(uuid, message, level, timeout, sticky) {
    super();
    this.uuid = uuid;
    this.message = message;
    this.level = level;
    this.timeout = timeout;
    this.sticky = sticky;
  }
}
