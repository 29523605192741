/// <reference types="./show.d.mts" />
import * as $data_point from "../../../common/data/data_point.mjs";
import * as $data_source from "../../../common/data/data_source.mjs";
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $question from "../../../common/data/question.mjs";
import * as $user from "../../../common/data/user.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $float from "../../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $mime_types from "../../../mime_types/mime_types.mjs";
import * as $model from "../../data/model.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $button from "../../design_system/components/button.mjs";
import * as $input from "../../design_system/components/input.mjs";
import * as $label from "../../design_system/components/label.mjs";
import * as $layout from "../../design_system/components/layout.mjs";
import * as $pp from "../../design_system/components/profile_picture.mjs";
import * as $tab_bar from "../../design_system/components/tab_bar.mjs";
import * as $icons from "../../design_system/icons.mjs";
import * as $outline from "../../design_system/outline.mjs";
import * as $tooltip from "../../elements/tooltip.mjs";
import {
  blurTargetWithEscape as blur_target,
  extractInnerText as inner_text_decoder,
} from "../../frontend.ffi.mjs";
import { Error, toList, prepend as listPrepend, isEqual } from "../../gleam.mjs";
import * as $common from "../../view/common.mjs";
import * as $s from "../../view/styles/proposals.mjs";

function get_data_point_by_id(id, data_points) {
  return $list.find(data_points, (dp) => { return dp.id === id; });
}

function ai_rewording_popup(opened, proposal_id, question_id) {
  return $bool.guard(
    !opened,
    $el.none(),
    () => {
      let on_click = (asked) => {
        return $e.on_click(
          new $msg.AskAiInsights(proposal_id, question_id, asked),
        );
      };
      return $s.ai_rewording_popup(
        toList([]),
        toList([
          $s.ai_rewording_title(toList([]), toList([$h.text("Actions")])),
          $s.ai_rewording_option(
            toList([on_click("add-question-bank")]),
            toList([
              $s.details_collaborators_icon($icons.add()),
              $h.text("Add to question bank"),
            ]),
          ),
          $s.ai_rewording_title(toList([]), toList([$h.text("AI Edits")])),
          $s.ai_rewording_option(
            toList([on_click("shorter")]),
            toList([
              $s.details_collaborators_icon($icons.shorter()),
              $h.text("Make shorter"),
            ]),
          ),
          $s.ai_rewording_option(
            toList([on_click("longer")]),
            toList([
              $s.details_collaborators_icon($icons.longer()),
              $h.text("Make longer"),
            ]),
          ),
          $s.ai_rewording_option(
            toList([on_click("proofread")]),
            toList([
              $s.details_collaborators_icon($icons.file_edit()),
              $h.text("Proofread"),
            ]),
          ),
        ]),
      );
    },
  );
}

function user_picture(owner) {
  let picture = owner.picture;
  let initials = owner.initials;
  return $el.fragment(
    toList([
      $pp.from_user(picture, initials, new $pp.Medium()),
      $h.text(owner.name),
    ]),
  );
}

function collaborators_list(model, proposal, question) {
  let _pipe = model.users;
  let _pipe$1 = $list.filter(
    _pipe,
    (u) => {
      return $list.any(
        u.roles,
        (r) => {
          return (r.name === "Contributor") || (r.name === "Proposal Manager");
        },
      ) && ($list.contains(proposal.collaborators, u.id) || (proposal.owner === u.id));
    },
  );
  return $list.map(
    _pipe$1,
    (user) => {
      let id = user.id;
      let name = user.name;
      let picture = user.picture;
      let initials = user.initials;
      let msg = new $msg.AddQuestionOwner(question.id, question.proposal_id, id);
      let on_click = $e.on_click(msg);
      return $s.add_collaborator_button(
        toList([on_click]),
        toList([
          $layout.row(
            toList([$layout.gap(8), $layout.align("center")]),
            toList([]),
            toList([
              $pp.from_user(picture, initials, new $pp.Medium()),
              $el.text(name),
            ]),
          ),
        ]),
      );
    },
  );
}

function collaborator_button(model, proposal, question) {
  let owner = (() => {
    let _pipe = question.owner;
    let _pipe$1 = $option.to_result(_pipe, undefined);
    return $result.then$(
      _pipe$1,
      (o) => { return $list.find(model.users, (u) => { return u.id === o; }); },
    );
  })();
  let on_click = $e.on_click(
    new $msg.ToggleProposalCollaboratorsPopup(question.id),
  );
  let disabled = $a.disabled(
    !$list.contains(model.permissions, "rfp:users:add") || question.assign_processing,
  );
  let $ = question.assign_processing;
  if ($) {
    return $s.details_collaborators_loading_button(
      toList([disabled]),
      toList([$s.typing_indicator(6)]),
    );
  } else {
    return $s.details_collaborators_button(
      toList([on_click, disabled]),
      toList([
        (() => {
          let $1 = question.assign_processing;
          if (owner.isOk()) {
            let owner$1 = owner[0];
            return user_picture(owner$1);
          } else {
            return $el.fragment(
              toList([
                $s.details_collaborators_icon($icons.add_user()),
                $h.text("Add collaborator"),
              ]),
            );
          }
        })(),
        (() => {
          let $1 = isEqual(
            model.collaborators_proposal_opened,
            new $option.Some(question.id)
          );
          if (!$1) {
            return $el.none();
          } else {
            return $s.add_collaborators_popup_right(
              toList([]),
              (() => {
                let _pipe = collaborators_list(model, proposal, question);
                return $list.prepend(
                  _pipe,
                  $s.add_collaborators_popup_title(
                    toList([]),
                    toList([$el.text("Select collaborators")]),
                  ),
                );
              })(),
            );
          }
        })(),
      ]),
    );
  }
}

function view_question_status(question) {
  let status = (() => {
    let $ = question.validated;
    if ($) {
      return new $label.Success();
    } else {
      return new $label.Current();
    }
  })();
  let content = (() => {
    let $ = question.validated;
    if ($) {
      return "Validated";
    } else {
      return "In review";
    }
  })();
  return $s.question_status(toList([]), toList([$label.label(status, content)]));
}

function view_question_answer(model, question, answer) {
  let user_id = $model.find_current_user_id(model);
  let is_hint = (($option.is_none(answer.custom) && $option.is_none(
    answer.short,
  )) && $option.is_none(answer.long)) && $option.is_none(answer.yes_no);
  let is_owner = isEqual($option.to_result(question.owner, undefined), user_id);
  let editable = $a.attribute(
    "contenteditable",
    (() => {
      let $ = is_hint || !is_owner;
      if ($) {
        return "false";
      } else {
        return "true";
      }
    })(),
  );
  let on_blur = $e.on(
    "blur",
    (event) => {
      return $result.map(
        inner_text_decoder(event),
        (content) => {
          return new $msg.UpdateProposalQuestion(question, content);
        },
      );
    },
  );
  let on_escape = $e.on(
    "keyup",
    (event) => {
      blur_target(event);
      return new Error(toList([]));
    },
  );
  return $s.question_response(
    is_hint,
    toList([editable, on_blur, on_escape]),
    toList([
      $h.text(
        (() => {
          if (answer instanceof $question.Answer &&
          answer.custom instanceof $option.Some) {
            let q = answer.custom[0];
            return q;
          } else if (answer instanceof $question.Answer &&
          answer.short instanceof $option.Some) {
            let q = answer.short[0];
            return q;
          } else if (answer instanceof $question.Answer &&
          answer.short instanceof $option.None &&
          answer.long instanceof $option.Some) {
            let q = answer.long[0];
            return q;
          } else if (answer instanceof $question.Answer &&
          answer.short instanceof $option.None &&
          answer.long instanceof $option.None &&
          answer.yes_no instanceof $option.Some) {
            let q = answer.yes_no[0];
            return q;
          } else {
            return "";
          }
        })(),
      ),
      (() => {
        let $ = question.ai_processing;
        if (!$) {
          return $el.none();
        } else {
          return $s.loading_answer_indicator(toList([$s.typing_indicator(12)]));
        }
      })(),
    ]),
  );
}

function view_question_card(
  model,
  current_user,
  proposal,
  question,
  index,
  total,
  data_sources,
  data_points
) {
  let style = $a.style(toList([["z-index", $int.to_string(9000 - index)]]));
  let index$1 = $int.to_string(index + 1);
  let total$1 = $int.to_string(total);
  let points = data_points;
  let actions_disabled = !isEqual(
    current_user,
    $option.to_result(question.owner, undefined)
  );
  let more_proposal_opened = isEqual(
    model.more_proposal_opened,
    new $option.Some(question.id)
  );
  return $s.question_card(
    toList([$a.id("question-" + question.id), style]),
    toList([
      $s.question_card_title(
        toList([]),
        toList([
          $s.question_number(
            toList([]),
            toList([$h.text((("Question " + index$1) + "/") + total$1)]),
          ),
          view_question_status(question),
          $s.question_question(toList([]), toList([$h.text(question.content)])),
          $s.question_owner(
            toList([]),
            toList([collaborator_button(model, proposal, question)]),
          ),
        ]),
      ),
      view_question_answer(model, question, question.answer),
      $s.question_actions(
        toList([]),
        toList([
          (() => {
            let handler = $e.on_click(new $msg.ValidateQuestion(question));
            let disabled = $a.disabled(question.validated || actions_disabled);
            return $s.button_icon(
              false,
              toList([handler, disabled]),
              toList([$s.large_icon($icons.checkmark())]),
            );
          })(),
          $s.button_icon(
            more_proposal_opened,
            toList([
              $e.on_click(new $msg.ToggleMoreProposalMenu(question.id)),
              $a.disabled(question.validated || actions_disabled),
            ]),
            toList([$s.large_icon($icons.more())]),
          ),
          ai_rewording_popup(more_proposal_opened, proposal.id, question.id),
        ]),
      ),
      $s.question_sources(
        toList([]),
        toList([
          $layout.row(
            toList([$layout.align("center"), $layout.gap(8)]),
            toList([]),
            toList([
              $s.question_sources_title(
                toList([]),
                toList([$h.text("Sources")]),
              ),
              (() => {
                let $ = $list.is_empty(points);
                if ($) {
                  return $h.text("∅");
                } else {
                  return $el.fragment(
                    $list.index_map(
                      points,
                      (point, index) => {
                        let _pipe = $list.find(
                          data_sources,
                          (s) => { return s.id === point.source_id; },
                        );
                        let _pipe$1 = $result.try_recover(
                          _pipe,
                          (_) => { return new Error($el.none()); },
                        );
                        let _pipe$2 = $result.map(
                          _pipe$1,
                          (source) => {
                            let index$1 = $h.text(
                              ("[" + $int.to_string(index)) + "]",
                            );
                            return $tooltip.tooltip(
                              toList([]),
                              $s.question_source_index(
                                toList([]),
                                toList([index$1]),
                              ),
                              (() => {
                                let icon = $common.select_icon(
                                  source.display.mime_type,
                                );
                                return $s.question_source_tooltip(
                                  toList([]),
                                  toList([
                                    $layout.row(
                                      toList([
                                        $layout.gap(12),
                                        $layout.align("center"),
                                      ]),
                                      toList([]),
                                      toList([
                                        $common.small_icon(icon),
                                        $s.question_source_tooltip_title(
                                          toList([]),
                                          toList([
                                            (() => {
                                              let $1 = source.display.mime_type;
                                              if ($1 instanceof $mime_types.Qna) {
                                                return $h.text("Question bank");
                                              } else {
                                                return $h.text(
                                                  source.display.name,
                                                );
                                              }
                                            })(),
                                          ]),
                                        ),
                                      ]),
                                    ),
                                    $h.text(point.text),
                                  ]),
                                );
                              })(),
                            );
                          },
                        );
                        return $result.unwrap_both(_pipe$2);
                      },
                    ),
                  );
                }
              })(),
            ]),
          ),
          (() => {
            let $ = question.display.score;
            if ($ instanceof $option.None) {
              return $el.none();
            } else {
              let score = $[0];
              return $s.confidence_score(
                toList([]),
                toList([
                  $h.text("Confidence Score"),
                  $s.confidence_score_highlight(
                    score,
                    toList([]),
                    (() => {
                      let value = $int.to_string($float.round(score * 100.0));
                      let value$1 = $string.slice(value, 0, 5);
                      return toList([$h.text(value$1 + " %")]);
                    })(),
                  ),
                ]),
              );
            }
          })(),
        ]),
      ),
    ]),
  );
}

function view_questions(model, proposal, sheet) {
  let current_user = (() => {
    let _pipe = $model.find_current_user(model);
    return $result.map(_pipe, (u) => { return u.id; });
  })();
  let questions = (() => {
    let _pipe = model.questions;
    let _pipe$1 = $dict.get(_pipe, proposal.id);
    return $result.unwrap(_pipe$1, toList([]));
  })();
  let by_sheet = (() => {
    let _pipe = $list.group(questions, (q) => { return q[1].sheet; });
    return $dict.map_values(
      _pipe,
      (_, value) => { return $list.reverse(value); },
    );
  })();
  let active_sheet = (() => {
    let _pipe = $list.find(
      proposal.metadata.sheets,
      (s) => { return isEqual(new $option.Some(s), sheet); },
    );
    let _pipe$1 = $result.try_recover(
      _pipe,
      (_) => { return $list.first(proposal.metadata.sheets); },
    );
    return $option.from_result(_pipe$1);
  })();
  return $s.questions_wrapper(
    toList([]),
    toList([
      $s.question_questions(
        toList([]),
        toList([
          (() => {
            let $ = $dict.get(by_sheet, active_sheet);
            if (!$.isOk()) {
              return $el.none();
            } else {
              let questions$1 = $[0];
              let total = $list.length(questions$1);
              return $el.fragment(
                $list.index_map(
                  questions$1,
                  (question, index) => {
                    let data_sources = (() => {
                      let _pipe = model.data_sources;
                      let _pipe$1 = $dict.get(_pipe, question[1].id);
                      return $result.unwrap(_pipe$1, toList([]));
                    })();
                    let tmp_data_points = (() => {
                      let _pipe = model.data_points;
                      let _pipe$1 = $dict.get(_pipe, question[1].id);
                      return $result.unwrap(_pipe$1, toList([]));
                    })();
                    let list_of_elements = question[1].data_points_id;
                    let data_points = $list.filter_map(
                      list_of_elements,
                      (element) => {
                        return get_data_point_by_id(element[0], tmp_data_points);
                      },
                    );
                    return view_question_card(
                      model,
                      current_user,
                      proposal,
                      question[1],
                      index,
                      total,
                      data_sources,
                      data_points,
                    );
                  },
                ),
              );
            }
          })(),
        ]),
      ),
      $s.question_spreadsheet_sheets(
        toList([]),
        $list.map(
          proposal.metadata.sheets,
          (sheet) => {
            let s = new $option.Some(sheet);
            let on_click = $e.on_click(new $msg.ProposalShow(proposal.id, s));
            let $ = isEqual(s, active_sheet);
            if ($) {
              return $s.active_spreadsheet_sheet(
                toList([on_click]),
                toList([$el.text(sheet)]),
              );
            } else {
              return $s.spreadsheet_sheet(
                toList([on_click]),
                toList([$el.text(sheet)]),
              );
            }
          },
        ),
      ),
    ]),
  );
}

export function view(model, id, sheet) {
  let proposal = $list.key_find(model.proposals, id);
  if (!proposal.isOk()) {
    return $el.none();
  } else {
    let proposal$1 = proposal[0];
    return $el.fragment(
      toList([
        $layout.column(
          toList([$layout.gap(8)]),
          toList([]),
          toList([
            $button.link(
              toList([$e.on_click(new $msg.Back())]),
              toList([
                $button.icon($icons.left_arrow()),
                $h.text("See all proposals"),
              ]),
            ),
            $layout.row(
              toList([$layout.gap(8), $layout.justify("space-between")]),
              toList([]),
              toList([
                $s.show_title_form(
                  toList([$e.on_submit(new $msg.SaveProposalTitle(id))]),
                  toList([
                    $input.input(
                      toList([
                        $input.value(proposal$1.name),
                        $input.invisible(),
                        $input.on_input(
                          (_capture) => {
                            return new $msg.UpdateProposalTitle(id, _capture);
                          },
                        ),
                      ]),
                    ),
                  ]),
                ),
                $button.primary(
                  toList([$e.on_click(new $msg.Xlsx(new $msg.GenerateXlsx(id)))]),
                  toList([
                    $button.icon($icons.export$()),
                    $h.text("Export document"),
                  ]),
                ),
              ]),
            ),
          ]),
        ),
        $layout.row(
          toList([$layout.justify("space-between")]),
          toList([]),
          toList([
            $layout.row(
              toList([]),
              toList([]),
              toList([
                $tab_bar.tab_bar(
                  toList([]),
                  toList([
                    $tab_bar.tab(
                      true,
                      toList([]),
                      toList([
                        $layout.row(
                          toList([$layout.align("center"), $layout.gap(8)]),
                          toList([]),
                          toList([
                            $s.details_collaborators_icon($icons.menu()),
                            $h.text("Blocks"),
                          ]),
                        ),
                      ]),
                    ),
                    $tab_bar.tab(
                      false,
                      toList([]),
                      toList([
                        $layout.row(
                          toList([$layout.align("center"), $layout.gap(8)]),
                          toList([]),
                          toList([
                            $s.details_collaborators_icon($icons.table_line()),
                            $h.text("Spreadsheet (coming soon)"),
                          ]),
                        ),
                      ]),
                    ),
                  ]),
                ),
              ]),
            ),
            $common.stacked_avatars(
              listPrepend(proposal$1.owner, proposal$1.collaborators),
              model.users,
            ),
          ]),
        ),
        view_questions(model, proposal$1, sheet),
      ]),
    );
  }
}
