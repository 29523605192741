/// <reference types="./double_fill.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function double_fill() {
  let content = "<path d=\"M9.66837 11.4666L10.845 12.6433L17.9 5.58825L19.0784 6.76659L10.845 14.9999L5.54171 9.69659L6.72004 8.51826L8.49087 10.2891L9.66837 11.4658V11.4666ZM9.67004 9.10992L13.7967 4.98242L14.9717 6.15742L10.845 10.2849L9.67004 9.10992ZM7.31421 13.8224L6.13671 14.9999L0.833374 9.69659L2.01171 8.51826L3.18921 9.69576L3.18837 9.69659L7.31421 13.8224Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 20 20"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
