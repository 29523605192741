/// <reference types="./content_library.d.mts" />
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $el from "../../../sketch/sketch/lustre/element.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { percent, px, vw } from "../../../sketch/sketch/size.mjs";
import * as $colors from "../../design_system/colors.mjs";
import * as $shadows from "../../design_system/shadows.mjs";
import * as $display from "../../design_system/typography/display.mjs";
import * as $text from "../../design_system/typography/text.mjs";
import { toList } from "../../gleam.mjs";

export function filter_card(settings, attrs, children) {
  let color = settings[0];
  let background = settings[1];
  let border = settings[2];
  let active = settings[3];
  let id = "filter-card-" + $string.inspect(settings);
  let border_color = (() => {
    if (!active) {
      return "transparent";
    } else {
      return border;
    }
  })();
  return $el.dynamic(
    "div",
    attrs,
    children,
    id,
    toList([
      $s.color(color),
      $s.background(background),
      $s.border("1px solid " + border_color),
      $s.padding(px(34)),
      $s.flex("1"),
      $s.text_align("center"),
      $s.border_radius(px(8)),
      $s.cursor("pointer"),
      $s.hover(toList([$s.border("1px solid " + border)])),
    ]),
  );
}

export function filter_card_title(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([$s.compose($display.md())]),
  );
}

export function filter_card_subtitle(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([$s.compose($text.md($text.medium))]),
  );
}

export function empty_cell(child) {
  return $el.element(
    "div",
    toList([]),
    toList([child]),
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("center"),
      $s.padding_("30px 0"),
    ]),
  );
}

export function empty_text_content(child) {
  return $el.element(
    "div",
    toList([]),
    toList([child]),
    toList([
      $s.max_width(px(230)),
      $s.white_space("wrap"),
      $s.text_align("center"),
      $s.color($colors.light.grey_800),
      $s.compose($text.xs($text.medium)),
    ]),
  );
}

export function menu_wrapper(hidden, attrs, children) {
  let id = "menu-hidden-" + $bool.to_string(hidden);
  return $el.dynamic(
    "div",
    attrs,
    children,
    id,
    toList([
      $s.position("fixed"),
      $s.display("flex"),
      $s.justify_content("center"),
      $s.width(vw(100)),
      $s.transition("bottom .3s"),
      $s.left(px(0)),
      $s.z_index(90_020),
      $s.bottom(
        (() => {
          if (hidden) {
            return px(-200);
          } else {
            return px(40);
          }
        })(),
      ),
    ]),
  );
}

export function menu(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.compose($text.sm($text.medium)),
      $s.color($colors.light.onyx),
      $s.display("flex"),
      $s.gap(px(32)),
      $s.align_items("center"),
      $s.justify_content("center"),
      $s.border("1px solid " + $colors.light.grey_400),
      $s.border_radius(px(8)),
      $s.background($colors.light.white),
      $s.padding_("14px 16px"),
      $s.box_shadow($shadows.m()),
    ]),
  );
}

export function number_selected(attrs, children) {
  return $el.element("div", attrs, children, toList([]));
}

export function change_button(attrs, children) {
  return $el.element(
    "button",
    attrs,
    children,
    toList([
      $s.display("flex"),
      $s.gap(px(8)),
      $s.align_items("center"),
      $s.cursor("pointer"),
      $s.hover(toList([$s.background($colors.light.grey_50)])),
      $s.padding(px(6)),
      $s.border_radius(px(4)),
      $s.position("relative"),
    ]),
  );
}

export function change_status(attrs, children) {
  return $el.element("div", attrs, children, toList([]));
}

export function change_icon(reversed, icon) {
  let id = "change-icon-" + $bool.to_string(reversed);
  return $el.dynamic(
    "div",
    toList([]),
    toList([icon]),
    id,
    toList([
      $s.width(px(20)),
      $s.height(px(20)),
      $s.transition("transform .3s"),
      $s.transform(
        (() => {
          if (reversed) {
            return "rotate(180deg)";
          } else {
            return "rotate(0deg)";
          }
        })(),
      ),
    ]),
  );
}

export function change_popup() {
  return $s.class$(
    toList([
      $s.position("absolute"),
      $s.bottom(px(55)),
      $s.border("1px solid " + $colors.light.grey_400),
      $s.border_radius(px(8)),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.background($colors.light.white),
      $s.cursor("default"),
      $s.overflow("auto"),
      $s.max_height(px(500)),
      $s.box_shadow($shadows.m()),
    ]),
  );
}

export function change_owner_popup(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.compose(change_popup()),
      $s.width(px(250)),
      $s.right($size.percent(-50)),
    ]),
  );
}

export function change_status_popup(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.compose(change_popup()),
      $s.right(px(0)),
      $s.gap(px(10)),
      $s.padding(px(10)),
      $s.width(percent(100)),
    ]),
  );
}

export function change_tags_popup(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.compose(change_popup()),
      $s.right(px(0)),
      $s.gap(px(10)),
      $s.padding(px(10)),
      $s.width(px(250)),
      $s.right($size.percent(0)),
    ]),
  );
}

export function change_expiration_date_popup(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.compose(change_popup()),
      $s.right(px(0)),
      $s.gap(px(10)),
      $s.padding(px(10)),
      $s.width(px(300)),
      $s.right($size.percent(0)),
    ]),
  );
}

export function change_popup_title(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.compose($text.xs($text.medium)),
      $s.color($colors.light.grey_800),
      $s.padding(px(10)),
      $s.position("sticky"),
      $s.top(px(0)),
      $s.background($colors.light.white),
      $s.border_bottom("1px solid " + $colors.light.grey_400),
    ]),
  );
}

export function change_popup_owner_select(attrs, children) {
  return $el.element(
    "button",
    attrs,
    children,
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("space-between"),
      $s.gap(px(8)),
      $s.padding(px(10)),
      $s.cursor("pointer"),
      $s.hover(toList([$s.background($colors.light.grey_100)])),
    ]),
  );
}

export function new_question_wrapper(attrs, children) {
  return $el.element(
    "form",
    attrs,
    children,
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(24)),
      $s.max_width(px(500)),
      $s.width(percent(100)),
      $s.margin_("0 auto"),
      $s.padding_top(px(48)),
    ]),
  );
}

export function question_title(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([$s.compose($display.xs())]),
  );
}
