/// <reference types="./shadows.d.mts" />
export function xs() {
  return "0px 1px 2px 0px rgba(16, 24, 40, 0.05)";
}

export function s() {
  return "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)";
}

export function m() {
  return "0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)";
}

export function l() {
  return "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)";
}

export function xl() {
  return "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)";
}

export function xxl() {
  return "0px 24px 48px -12px rgba(16, 24, 40, 0.18)";
}

export function xxxl() {
  return "0px 32px 64px -12px rgba(16, 24, 40, 0.14)";
}
