/// <reference types="./form.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import { attribute } from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import * as $svg from "../../../lustre/lustre/element/svg.mjs";
import { toList } from "../../gleam.mjs";

export function form() {
  return $html.svg(
    toList([
      attribute("xmlns", "http://www.w3.org/2000/svg"),
      attribute("fill", "none"),
      attribute("viewBox", "0 0 24 24"),
    ]),
    toList([
      $svg.path(
        toList([
          attribute("fill", "currentColor"),
          attribute(
            "d",
            "M20 22.8503H4C3.73478 22.8503 3.48043 22.745 3.29289 22.5574C3.10536 22.3699 3 22.1156 3 21.8503V3.85034C3 3.58513 3.10536 3.33077 3.29289 3.14323C3.48043 2.9557 3.73478 2.85034 4 2.85034H20C20.2652 2.85034 20.5196 2.9557 20.7071 3.14323C20.8946 3.33077 21 3.58513 21 3.85034V21.8503C21 22.1156 20.8946 22.3699 20.7071 22.5574C20.5196 22.745 20.2652 22.8503 20 22.8503ZM8 7.85034V9.85034H16V7.85034H8ZM8 11.8503V13.8503H16V11.8503H8ZM8 15.8503V17.8503H13V15.8503H8Z",
          ),
        ]),
      ),
    ]),
  );
}
