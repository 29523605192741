import * as analytics from '@vercel/analytics'
import * as speed from '@vercel/speed-insights'
import { PieChart } from './elements/pie-chart'
import { Tooltip } from './elements/tooltip'
import { main } from './frontend.gleam'

// Setup Vercel toolings.
if (import.meta.env.PROD) {
  analytics.inject({ mode: 'production' })
  speed.injectSpeedInsights()
}

// @ts-ignore
Element.prototype._attachShadow = Element.prototype.attachShadow
Element.prototype.attachShadow = function () {
  // @ts-ignore
  return this._attachShadow({ mode: 'open' })
}

// Register the custom elements upfront.
PieChart.register()
Tooltip.register()

// Start the Lustre app.
document.addEventListener('DOMContentLoaded', main)
