/// <reference types="./new_proposal.d.mts" />
import * as $birl from "../../../birl/birl.mjs";
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $spreadsheet from "../../../common/data/spreadsheet.mjs";
import * as $status from "../../../common/data/status.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import { proposalToFormData as to_form_data } from "../../frontend.ffi.mjs";
import { Ok, Error, toList, CustomType as $CustomType, isEqual } from "../../gleam.mjs";

export class Question extends $CustomType {}

export class Answer extends $CustomType {}

export class Miscellaneous extends $CustomType {}

export class NewProposal extends $CustomType {
  constructor(step, name, due_date, client, collaborators, qualification_matrix, selected_sheet, columns_type, invalid_lines, collaborators_popup_opened, xlsx) {
    super();
    this.step = step;
    this.name = name;
    this.due_date = due_date;
    this.client = client;
    this.collaborators = collaborators;
    this.qualification_matrix = qualification_matrix;
    this.selected_sheet = selected_sheet;
    this.columns_type = columns_type;
    this.invalid_lines = invalid_lines;
    this.collaborators_popup_opened = collaborators_popup_opened;
    this.xlsx = xlsx;
  }
}

export function init() {
  return new NewProposal(
    1,
    "",
    $birl.utc_now(),
    "",
    toList([]),
    new $option.None(),
    new $option.None(),
    toList([]),
    toList([]),
    false,
    new $option.None(),
  );
}

export function update_xlsx(new_proposal, xlsx) {
  return new_proposal.withFields({ xlsx: new $option.Some(xlsx) });
}

export function update_client(new_proposal, client) {
  return new_proposal.withFields({ client: client });
}

export function update_name(new_proposal, name) {
  return new_proposal.withFields({ name: name });
}

export function update_due_date(new_proposal, date) {
  return new_proposal.withFields({ due_date: date });
}

export function next_step(new_proposal) {
  return new_proposal.withFields({ step: new_proposal.step + 1 });
}

export function use_qualification_matrix(new_proposal) {
  return new_proposal.withFields({
    qualification_matrix: new $option.Some(toList([]))
  });
}

export function update_selected_sheet(new_proposal, name) {
  return new_proposal.withFields({ selected_sheet: new $option.Some(name) });
}

export function add_collaborator(new_proposal, user_id) {
  let collaborators = (() => {
    let _pipe = new_proposal.collaborators;
    let _pipe$1 = $list.reverse(_pipe);
    let _pipe$2 = $list.prepend(_pipe$1, user_id);
    let _pipe$3 = $list.reverse(_pipe$2);
    return $list.unique(_pipe$3);
  })();
  return new_proposal.withFields({ collaborators: collaborators });
}

export function remove_collaborator(new_proposal, user_id) {
  let collaborators = (() => {
    let _pipe = new_proposal.collaborators;
    return $list.filter(_pipe, (u) => { return u !== user_id; });
  })();
  return new_proposal.withFields({ collaborators: collaborators });
}

export function toggle_collaborators_popup(new_proposal) {
  let collaborators_popup_opened = !new_proposal.collaborators_popup_opened;
  return new_proposal.withFields({
    collaborators_popup_opened: collaborators_popup_opened
  });
}

export function update_column_type(new_proposal, sheet, index, value) {
  let columns_type = (() => {
    let _pipe = new_proposal.columns_type;
    let _pipe$1 = $list.key_find(_pipe, sheet);
    let _pipe$2 = $result.unwrap(_pipe$1, $dict.new$());
    let _pipe$3 = $dict.fold(
      _pipe$2,
      $dict.new$(),
      (d, k, v) => {
        return $bool.guard(
          isEqual(v, value),
          d,
          () => { return $dict.insert(d, k, v); },
        );
      },
    );
    let _pipe$4 = $dict.insert(_pipe$3, index, value);
    return ((_capture) => {
      return $list.key_set(new_proposal.columns_type, sheet, _capture);
    })(_pipe$4);
  })();
  return new_proposal.withFields({ columns_type: columns_type });
}

export function back_to_upload(new_proposal) {
  return new_proposal.withFields({
    step: new_proposal.step - 1,
    selected_sheet: new $option.None(),
    columns_type: toList([]),
    invalid_lines: toList([]),
    xlsx: new $option.None()
  });
}

export function update_invalid_line(new_proposal, sheet, index, value) {
  let invalid_lines = (() => {
    let _pipe = new_proposal.invalid_lines;
    let _pipe$1 = $list.key_find(_pipe, sheet);
    let _pipe$2 = $result.unwrap(_pipe$1, $dict.new$());
    let _pipe$3 = $dict.insert(_pipe$2, index, value);
    return ((_capture) => {
      return $list.key_set(new_proposal.invalid_lines, sheet, _capture);
    })(_pipe$3);
  })();
  return new_proposal.withFields({ invalid_lines: invalid_lines });
}

function encode_proposal(new_proposal, org_id, user_id, xlsx) {
  let metadata = new $proposal.Questionnaire(
    (() => {
      let _pipe = new_proposal.xlsx;
      let _pipe$1 = $option.map(_pipe, (a) => { return a.data; });
      let _pipe$2 = $option.map(
        _pipe$1,
        (_capture) => { return $list.map(_capture, (a) => { return a[0]; }); },
      );
      return $option.unwrap(_pipe$2, toList([]));
    })(),
    $list.map(
      new_proposal.columns_type,
      (column) => {
        let name = column[0];
        let content = column[1];
        return new $proposal.QuestionnaireResponse(
          name,
          (() => {
            let _pipe = content;
            let _pipe$1 = $dict.to_list(_pipe);
            let _pipe$2 = $list.find(
              _pipe$1,
              (a) => { return isEqual(a[1], new Answer()); },
            );
            let _pipe$3 = $result.map(_pipe$2, $pair.first);
            return $result.unwrap(_pipe$3, -1);
          })(),
        );
      },
    ),
    new_proposal.qualification_matrix,
    xlsx.data,
  );
  return $proposal.encode(
    new $proposal.Proposal(
      "",
      new $proposal.Display("questionnaire"),
      org_id,
      new_proposal.name,
      new_proposal.due_date,
      user_id,
      new_proposal.client,
      new_proposal.collaborators,
      0,
      new $status.InProgress(),
      metadata,
    ),
  );
}

function find_default_dict(dicts, name) {
  let _pipe = $list.key_find(dicts, name);
  return $result.unwrap(_pipe, $dict.new$());
}

function encode_question_metadata(col, x, y) {
  return $json.object(
    toList([
      ["x", $json.int(x)],
      ["y", $json.int(y)],
      ["question", $json.nullable(col, $json.string)],
    ]),
  );
}

function encode_sheet_questions(content, invalid, types) {
  return $json.preprocessed_array(
    $list.flatten(
      $list.index_map(
        content,
        (row, y) => {
          return $list.flatten(
            $list.index_map(
              row,
              (col, x) => {
                let is_invalid = (() => {
                  let _pipe = $dict.get(invalid, y);
                  return $result.unwrap(_pipe, true);
                })() === false;
                let is_question = isEqual(
                  (() => {
                    let _pipe = $dict.get(types, x);
                    return $result.unwrap(_pipe, new Miscellaneous());
                  })(),
                  new Question()
                );
                return $bool.guard(
                  is_invalid,
                  toList([]),
                  () => {
                    return $bool.guard(
                      !is_question,
                      toList([]),
                      () => {
                        return toList([encode_question_metadata(col, x, y)]);
                      },
                    );
                  },
                );
              },
            ),
          );
        },
      ),
    ),
  );
}

function encode_questions(data, new_proposal) {
  return $json.preprocessed_array(
    $list.map(
      data,
      (_use0) => {
        let name = _use0[0];
        let start_index = _use0[1];
        let content = _use0[2];
        let types = find_default_dict(new_proposal.columns_type, name);
        let invalid = find_default_dict(new_proposal.invalid_lines, name);
        return $json.object(
          toList([
            ["sheet", $json.string(name)],
            ["start_index", $json.int(start_index)],
            ["questions", encode_sheet_questions(content, invalid, types)],
          ]),
        );
      },
    ),
  );
}

export function encode(new_proposal, org_id, user_id) {
  let $ = new_proposal.xlsx;
  if ($ instanceof $option.None) {
    return new Error(undefined);
  } else {
    let xlsx = $[0];
    let _pipe = $json.object(
      toList([
        ["proposal", encode_proposal(new_proposal, org_id, user_id, xlsx)],
        ["spreadsheet", $spreadsheet.encode(xlsx)],
        ["questions", encode_questions(xlsx.data, new_proposal)],
      ]),
    );
    let _pipe$1 = $json.to_string(_pipe);
    let _pipe$2 = to_form_data(_pipe$1, xlsx.blob);
    return new Ok(_pipe$2);
  }
}
