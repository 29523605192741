/// <reference types="./layout.d.mts" />
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $el from "../../../sketch/sketch/lustre/element.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { px } from "../../../sketch/sketch/size.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

class Gap extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Align extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Justify extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Flex extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Tag extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Padding extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Position extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Overflow extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Attributes extends $CustomType {
  constructor(gap, align, justify, flex, tag, padding, position, overflow) {
    super();
    this.gap = gap;
    this.align = align;
    this.justify = justify;
    this.flex = flex;
    this.tag = tag;
    this.padding = padding;
    this.position = position;
    this.overflow = overflow;
  }
}

export function overflow(value) {
  return new Overflow(value);
}

export function gap(value) {
  return new Gap(value);
}

export function align(value) {
  return new Align(value);
}

export function justify(value) {
  return new Justify(value);
}

export function flex(value) {
  return new Flex(value);
}

export function tag(value) {
  return new Tag(value);
}

export function padding(value) {
  return new Padding(value);
}

export function position(value) {
  return new Position(value);
}

function compute_attributes(attrs) {
  let new$ = new Attributes(
    0,
    "stretch",
    "stretch",
    new None(),
    "div",
    0,
    "static",
    new $option.None(),
  );
  return $list.fold(
    attrs,
    new$,
    (acc, val) => {
      if (val instanceof Gap) {
        let gap$1 = val[0];
        return acc.withFields({ gap: gap$1 });
      } else if (val instanceof Align) {
        let align$1 = val[0];
        return acc.withFields({ align: align$1 });
      } else if (val instanceof Justify) {
        let justify$1 = val[0];
        return acc.withFields({ justify: justify$1 });
      } else if (val instanceof Flex) {
        let value = val[0];
        return acc.withFields({ flex: new Some(value) });
      } else if (val instanceof Tag) {
        let tag$1 = val[0];
        return acc.withFields({ tag: tag$1 });
      } else if (val instanceof Padding) {
        let padding$1 = val[0];
        return acc.withFields({ padding: padding$1 });
      } else if (val instanceof Position) {
        let position$1 = val[0];
        return acc.withFields({ position: position$1 });
      } else {
        let overflow$1 = val[0];
        return acc.withFields({ overflow: new Some(overflow$1) });
      }
    },
  );
}

export function row(row_attrs, attrs, children) {
  let styles = compute_attributes(row_attrs);
  let id = "row-" + $string.inspect(styles);
  return $el.dynamic(
    "div",
    attrs,
    children,
    id,
    toList([
      $s.display("flex"),
      $s.flex_direction("row"),
      $s.align_items(styles.align),
      $s.justify_content(styles.justify),
      $s.gap(px(styles.gap)),
      $s.padding(px(styles.padding)),
      $s.position(styles.position),
      $s.overflow(
        (() => {
          let $ = styles.overflow;
          if ($ instanceof Some) {
            let value = $[0];
            return value;
          } else {
            return "visible";
          }
        })(),
      ),
      $s.flex(
        (() => {
          let $ = styles.flex;
          if ($ instanceof Some) {
            let value = $[0];
            return value;
          } else {
            return "initial";
          }
        })(),
      ),
    ]),
  );
}

export function column(column_attrs, attrs, children) {
  let styles = compute_attributes(column_attrs);
  let id = "column-" + $string.inspect(styles);
  return $el.dynamic(
    styles.tag,
    attrs,
    children,
    id,
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.align_items(styles.align),
      $s.justify_content(styles.justify),
      $s.gap(px(styles.gap)),
      $s.padding(px(styles.padding)),
      $s.position(styles.position),
      $s.overflow(
        (() => {
          let $ = styles.overflow;
          if ($ instanceof Some) {
            let value = $[0];
            return value;
          } else {
            return "visible";
          }
        })(),
      ),
      $s.flex(
        (() => {
          let $ = styles.flex;
          if ($ instanceof Some) {
            let value = $[0];
            return value;
          } else {
            return "initial";
          }
        })(),
      ),
    ]),
  );
}
