/// <reference types="./feed.d.mts" />
import * as $s from "../../../sketch/sketch.mjs";
import * as $el from "../../../sketch/sketch/lustre/element.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { px, vh } from "../../../sketch/sketch/size.mjs";
import * as $colors from "../../design_system/colors.mjs";
import * as $display from "../../design_system/typography/display.mjs";
import * as $text from "../../design_system/typography/text.mjs";
import { toList } from "../../gleam.mjs";

export function feed_title(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.display("flex"),
      $s.justify_content("space-between"),
      $s.align_items("center"),
      $s.padding_("20px 16px"),
      $s.position("sticky"),
      $s.top(px(0)),
      $s.border_bottom("1px solid transparent"),
      $s.transition("all .3s"),
      $s.background($colors.light.white),
    ]),
  );
}

export function title(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([$s.compose($display.xs())]),
  );
}

export function mark_all_as_read(attrs, children) {
  return $el.element(
    "button",
    attrs,
    children,
    toList([
      $s.display("flex"),
      $s.gap(px(8)),
      $s.align_items("center"),
      $s.background($colors.light.white),
      $s.transition("background .3s"),
      $s.padding_("6px 12px"),
      $s.border_radius(px(8)),
      $s.hover(toList([$s.background($colors.light.grey_50)])),
      $s.disabled(toList([$s.background("transparent"), $s.cursor("default")])),
    ]),
  );
}

export function icon_wrapper(child) {
  return $el.element(
    "div",
    toList([]),
    toList([child]),
    toList([$s.width(px(20)), $s.height(px(20))]),
  );
}

export function notification_wrapper(attrs, children) {
  return $el.element("div", attrs, children, toList([$s.padding_("16px 0px")]));
}

function dot() {
  return $s.class$(
    toList([
      $s.width(px(12)),
      $s.height(px(12)),
      $s.border_radius(px(6)),
      $s.property("flex-shrink", "0"),
    ]),
  );
}

export function active_dot() {
  return $el.element(
    "div",
    toList([]),
    toList([]),
    toList([$s.compose(dot()), $s.background($colors.light.error_800)]),
  );
}

export function inactive_dot() {
  return $el.element("div", toList([]), toList([]), toList([$s.compose(dot())]));
}

export function dim_text(child) {
  return $el.element(
    "div",
    toList([]),
    toList([child]),
    toList([$s.color($colors.light.grey_600)]),
  );
}

export function text(child) {
  return $el.element("div", toList([]), toList([child]), toList([]));
}

export function wrapper(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.position("absolute"),
      $s.height(vh(100)),
      $s.width(px(400)),
      $s.background("white"),
      $s.z_index(90_000),
      $s.overflow("auto"),
      $s.transition("left .6s"),
      $s.padding_("0px 24px"),
      $s.compose($text.sm($text.regular)),
      $s.border_right("1px solid " + $colors.light.grey_400),
    ]),
  );
}

export function date(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.compose($text.xs($text.regular)),
      $s.color($colors.light.grey_800),
    ]),
  );
}
