/// <reference types="./export.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function export$() {
  let content = "<g clip-path=\"url(#clip0_7646_1996)\">\n      <path d=\"M12.5 3.33335H4.16667V16.6667H15.8333V6.66669H12.5V3.33335ZM2.5 2.49335C2.5 2.03669 2.8725 1.66669 3.3325 1.66669H13.3333L17.5 5.83335V17.4942C17.5008 17.6036 17.48 17.7121 17.4388 17.8135C17.3976 17.9149 17.3369 18.0072 17.2601 18.0852C17.1832 18.1631 17.0918 18.2251 16.991 18.2677C16.8901 18.3103 16.7819 18.3326 16.6725 18.3334H3.3275C3.10865 18.3318 2.89918 18.2443 2.74435 18.0896C2.58951 17.9349 2.50175 17.7255 2.5 17.5067V2.49335ZM10 9.16669V6.66669L13.3333 10L10 13.3334V10.8334H6.66667V9.16669H10Z\" fill=\"currentColor\"/>\n    </g>\n    <defs>\n      <clipPath id=\"clip0_7646_1996\">\n      <rect width=\"20\" height=\"20\" fill=\"currentColor\"/>\n      </clipPath>\n    </defs>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 20 20"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
