/// <reference types="./tooltip.d.mts" />
import * as $attribute from "../../lustre/lustre/attribute.mjs";
import * as $element from "../../lustre/lustre/element.mjs";
import * as $html from "../../lustre/lustre/element/html.mjs";
import { toList } from "../gleam.mjs";

export function tooltip(attributes, content, tooltip) {
  return $element.element(
    "steerlab-tooltip",
    attributes,
    toList([
      $html.div(
        toList([$attribute.attribute("slot", "tooltip-content")]),
        toList([content]),
      ),
      $html.div(
        toList([$attribute.attribute("slot", "tooltip-tooltip")]),
        toList([tooltip]),
      ),
    ]),
  );
}
