/// <reference types="./overview.d.mts" />
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $uri from "../../../gleam_stdlib/gleam/uri.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $model from "../../data/model.mjs";
import * as $route from "../../data/route.mjs";
import * as $button from "../../design_system/components/button.mjs";
import * as $layout from "../../design_system/components/layout.mjs";
import * as $icons from "../../design_system/icons.mjs";
import { toList, makeError } from "../../gleam.mjs";
import * as $proposals_table from "../../view/components/proposals_table.mjs";
import * as $tab_bar from "../../view/components/tab_bar.mjs";

export function view(model) {
  let $ = model.route;
  if (!($ instanceof $route.Proposals)) {
    throw makeError(
      "assignment_no_match",
      "view/proposals/overview",
      17,
      "view",
      "Assignment pattern did not match",
      { value: $ }
    )
  }
  let route = $[0];
  let ongoing = $list.filter(
    model.proposals,
    (p) => { return $proposal.is_ongoing(p[1]); },
  );
  let previous = $list.filter(
    model.proposals,
    (p) => { return $proposal.is_finished(p[1]); },
  );
  let going_len = $list.length(ongoing);
  let prev_len = $list.length(previous);
  let no_ongoing = going_len === 0;
  let no_previous = prev_len === 0;
  return $el.fragment(
    toList([
      $tab_bar.tab_bar(
        toList([
          (() => {
            let _pipe = new $route.Proposals(new $route.OnGoing());
            let _pipe$1 = $tab_bar.tab(
              _pipe,
              toList([$el.text(("Ongoing (" + $int.to_string(going_len)) + ")")]),
            );
            return $tab_bar.disabled(_pipe$1, no_ongoing);
          })(),
          (() => {
            let _pipe = new $route.Proposals(new $route.Previous());
            let _pipe$1 = $tab_bar.tab(
              _pipe,
              toList([$el.text(("Previous (" + $int.to_string(prev_len)) + ")")]),
            );
            return $tab_bar.disabled(_pipe$1, no_previous);
          })(),
          $tab_bar.element(
            $layout.row(
              toList([$layout.flex("1"), $layout.justify("end")]),
              toList([]),
              toList([
                $button.primary_link(
                  (() => {
                    let _pipe = $route.to_uri(
                      new $route.Proposals(new $route.Add(false)),
                    );
                    let _pipe$1 = $result.map(_pipe, $uri.to_string);
                    let _pipe$2 = $result.unwrap(_pipe$1, "#");
                    let _pipe$3 = $a.href(_pipe$2);
                    return $list.wrap(_pipe$3);
                  })(),
                  toList([
                    $button.icon($icons.add()),
                    $el.text("Add a proposal"),
                  ]),
                ),
              ]),
            ),
          ),
        ]),
      ),
      (() => {
        if (route instanceof $route.OnGoing && (!no_ongoing)) {
          return $proposals_table.table(
            model.loading.proposals,
            ongoing,
            model.users,
          );
        } else if (route instanceof $route.Previous && (!no_previous)) {
          return $proposals_table.table(
            model.loading.proposals,
            previous,
            model.users,
          );
        } else {
          return $proposals_table.table(
            model.loading.proposals,
            toList([]),
            toList([]),
          );
        }
      })(),
    ]),
  );
}
