/// <reference types="./building.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function building() {
  let content = "<path d=\"M21 20.2383H23V22.2383H1V20.2383H3V3.23828C3 2.97306 3.10536 2.71871 3.29289 2.53117C3.48043 2.34364 3.73478 2.23828 4 2.23828H20C20.2652 2.23828 20.5196 2.34364 20.7071 2.53117C20.8946 2.71871 21 2.97306 21 3.23828V20.2383ZM19 20.2383V4.23828H5V20.2383H19ZM8 11.2383H11V13.2383H8V11.2383ZM8 7.23828H11V9.23828H8V7.23828ZM8 15.2383H11V17.2383H8V15.2383ZM13 15.2383H16V17.2383H13V15.2383ZM13 11.2383H16V13.2383H13V11.2383ZM13 7.23828H16V9.23828H13V7.23828Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 24 24"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
