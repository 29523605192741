/// <reference types="./copilot_question.d.mts" />
import * as $birl from "../../birl/birl.mjs";
import * as $decode from "../../decode/decode.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $float from "../../gleam_stdlib/gleam/float.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $decode_ from "../decode/extra.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class Display extends $CustomType {
  constructor(score) {
    super();
    this.score = score;
  }
}

export class Answer extends $CustomType {
  constructor(short, long, yes_no, custom) {
    super();
    this.short = short;
    this.long = long;
    this.yes_no = yes_no;
    this.custom = custom;
  }
}

export class CopilotQuestion extends $CustomType {
  constructor(id, thread_id, display, content, user_id, answer, org_id, loading, data_points_id, created_at, ai_processing) {
    super();
    this.id = id;
    this.thread_id = thread_id;
    this.display = display;
    this.content = content;
    this.user_id = user_id;
    this.answer = answer;
    this.org_id = org_id;
    this.loading = loading;
    this.data_points_id = data_points_id;
    this.created_at = created_at;
    this.ai_processing = ai_processing;
  }
}

export function encode_answer(answer) {
  return $json.object(
    toList([
      ["short", $json.nullable(answer.short, $json.string)],
      ["long", $json.nullable(answer.long, $json.string)],
      ["custom", $json.nullable(answer.custom, $json.string)],
      ["yes_no", $json.nullable(answer.yes_no, $json.string)],
    ]),
  );
}

export function encode_display(display) {
  return $json.object(
    toList([["score", $json.nullable(display.score, $json.float)]]),
  );
}

export function encode(question) {
  return $json.object(
    toList([
      ["id", $json.string(question.id)],
      ["thread_id", $json.string(question.thread_id)],
      ["display", $decode_.encode_json(encode_display(question.display))],
      ["content", $json.string(question.content)],
      ["user_id", $json.string(question.user_id)],
      ["answer", $decode_.encode_json(encode_answer(question.answer))],
      ["org_id", $json.string(question.org_id)],
      ["loading", $json.bool(question.loading)],
      ["created_at", $json.string($birl.to_iso8601(question.created_at))],
      [
        "data_points_id",
        $decode_.encode_json(
          $json.array(
            question.data_points_id,
            (s) => {
              return $json.object(
                toList([
                  ["data_point_id", $json.string(s[0])],
                  ["score", $json.float(s[1])],
                ]),
              );
            },
          ),
        ),
      ],
      ["ai_processing", $json.bool(question.ai_processing)],
    ]),
  );
}

export function decode_display(dyn) {
  let _pipe = $decode.into(
    $decode.parameter((score) => { return new Display(score); }),
  );
  let _pipe$1 = $decode.field(_pipe, "score", $decode.optional($decode.float));
  return $decode.from(_pipe$1, dyn);
}

export function decode(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (id) => {
        return $decode.parameter(
          (thread_id) => {
            return $decode.parameter(
              (display) => {
                return $decode.parameter(
                  (content) => {
                    return $decode.parameter(
                      (user_id) => {
                        return $decode.parameter(
                          (answer) => {
                            return $decode.parameter(
                              (loading) => {
                                return $decode.parameter(
                                  (org_id) => {
                                    return $decode.parameter(
                                      (data_points_id) => {
                                        return $decode.parameter(
                                          (created_at) => {
                                            return $decode.parameter(
                                              (ai_processing) => {
                                                return new CopilotQuestion(
                                                  id,
                                                  thread_id,
                                                  display,
                                                  content,
                                                  user_id,
                                                  answer,
                                                  org_id,
                                                  loading,
                                                  data_points_id,
                                                  created_at,
                                                  ai_processing,
                                                );
                                              },
                                            );
                                          },
                                        );
                                      },
                                    );
                                  },
                                );
                              },
                            );
                          },
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(
    _pipe,
    "id",
    (() => {
      let _pipe$1 = $decode.string;
      return $decode.map(_pipe$1, $string.lowercase);
    })(),
  );
  let _pipe$2 = $decode.field(
    _pipe$1,
    "thread_id",
    (() => {
      let _pipe$2 = $decode.string;
      return $decode.map(_pipe$2, $string.lowercase);
    })(),
  );
  let _pipe$3 = $decode.field(_pipe$2, "display", $decode_.json(decode_display));
  let _pipe$4 = $decode.field(_pipe$3, "content", $decode.string);
  let _pipe$5 = $decode.field(_pipe$4, "user_id", $decode.string);
  let _pipe$6 = $decode.field(_pipe$5, "answer", $decode_.json(decode_answer));
  let _pipe$7 = $decode.field(_pipe$6, "loading", $decode.bool);
  let _pipe$8 = $decode.field(_pipe$7, "org_id", $decode.string);
  let _pipe$9 = $decode.field(
    _pipe$8,
    "data_points_id",
    (() => {
      let _pipe$9 = $decode.optional(
        $decode_.json(
          (dyn) => {
            let _pipe$9 = $decode.list(
              (() => {
                let _pipe$9 = $decode.into(
                  $decode.parameter(
                    (a) => {
                      return $decode.parameter(
                        (b) => {
                          if (a instanceof $option.Some &&
                          b instanceof $option.Some) {
                            let a$1 = a[0];
                            let b$1 = b[0];
                            return new $option.Some([a$1, b$1]);
                          } else {
                            return new $option.None();
                          }
                        },
                      );
                    },
                  ),
                );
                let _pipe$10 = $decode.field(
                  _pipe$9,
                  "data_point_id",
                  $decode.optional($decode.string),
                );
                return $decode.field(
                  _pipe$10,
                  "score",
                  $decode.optional($decode.float),
                );
              })(),
            );
            let _pipe$10 = $decode.map(_pipe$9, $option.values);
            let _pipe$11 = $decode.map(
              _pipe$10,
              (_capture) => {
                return $list.sort(
                  _capture,
                  (a, b) => { return $float.compare(b[1], a[1]); },
                );
              },
            );
            return $decode.from(_pipe$11, dyn);
          },
        ),
      );
      return $decode.map(
        _pipe$9,
        (_capture) => { return $option.unwrap(_capture, toList([])); },
      );
    })(),
  );
  let _pipe$10 = $decode.field(_pipe$9, "created_at", $decode_.time());
  let _pipe$11 = $decode.field(_pipe$10, "ai_processing", $decode.bool);
  return $decode.from(_pipe$11, dyn);
}

export function decode_answer(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (short) => {
        return $decode.parameter(
          (long) => {
            return $decode.parameter(
              (yes_no) => {
                return $decode.parameter(
                  (custom) => { return new Answer(short, long, yes_no, custom); },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "short", $decode.optional($decode.string));
  let _pipe$2 = $decode.field(_pipe$1, "long", $decode.optional($decode.string));
  let _pipe$3 = $decode.field(
    _pipe$2,
    "yes_no",
    $decode.optional($decode.string),
  );
  let _pipe$4 = $decode.field(
    _pipe$3,
    "custom",
    $decode.optional($decode.string),
  );
  let _pipe$5 = $decode.optional(_pipe$4);
  let _pipe$6 = $decode.map(
    _pipe$5,
    (_capture) => {
      return $option.unwrap(
        _capture,
        new Answer(
          new $option.None(),
          new $option.None(),
          new $option.None(),
          new $option.None(),
        ),
      );
    },
  );
  return $decode.from(_pipe$6, dyn);
}
