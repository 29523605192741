/// <reference types="./checkbox.d.mts" />
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $icons from "../../design_system/icons.mjs";
import * as $s from "../../design_system/internals/styles/checkbox.mjs";
import { Error, toList, CustomType as $CustomType } from "../../gleam.mjs";

export class Checked extends $CustomType {}

export class Unchecked extends $CustomType {}

export class Indeterminate extends $CustomType {}

function cancel_checkbox_propagation() {
  return $e.on(
    "click",
    (event) => {
      $e.stop_propagation(event);
      return new Error(toList([]));
    },
  );
}

export function checkbox(state, on_check) {
  let checked$1 = (() => {
    if (state instanceof Unchecked) {
      return false;
    } else {
      return true;
    }
  })();
  return $s.label(
    toList([cancel_checkbox_propagation()]),
    toList([
      $s.input(
        toList([
          $a.type_("checkbox"),
          $a.checked(checked$1),
          $e.on_check(on_check),
        ]),
      ),
      $s.checkbox(
        checked$1,
        toList([]),
        toList([
          (() => {
            if (state instanceof Checked) {
              return $s.icon($icons.checkmark());
            } else if (state instanceof Indeterminate) {
              return $s.icon($icons.minus());
            } else {
              return $el.none();
            }
          })(),
        ]),
      ),
    ]),
  );
}

export function checked(checked) {
  if (checked) {
    return new Checked();
  } else {
    return new Unchecked();
  }
}
