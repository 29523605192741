/// <reference types="./lightbulb_flash.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import { attribute } from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import * as $svg from "../../../lustre/lustre/element/svg.mjs";
import { toList } from "../../gleam.mjs";

export function lightbulb_flash() {
  return $html.svg(
    toList([
      attribute("xmlns", "http://www.w3.org/2000/svg"),
      attribute("fill", "none"),
      attribute("viewBox", "0 0 24 24"),
    ]),
    toList([
      $svg.path(
        toList([
          attribute("fill", "currentColor"),
          attribute(
            "d",
            "M7.94096 18.8503C7.64396 17.5773 6.30396 16.5363 5.75396 15.8503C4.81215 14.6736 4.22191 13.2548 4.05122 11.7573C3.88054 10.2598 4.13636 8.74452 4.78921 7.38606C5.44207 6.0276 6.46539 4.88119 7.74132 4.0789C9.01724 3.27661 10.4938 2.85108 12.001 2.85132C13.5082 2.85156 14.9847 3.27757 16.2604 4.08026C17.536 4.88296 18.559 6.0297 19.2114 7.38837C19.8638 8.74704 20.1192 10.2624 19.948 11.7598C19.7768 13.2573 19.1861 14.6759 18.244 15.8523C17.694 16.5373 16.356 17.5783 16.059 18.8503H7.93996H7.94096ZM16 20.8503V21.8503C16 22.3807 15.7892 22.8895 15.4142 23.2645C15.0391 23.6396 14.5304 23.8503 14 23.8503H9.99996C9.46953 23.8503 8.96082 23.6396 8.58575 23.2645C8.21067 22.8895 7.99996 22.3807 7.99996 21.8503V20.8503H16ZM13 10.8553V6.85032L8.49996 12.8553H11V16.8553L15.5 10.8553H13Z",
          ),
        ]),
      ),
    ]),
  );
}
