/// <reference types="./navbar.d.mts" />
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $el from "../../../sketch/sketch/lustre/element.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { percent, px } from "../../../sketch/sketch/size.mjs";
import * as $colors from "../../design_system/colors.mjs";
import * as $text from "../../design_system/typography/text.mjs";
import { toList } from "../../gleam.mjs";

export function navbar(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.display("flex"),
      $s.padding(px(40)),
      $s.background($colors.light.grey_100),
      $s.gap(px(60)),
      $s.flex_direction("column"),
      $s.overflow("auto"),
      $s.z_index(90_005),
    ]),
  );
}

export function logo(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.display("flex"),
      $s.max_height(px(35)),
      $s.gap(px(14)),
      $s.width(percent(100)),
    ]),
  );
}

export function logo_text(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([$s.padding_("7px 0"), $s.flex("1")]),
  );
}

export function navbar_main(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([$s.display("flex"), $s.flex_direction("column"), $s.gap(px(32))]),
  );
}

export function name_notifications(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.display("flex"),
      $s.justify_content("space-between"),
      $s.align_items("center"),
    ]),
  );
}

export function name(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.gap(px(16)),
      $s.compose($text.md($text.regular)),
    ]),
  );
}

export function nav_links(attrs, children) {
  return $el.element(
    "nav",
    attrs,
    children,
    toList([$s.display("flex"), $s.flex_direction("column"), $s.gap(px(8))]),
  );
}

export function nav_link(color, active, attrs, children) {
  let id = (("nav-link-color-" + color) + "-active-") + $bool.to_string(active);
  return $el.dynamic(
    "a",
    attrs,
    children,
    id,
    toList([
      $s.display("flex"),
      $s.flex_direction("row"),
      $s.align_items("center"),
      $s.gap(px(8)),
      $s.border_radius(px(8)),
      $s.color(color),
      $s.text_decoration("none"),
      $s.compose($text.lg($text.medium)),
      $s.padding(px(14)),
      $s.transition("all .3s"),
      $s.background(
        (() => {
          if (active) {
            return $colors.light.grey_200;
          } else {
            return $colors.light.transparent;
          }
        })(),
      ),
      $s.color(
        (() => {
          if (active) {
            return $colors.light.onyx;
          } else {
            return color;
          }
        })(),
      ),
      $s.hover(
        (() => {
          if (!active) {
            return toList([$s.background($colors.light.white)]);
          } else {
            return toList([]);
          }
        })(),
      ),
    ]),
  );
}

export function spacer() {
  return $el.element("div", toList([]), toList([]), toList([$s.flex("1")]));
}

export function navbar_settings(attrs, children) {
  return $el.element(
    "div",
    attrs,
    children,
    toList([$s.display("flex"), $s.flex_direction("column"), $s.gap(px(32))]),
  );
}

export function icon_wrapper(child) {
  return $el.element(
    "div",
    toList([]),
    toList([child]),
    toList([$s.width(px(24)), $s.height(px(24))]),
  );
}

export function bell_button(active, attrs, children) {
  let id = "bell-button-" + $bool.to_string(active);
  return $el.dynamic(
    "button",
    attrs,
    children,
    id,
    toList([
      $s.padding(px(9)),
      $s.border_radius(px(50)),
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("center"),
      $s.background(
        (() => {
          if (!active) {
            return "transparent";
          } else {
            return $colors.light.white;
          }
        })(),
      ),
      $s.position("relative"),
      $s.hover(toList([$s.background($colors.light.white)])),
    ]),
  );
}

export function bell_active_dot() {
  return $el.element(
    "div",
    toList([]),
    toList([]),
    toList([
      $s.width(px(10)),
      $s.height(px(10)),
      $s.border_radius(px(8)),
      $s.background($colors.light.error_800),
      $s.position("absolute"),
      $s.top(px(2)),
      $s.right(px(2)),
    ]),
  );
}
