/// <reference types="./add.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function add() {
  let content = "<g clip-path=\"url(#clip0_7164_4384)\">\n    <path d=\"M9.16663 9.16666V4.16666H10.8333V9.16666H15.8333V10.8333H10.8333V15.8333H9.16663V10.8333H4.16663V9.16666H9.16663Z\" fill=\"currentColor\"/>\n    </g>\n    <defs>\n    <clipPath id=\"clip0_7164_4384\">\n    <rect width=\"20\" height=\"20\" fill=\"none\"/>\n    </clipPath>\n    </defs>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 20 20"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
