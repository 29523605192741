/// <reference types="./connectors.d.mts" />
import * as $connector from "../../common/data/connector.mjs";
import * as $confluence from "../../common/data/connector/confluence.mjs";
import * as $connector_settings from "../../common/data/connector_settings.mjs";
import * as $http from "../../gleam_http/gleam/http.mjs";
import * as $promise from "../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $window from "../../plinth/plinth/browser/window.mjs";
import * as $model from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";
import * as $ask from "../frontend/ask.mjs";
import * as $error from "../frontend/error.mjs";
import { toList, makeError, divideInt } from "../gleam.mjs";
import * as $effects from "../update/effects.mjs";
import * as $utils from "../utils.mjs";
import { to_path } from "../utils.mjs";

function handle_fetch_connector_data(model, connector) {
  let $ = model.access_token;
  if ($ instanceof $option.None) {
    return $effect.none();
  } else {
    let access_token = $[0];
    return $effect.from(
      (_) => {
        let _pipe = $ask.to(new $ask.Heimdall(), "/refresh-data");
        let _pipe$1 = $ask.bearing(_pipe, access_token);
        let _pipe$2 = $ask.query(
          _pipe$1,
          "connector",
          $connector.to_string(connector),
        );
        $ask.run(_pipe$2)
        return undefined;
      },
    );
  }
}

function open_connector_popup(connector, access_token) {
  let connector$1 = $connector.to_string(connector);
  let body = $json.object(toList([["connector", $json.string(connector$1)]]));
  return $promise.map_try(
    (() => {
      let _pipe = $ask.to(new $ask.Loki(), "/authorize");
      let _pipe$1 = $ask.via(_pipe, new $http.Post());
      let _pipe$2 = $ask.with$(_pipe$1, body);
      let _pipe$3 = $ask.bearing(_pipe$2, access_token);
      let _pipe$4 = $ask.expect(_pipe$3, $dynamic.string);
      return $ask.run(_pipe$4);
    })(),
    (response) => {
      let $ = [400, 600];
      let width = $[0];
      let height = $[1];
      let win = $window.self();
      let left = $window.screen_x(win) + (divideInt(
        ($window.inner_width(win) - width),
        2
      ));
      let top = $window.screen_y(win) + (divideInt(
        ($window.inner_height(win) - height),
        2
      ));
      let _pipe = toList([
        "popup",
        "height=" + $int.to_string(height),
        "width=" + $int.to_string(width),
        "left=" + $int.to_string(left),
        "top=" + $int.to_string(top),
      ]);
      let _pipe$1 = $string.join(_pipe, ",");
      let _pipe$2 = ((_capture) => {
        return $window.open(
          response,
          "steerlab:connectors:authorize:popup",
          _capture,
        );
      })(_pipe$1);
      return $result.replace_error(
        _pipe$2,
        new $error.NilError("Opening new window"),
      );
    },
  );
}

function handle_connector_connection(model, connector) {
  return $effect.from(
    (dispatch) => {
      return $bool.guard(
        $option.is_none(model.access_token),
        undefined,
        () => {
          let $ = model.access_token;
          if (!($ instanceof $option.Some)) {
            throw makeError(
              "assignment_no_match",
              "update/connectors",
              92,
              "",
              "Assignment pattern did not match",
              { value: $ }
            )
          }
          let access_token = $[0];
          if (connector instanceof $connector.Steerlab) {
            return undefined;
          } else if (connector instanceof $connector.SteerlabQna) {
            return undefined;
          } else if (connector instanceof $connector.GoogleDrive) {
            open_connector_popup(connector, access_token);
            return undefined;
          } else if (connector instanceof $connector.OneDrive) {
            open_connector_popup(connector, access_token);
            return undefined;
          } else {
            let disabled = $confluence.is_submit_disabled(
              model.connectors.confluence,
            );
            return $bool.guard(
              disabled,
              undefined,
              () => {
                let connector$1 = $connector.to_string(connector);
                let _pipe = $ask.to(new $ask.Loki(), "/api-token");
                let _pipe$1 = $ask.via(_pipe, new $http.Post());
                let _pipe$2 = $ask.bearing(_pipe$1, access_token);
                let _pipe$3 = $ask.with$(
                  _pipe$2,
                  $json.object(
                    toList([
                      ["token", $confluence.encode(model.connectors.confluence)],
                      ["connector", $json.string(connector$1)],
                    ]),
                  ),
                );
                let _pipe$4 = $ask.run(_pipe$3);
                $promise.tap(
                  _pipe$4,
                  (_) => { return dispatch(new $msg.FetchUserData()); },
                )
                return undefined;
              },
            );
          }
        },
      );
    },
  );
}

function update_google_folder_id(model) {
  return $effects.require_access_token(
    model,
    (access_token) => {
      return $effects.require_org_id(
        model,
        (org_id) => {
          return $effect.from(
            (_) => {
              let at = to_path(
                toList(["organizations", org_id, "connectors-settings"]),
              );
              let body = (() => {
                let _pipe = model.connectors.google_folder_id;
                let _pipe$1 = new $connector_settings.GoogleDrive(_pipe);
                return $connector_settings.encode(_pipe$1);
              })();
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.via(_pipe, new $http.Post());
              let _pipe$2 = $ask.with$(_pipe$1, body);
              let _pipe$3 = $ask.bearing(_pipe$2, access_token);
              let _pipe$4 = $ask.expect(_pipe$3, $connector_settings.decode);
              $ask.run(_pipe$4)
              return undefined;
            },
          );
        },
      );
    },
  );
}

export function handle_on_connectors(model, msg) {
  if (msg instanceof $msg.FetchConnectorData) {
    let connector = msg[0];
    let _pipe = handle_fetch_connector_data(model, connector);
    return ((_capture) => { return $pair.new$(model, _capture); })(_pipe);
  } else if (msg instanceof $msg.ConnectTo) {
    let connector = msg[0];
    let _pipe = handle_connector_connection(model, connector);
    return ((_capture) => { return $pair.new$(model, _capture); })(_pipe);
  } else if (msg instanceof $msg.UpdateFetched) {
    let connector = msg[0];
    let connected = msg[1];
    let _pipe = model.connectors.fetched;
    let _pipe$1 = $list.key_set(_pipe, connector, connected);
    let _pipe$2 = ((fetched) => {
      return model.connectors.withFields({ fetched: fetched });
    })(_pipe$1);
    let _pipe$3 = ((_capture) => {
      return $model.set_connectors(model, _capture);
    })(_pipe$2);
    return $pair.new$(_pipe$3, $effect.none());
  } else if (msg instanceof $msg.UpdateConfluenceApiKey) {
    let api_key = msg[0];
    let _pipe = model.connectors.confluence.withFields({ api_key: api_key });
    let _pipe$1 = ((confluence) => {
      return model.connectors.withFields({ confluence: confluence });
    })(_pipe);
    let _pipe$2 = ((_capture) => {
      return $model.set_connectors(model, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UpdateConfluenceDomain) {
    let domain = msg[0];
    let _pipe = model.connectors.confluence.withFields({ domain: domain });
    let _pipe$1 = ((confluence) => {
      return model.connectors.withFields({ confluence: confluence });
    })(_pipe);
    let _pipe$2 = ((_capture) => {
      return $model.set_connectors(model, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UpdateConfluenceUser) {
    let user = msg[0];
    let _pipe = model.connectors.confluence.withFields({ user: user });
    let _pipe$1 = ((confluence) => {
      return model.connectors.withFields({ confluence: confluence });
    })(_pipe);
    let _pipe$2 = ((_capture) => {
      return $model.set_connectors(model, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.ToggleConfluencePanel) {
    let opened = model.connectors.confluence_opened;
    let _pipe = model.connectors.withFields({ confluence_opened: !opened });
    let _pipe$1 = ((_capture) => {
      return $model.set_connectors(model, _capture);
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UpdateGoogleFolderId) {
    let google_folder_id = msg[0];
    let _pipe = model.connectors.withFields({
      google_folder_id: google_folder_id
    });
    let _pipe$1 = ((_capture) => {
      return $model.set_connectors(model, _capture);
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.SubmitGoogleFolderId) {
    return [model, update_google_folder_id(model)];
  } else {
    let settings = msg[0];
    let _pipe = model.connectors.withFields({ settings: settings });
    let _pipe$1 = ((_capture) => {
      return $model.set_connectors(model, _capture);
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  }
}
