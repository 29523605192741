/// <reference types="./route.d.mts" />
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $uri from "../../gleam_stdlib/gleam/uri.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import { updateTitle as do_update_title } from "../frontend.ffi.mjs";
import { Ok, toList, CustomType as $CustomType } from "../gleam.mjs";

export class Account extends $CustomType {}

export class Collaborators extends $CustomType {}

export class Integrations extends $CustomType {}

export class OnGoing extends $CustomType {}

export class Previous extends $CustomType {}

export class Add extends $CustomType {
  constructor(loading) {
    super();
    this.loading = loading;
  }
}

export class Show extends $CustomType {
  constructor(id, sheet) {
    super();
    this.id = id;
    this.sheet = sheet;
  }
}

export class AiSearch extends $CustomType {}

export class AiResponse extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class DataSources extends $CustomType {}

export class QuestionBank extends $CustomType {}

export class NewQuestion extends $CustomType {}

export class Overview extends $CustomType {}

export class ContentLibrary extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Proposals extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class CoPilot extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Settings extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Organization extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Support extends $CustomType {}

export class Login extends $CustomType {
  constructor(invitation, organization) {
    super();
    this.invitation = invitation;
    this.organization = organization;
  }
}

export class NotFound extends $CustomType {}

export function from_uri(uri) {
  let $ = $uri.path_segments(uri.path);
  if ($.hasLength(0)) {
    return new Overview();
  } else if ($.hasLength(1) && $.head === "content-library") {
    return new ContentLibrary(new DataSources());
  } else if ($.hasLength(1) && $.head === "question-bank") {
    return new ContentLibrary(new QuestionBank());
  } else if ($.hasLength(2) &&
  $.head === "question-bank" &&
  $.tail.head === "new") {
    return new ContentLibrary(new NewQuestion());
  } else if ($.hasLength(1) && $.head === "proposals") {
    return new Proposals(new OnGoing());
  } else if ($.hasLength(2) &&
  $.head === "proposals" &&
  $.tail.head === "previous") {
    return new Proposals(new Previous());
  } else if ($.hasLength(2) && $.head === "proposals" && $.tail.head === "add") {
    return new Proposals(new Add(false));
  } else if ($.hasLength(1) && $.head === "co-pilot") {
    return new CoPilot(new AiSearch());
  } else if ($.hasLength(2) && $.head === "co-pilot") {
    let id = $.tail.head;
    return new CoPilot(new AiResponse(id));
  } else if ($.hasLength(1) && $.head === "settings") {
    return new Settings(new Account());
  } else if ($.hasLength(2) &&
  $.head === "organization" &&
  $.tail.head === "collaborators") {
    return new Organization(new Collaborators());
  } else if ($.hasLength(2) &&
  $.head === "organization" &&
  $.tail.head === "integrations") {
    return new Organization(new Integrations());
  } else if ($.hasLength(1) && $.head === "login") {
    let _pipe = $option.to_result(uri.query, undefined);
    let _pipe$1 = $result.then$(_pipe, $uri.parse_query);
    let _pipe$2 = $result.then$(
      _pipe$1,
      (query) => {
        let find = (a) => {
          let _pipe$2 = $list.key_find(query, a);
          return $option.from_result(_pipe$2);
        };
        let invitation = find("invitation");
        let organization = find("organization");
        return new Ok(new Login(invitation, organization));
      },
    );
    return $result.unwrap(
      _pipe$2,
      new Login(new $option.None(), new $option.None()),
    );
  } else if ($.hasLength(1) && $.head === "support") {
    return new Support();
  } else if ($.hasLength(2) && $.head === "proposals") {
    let id = $.tail.head;
    let _pipe = $option.to_result(uri.query, undefined);
    let _pipe$1 = $result.then$(_pipe, $uri.parse_query);
    let _pipe$2 = $result.then$(
      _pipe$1,
      (_capture) => { return $list.key_find(_capture, "sheet"); },
    );
    let _pipe$3 = $option.from_result(_pipe$2);
    return ((sheet) => { return new Proposals(new Show(id, sheet)); })(_pipe$3);
  } else {
    return new NotFound();
  }
}

export function to_uri(route) {
  if (route instanceof Overview) {
    return $uri.parse("/");
  } else if (route instanceof ContentLibrary && route[0] instanceof DataSources) {
    return $uri.parse("/content-library");
  } else if (route instanceof ContentLibrary && route[0] instanceof QuestionBank) {
    return $uri.parse("/question-bank");
  } else if (route instanceof ContentLibrary && route[0] instanceof NewQuestion) {
    return $uri.parse("/question-bank/new");
  } else if (route instanceof Proposals && route[0] instanceof OnGoing) {
    return $uri.parse("/proposals");
  } else if (route instanceof Proposals && route[0] instanceof Previous) {
    return $uri.parse("/proposals/previous");
  } else if (route instanceof Proposals && route[0] instanceof Add) {
    return $uri.parse("/proposals/add");
  } else if (route instanceof CoPilot && route[0] instanceof AiSearch) {
    return $uri.parse("/co-pilot");
  } else if (route instanceof CoPilot && route[0] instanceof AiResponse) {
    let id = route[0][0];
    return $uri.parse("/co-pilot/" + id);
  } else if (route instanceof Settings && route[0] instanceof Account) {
    return $uri.parse("/settings");
  } else if (route instanceof Organization && route[0] instanceof Collaborators) {
    return $uri.parse("/organization/collaborators");
  } else if (route instanceof Organization && route[0] instanceof Integrations) {
    return $uri.parse("/organization/integrations");
  } else if (route instanceof Support) {
    return $uri.parse("/support");
  } else if (route instanceof NotFound) {
    return $uri.parse("#");
  } else if (route instanceof Login) {
    let invitation = route.invitation;
    let organization = route.organization;
    return $result.map(
      $uri.parse("/login"),
      (uri) => {
        if (invitation instanceof $option.Some &&
        organization instanceof $option.Some) {
          let i = invitation[0];
          let o = organization[0];
          let query = $uri.query_to_string(
            toList([["invitation", i], ["organization", o]]),
          );
          return uri.withFields({ query: new $option.Some(query) });
        } else {
          return uri;
        }
      },
    );
  } else {
    let id = route[0].id;
    let sheet = route[0].sheet;
    return $result.map(
      $uri.parse("/proposals/" + id),
      (uri) => {
        let _pipe = $option.map(
          sheet,
          (s) => { return $uri.query_to_string(toList([["sheet", s]])); },
        );
        return ((query) => { return uri.withFields({ query: query }); })(_pipe);
      },
    );
  }
}

export function update_title(route) {
  return $effect.from(
    (_) => {
      let title = (() => {
        if (route instanceof Overview) {
          return "Overview";
        } else if (route instanceof ContentLibrary) {
          return "Content Library";
        } else if (route instanceof Proposals) {
          return "Proposals";
        } else if (route instanceof CoPilot) {
          return "Co-pilot";
        } else if (route instanceof Settings && route[0] instanceof Account) {
          return "Settings";
        } else if (route instanceof Organization &&
        route[0] instanceof Collaborators) {
          return "Organization Members";
        } else if (route instanceof Organization &&
        route[0] instanceof Integrations) {
          return "Organization Integrations";
        } else if (route instanceof Support) {
          return "Support";
        } else if (route instanceof Login) {
          return "Login";
        } else {
          return "Not Found";
        }
      })();
      do_update_title("Steerlab — " + title);
      return undefined;
    },
  );
}
