/// <reference types="./msg.d.mts" />
import * as $auth0 from "../../auth0_client/auth0/client.mjs";
import * as $auth0_user from "../../auth0_client/auth0/client/user.mjs";
import * as $birl from "../../birl/birl.mjs";
import * as $connector from "../../common/data/connector.mjs";
import * as $connector_settings from "../../common/data/connector_settings.mjs";
import * as $copilot_question from "../../common/data/copilot_question.mjs";
import * as $data_point from "../../common/data/data_point.mjs";
import * as $data_source from "../../common/data/data_source.mjs";
import * as $notification from "../../common/data/notification.mjs";
import * as $proposal from "../../common/data/proposal.mjs";
import * as $question from "../../common/data/question.mjs";
import * as $spreadsheet from "../../common/data/spreadsheet.mjs";
import * as $tag from "../../common/data/tag.mjs";
import * as $user from "../../common/data/user.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $toast from "../../grille_pain/grille_pain/lustre/toast.mjs";
import * as $level from "../../grille_pain/grille_pain/toast/level.mjs";
import * as $route from "../data/route.mjs";
import * as $new_proposal from "../data/ui/new_proposal.mjs";
import { CustomType as $CustomType } from "../gleam.mjs";

export class Add extends $CustomType {}

export class Remove extends $CustomType {}

export class AddQuestionOwner extends $CustomType {
  constructor(question_id, proposal_id, user_id) {
    super();
    this.question_id = question_id;
    this.proposal_id = proposal_id;
    this.user_id = user_id;
  }
}

export class Auth0 extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class AuthStateChanged extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Back extends $CustomType {}

export class ContentLibrary extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class DisplayToast extends $CustomType {
  constructor(level, message) {
    super();
    this.level = level;
    this.message = message;
  }
}

export class FetchUserData extends $CustomType {}

export class HideToast extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class OnConnectors extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class OnCopilotInput extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class OnCopilotQuestionUpdated extends $CustomType {
  constructor(question) {
    super();
    this.question = question;
  }
}

export class OnCopilotSubmit extends $CustomType {}

export class OnCopilotThreads extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class OnNewProposal extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class OnProposalQuestionUpdated extends $CustomType {
  constructor(question) {
    super();
    this.question = question;
  }
}

export class OnProposals extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class OnProposalsQuestions extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class OnRouteChange extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class OnUserFunctionSelected extends $CustomType {
  constructor(user_id, function$) {
    super();
    this.user_id = user_id;
    this.function = function$;
  }
}

export class OnUsers extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class OpenLink extends $CustomType {
  constructor(link) {
    super();
    this.link = link;
  }
}

export class ProposalShow extends $CustomType {
  constructor(id, sheet) {
    super();
    this.id = id;
    this.sheet = sheet;
  }
}

export class QueryProposalQuestions extends $CustomType {
  constructor(timeout, id) {
    super();
    this.timeout = timeout;
    this.id = id;
  }
}

export class SaveProposalTitle extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class ToggleMoreProposalMenu extends $CustomType {
  constructor(question_id) {
    super();
    this.question_id = question_id;
  }
}

export class AskAiInsights extends $CustomType {
  constructor(proposal_id, question_id, asked) {
    super();
    this.proposal_id = proposal_id;
    this.question_id = question_id;
    this.asked = asked;
  }
}

export class ToggleFeed extends $CustomType {}

export class ToggleProposalCollaboratorsPopup extends $CustomType {
  constructor(question_id) {
    super();
    this.question_id = question_id;
  }
}

export class UpdateNotifications extends $CustomType {
  constructor(notifications) {
    super();
    this.notifications = notifications;
  }
}

export class MarkAllNotificationsAsRead extends $CustomType {}

export class MarkNotificationAsRead extends $CustomType {
  constructor(notification_id) {
    super();
    this.notification_id = notification_id;
  }
}

export class UpdateNotification extends $CustomType {
  constructor(notification) {
    super();
    this.notification = notification;
  }
}

export class UpdateProposalQuestion extends $CustomType {
  constructor(question, content) {
    super();
    this.question = question;
    this.content = content;
  }
}

export class UpdateProposalTitle extends $CustomType {
  constructor(id, title) {
    super();
    this.id = id;
    this.title = title;
  }
}

export class UpsertQuestion extends $CustomType {
  constructor(question) {
    super();
    this.question = question;
  }
}

export class ClosePopups extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class Xlsx extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ValidateQuestion extends $CustomType {
  constructor(question) {
    super();
    this.question = question;
  }
}

export class GoToProposalQuestion extends $CustomType {
  constructor(proposal_id, question_id, notification_id) {
    super();
    this.proposal_id = proposal_id;
    this.question_id = question_id;
    this.notification_id = notification_id;
  }
}

export class ToastUser extends $CustomType {
  constructor(options, content) {
    super();
    this.options = options;
    this.content = content;
  }
}

export class UpdateTags extends $CustomType {
  constructor(tags) {
    super();
    this.tags = tags;
  }
}

export class None extends $CustomType {}

export class GenerateXlsx extends $CustomType {
  constructor(proposal_id) {
    super();
    this.proposal_id = proposal_id;
  }
}

export class DownloadXlsx extends $CustomType {
  constructor(proposal_id, toast_id) {
    super();
    this.proposal_id = proposal_id;
    this.toast_id = toast_id;
  }
}

export class FetchConnectorData extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ConnectTo extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UpdateFetched extends $CustomType {
  constructor(x0, x1) {
    super();
    this[0] = x0;
    this[1] = x1;
  }
}

export class UpdateSettings extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UpdateConfluenceApiKey extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UpdateConfluenceDomain extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UpdateConfluenceUser extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ToggleConfluencePanel extends $CustomType {}

export class UpdateGoogleFolderId extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class SubmitGoogleFolderId extends $CustomType {}

export class UpdateName extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UpdateClient extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UpdateDate extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UpdateSelectedSheet extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class BackToUpload extends $CustomType {}

export class SubmitDetails extends $CustomType {}

export class SkipQualificationMatrix extends $CustomType {}

export class UseQualificationMatrix extends $CustomType {}

export class FetchXlsx extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class XlsxFetched extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class NewProposalNone extends $CustomType {}

export class ColumnTypeChanged extends $CustomType {
  constructor(x0, x1, x2) {
    super();
    this[0] = x0;
    this[1] = x1;
    this[2] = x2;
  }
}

export class InvalidLineChanged extends $CustomType {
  constructor(x0, x1, x2) {
    super();
    this[0] = x0;
    this[1] = x1;
    this[2] = x2;
  }
}

export class SubmitProposal extends $CustomType {}

export class AddCollaborator extends $CustomType {
  constructor(user_id) {
    super();
    this.user_id = user_id;
  }
}

export class RemoveCollaborator extends $CustomType {
  constructor(user_id) {
    super();
    this.user_id = user_id;
  }
}

export class ToggleCollaboratorsPopup extends $CustomType {}

export class ProposalSaved extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class AssignExpirationDate extends $CustomType {
  constructor(expiration_date) {
    super();
    this.expiration_date = expiration_date;
  }
}

export class AssignStatus extends $CustomType {
  constructor(status) {
    super();
    this.status = status;
  }
}

export class AssignTag extends $CustomType {
  constructor(tag, add_remove) {
    super();
    this.tag = tag;
    this.add_remove = add_remove;
  }
}

export class AssignUser extends $CustomType {
  constructor(user_id) {
    super();
    this.user_id = user_id;
  }
}

export class CheckSource extends $CustomType {
  constructor(id, checked) {
    super();
    this.id = id;
    this.checked = checked;
  }
}

export class CreateQuestion extends $CustomType {}

export class EditQuestion extends $CustomType {
  constructor(question_id) {
    super();
    this.question_id = question_id;
  }
}

export class GlobalCheckbox extends $CustomType {}

export class QnaErrored extends $CustomType {}

export class QnaSubmitted extends $CustomType {}

export class SelectFilter extends $CustomType {
  constructor(filter) {
    super();
    this.filter = filter;
  }
}

export class SubmitNewQna extends $CustomType {}

export class ToggleChangeExpirationDate extends $CustomType {}

export class ToggleChangeOwner extends $CustomType {}

export class ToggleChangeStatus extends $CustomType {}

export class ToggleChangeTags extends $CustomType {}

export class UpdateExpirationDateInput extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class UpdateNewQnaAnswer extends $CustomType {
  constructor(answer) {
    super();
    this.answer = answer;
  }
}

export class UpdateNewQnaQuestion extends $CustomType {
  constructor(question) {
    super();
    this.question = question;
  }
}

export class UpdateSearch extends $CustomType {
  constructor(search) {
    super();
    this.search = search;
  }
}

export class UpdateSource extends $CustomType {
  constructor(source) {
    super();
    this.source = source;
  }
}

export class UpdateSources extends $CustomType {
  constructor(sources) {
    super();
    this.sources = sources;
  }
}

export class UploadSources extends $CustomType {
  constructor(sources) {
    super();
    this.sources = sources;
  }
}
