/// <reference types="./app_config.d.mts" />
import * as $vitools from "../vitools/vitools.mjs";

export function heimdall_endpoint() {
  let $ = $vitools.is_dev();
  if ($) {
    return "http://localhost:3000/heimdall";
  } else {
    return "https://api.steerlab.ai/heimdall";
  }
}

export function loki_endpoint() {
  let $ = $vitools.is_dev();
  if ($) {
    return "http://localhost:3000/loki";
  } else {
    return "https://api.steerlab.ai/loki";
  }
}
