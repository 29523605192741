/// <reference types="./sentry.d.mts" />
import * as $string from "../gleam_stdlib/gleam/string.mjs";
import {
  captureError as do_capture_error,
  captureMessage as capture_message,
  init,
} from "./sentry.ffi.mjs";

export { capture_message, init };

export function capture(error) {
  let _pipe = error;
  let _pipe$1 = $string.inspect(_pipe);
  return do_capture_error(_pipe$1);
}

export function capture_error(error) {
  if (error.isOk()) {
    return undefined;
  } else {
    let content = error[0];
    let _pipe = content;
    let _pipe$1 = $string.inspect(_pipe);
    return do_capture_error(_pipe$1);
  }
}
