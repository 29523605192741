/// <reference types="./client.d.mts" />
import * as $promise from "../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import {
  client as create,
  state as do_state,
  loginWithRedirect as do_login_with_redirect,
  loginWithPopup as do_login_with_popup,
  buildAuthorize as do_authorize,
  handleRedirect as do_handle_redirect,
  logout as do_logout,
} from "../auth0.ffi.mjs";
import * as $options from "../auth0/client/options.mjs";
import * as $user from "../auth0/client/user.mjs";
import { CustomType as $CustomType } from "../gleam.mjs";

export { create };

export class Login extends $CustomType {}

export class Logout extends $CustomType {}

export class Authorize extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Authorized extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export function authorize(client, redirect_uri) {
  return $effect.from(
    (dispatch) => {
      let _pipe = do_authorize(client, redirect_uri);
      let _pipe$1 = $promise.map(
        _pipe,
        (var0) => { return new Authorize(var0); },
      );
      $promise.tap(_pipe$1, dispatch)
      return undefined;
    },
  );
}

function get_state(client) {
  return $promise.map(
    do_state(client),
    (content) => {
      return $option.then$(
        content,
        (content) => {
          let _pipe = $user.decode(content[1]);
          let _pipe$1 = $result.map(
            _pipe,
            (_capture) => { return $pair.new$(content[0], _capture); },
          );
          let _pipe$2 = $result.map(
            _pipe$1,
            (var0) => { return new $option.Some(var0); },
          );
          return $result.unwrap(_pipe$2, new $option.None());
        },
      );
    },
  );
}

export function init(client, msg) {
  return $effect.from(
    (dispatch) => {
      let _pipe = get_state(client);
      let _pipe$1 = $promise.map(_pipe, msg);
      $promise.tap(_pipe$1, dispatch)
      return undefined;
    },
  );
}

export function login_with_redirect(client, msg) {
  return $effect.from(
    (dispatch) => {
      let _pipe = do_login_with_redirect(client);
      let _pipe$1 = $promise.await$(_pipe, (_) => { return get_state(client); });
      let _pipe$2 = $promise.map(_pipe$1, msg);
      $promise.tap(_pipe$2, dispatch)
      return undefined;
    },
  );
}

export function login_with_popup(client, msg) {
  return $effect.from(
    (dispatch) => {
      let _pipe = do_login_with_popup(client);
      let _pipe$1 = $promise.await$(_pipe, (_) => { return get_state(client); });
      let _pipe$2 = $promise.map(_pipe$1, msg);
      $promise.tap(_pipe$2, dispatch)
      return undefined;
    },
  );
}

export function logout(client, msg) {
  return $effect.from(
    (dispatch) => {
      let _pipe = do_logout(client);
      let _pipe$1 = $promise.await$(_pipe, (_) => { return get_state(client); });
      let _pipe$2 = $promise.map(_pipe$1, msg);
      $promise.tap(_pipe$2, dispatch)
      return undefined;
    },
  );
}

export function handle_redirect(client, url, msg) {
  return $effect.from(
    (dispatch) => {
      let _pipe = do_handle_redirect(client, url);
      let _pipe$1 = $promise.await$(_pipe, (_) => { return get_state(client); });
      let _pipe$2 = $promise.map(_pipe$1, msg);
      $promise.tap(_pipe$2, dispatch)
      return undefined;
    },
  );
}
