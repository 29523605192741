/// <reference types="./minus.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function minus() {
  let content = "<g clip-path=\"url(#clip0_6710_8884)\">\n    <path d=\"M5 11.1147H19V13.1147H5V11.1147Z\" fill=\"currentColor\"/>\n    </g>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 24 24"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
