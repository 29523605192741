/// <reference types="./file_edit.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function file_edit() {
  let content = "<path d=\"M17.5 5.63079L15.8334 7.29746V3.33329H8.33335V7.49996H4.16669V16.6666H15.8334V14.3691L17.5 12.7025V17.5066C17.4998 17.7259 17.4125 17.9362 17.2574 18.0912C17.1022 18.2462 16.8918 18.3333 16.6725 18.3333H3.32752C3.21808 18.3325 3.10987 18.3102 3.00906 18.2676C2.90825 18.225 2.81681 18.163 2.73997 18.0851C2.66313 18.0072 2.60239 17.9149 2.56122 17.8135C2.52005 17.7121 2.49925 17.6036 2.50002 17.4941V6.66663L7.50252 1.66663H16.665C17.125 1.66663 17.5 2.04579 17.5 2.49329V5.63079ZM18.1484 7.33913L19.3267 8.51829L12.845 15L11.665 14.9983L11.6667 13.8216L18.1484 7.33996V7.33913Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 20 20"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
