/// <reference types="./slack.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function slack() {
  let content = "<g clip-path=\"url(#clip0_6881_3755)\">\n    <path d=\"M45.9213 71.9444C45.9213 75.6806 42.9016 78.7003 39.1654 78.7003C35.4291 78.7003 32.4095 75.6806 32.4095 71.9444C32.4095 68.2082 35.4291 65.1885 39.1654 65.1885H45.9213V71.9444ZM49.2992 71.9444C49.2992 68.2082 52.3189 65.1885 56.0551 65.1885C59.7913 65.1885 62.811 68.2082 62.811 71.9444V88.8341C62.811 92.5704 59.7913 95.59 56.0551 95.59C52.3189 95.59 49.2992 92.5704 49.2992 88.8341V71.9444Z\" fill=\"#E01E5A\"/>\n    <path d=\"M56.055 44.8194C52.3188 44.8194 49.2991 41.7997 49.2991 38.0635C49.2991 34.3273 52.3188 31.3076 56.055 31.3076C59.7913 31.3076 62.8109 34.3273 62.8109 38.0635V44.8194H56.055ZM56.055 48.2486C59.7913 48.2486 62.8109 51.2682 62.8109 55.0045C62.8109 58.7407 59.7913 61.7604 56.055 61.7604H39.1141C35.3779 61.7604 32.3582 58.7407 32.3582 55.0045C32.3582 51.2682 35.3779 48.2486 39.1141 48.2486H56.055Z\" fill=\"#36C5F0\"/>\n    <path d=\"M83.1299 55.0045C83.1299 51.2682 86.1496 48.2486 89.8859 48.2486C93.6221 48.2486 96.6418 51.2682 96.6418 55.0045C96.6418 58.7407 93.6221 61.7604 89.8859 61.7604H83.1299V55.0045ZM79.752 55.0045C79.752 58.7407 76.7323 61.7604 72.9961 61.7604C69.2599 61.7604 66.2402 58.7407 66.2402 55.0045V38.0635C66.2402 34.3273 69.2599 31.3076 72.9961 31.3076C76.7323 31.3076 79.752 34.3273 79.752 38.0635V55.0045Z\" fill=\"#2EB67D\"/>\n    <path d=\"M72.9961 82.0782C76.7323 82.0782 79.752 85.0979 79.752 88.8341C79.752 92.5704 76.7323 95.59 72.9961 95.59C69.2599 95.59 66.2402 92.5704 66.2402 88.8341V82.0782H72.9961ZM72.9961 78.7003C69.2599 78.7003 66.2402 75.6806 66.2402 71.9444C66.2402 68.2082 69.2599 65.1885 72.9961 65.1885H89.937C93.6733 65.1885 96.6929 68.2082 96.6929 71.9444C96.6929 75.6806 93.6733 78.7003 89.937 78.7003H72.9961Z\" fill=\"#ECB22E\"/>\n    </g>\n    <defs>\n    <clipPath id=\"clip0_6881_3755\">\n    <rect width=\"65\" height=\"65\" fill=\"white\" transform=\"translate(32 31)\"/>\n    </clipPath>\n    </defs>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 128 128"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
