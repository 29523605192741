/// <reference types="./proposals.d.mts" />
import * as $element from "../../lustre/lustre/element.mjs";
import * as $model from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";
import * as $route from "../data/route.mjs";
import { makeError } from "../gleam.mjs";
import * as $add from "../view/proposals/add.mjs";
import * as $overview from "../view/proposals/overview.mjs";
import * as $show from "../view/proposals/show.mjs";

export function view(model) {
  let $ = model.route;
  if (!($ instanceof $route.Proposals)) {
    throw makeError(
      "assignment_no_match",
      "view/proposals",
      10,
      "view",
      "Assignment pattern did not match",
      { value: $ }
    )
  }
  let route = $[0];
  if (route instanceof $route.OnGoing) {
    return $overview.view(model);
  } else if (route instanceof $route.Previous) {
    return $overview.view(model);
  } else if (route instanceof $route.Show) {
    let id = route.id;
    let sheet = route.sheet;
    return $show.view(model, id, sheet);
  } else {
    let loading = route.loading;
    return $element.map(
      $add.view(model, loading),
      (var0) => { return new $msg.OnNewProposal(var0); },
    );
  }
}
