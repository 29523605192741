/// <reference types="./copilot_input.d.mts" />
import { CustomType as $CustomType } from "../../gleam.mjs";

export class CopilotInput extends $CustomType {
  constructor(value, loading) {
    super();
    this.value = value;
    this.loading = loading;
  }
}

export function new$() {
  return new CopilotInput("", false);
}
