/// <reference types="./label.d.mts" />
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $el from "../../../sketch/sketch/lustre/element.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { px } from "../../../sketch/sketch/size.mjs";
import * as $colors from "../../design_system/colors.mjs";
import * as $text from "../../design_system/typography/text.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

export class Success extends $CustomType {}

export class Error extends $CustomType {}

export class Current extends $CustomType {}

export class Neutral extends $CustomType {}

function status_to_colors(status) {
  if (status instanceof Success) {
    return [$colors.light.success_100, $colors.light.success_800];
  } else if (status instanceof Error) {
    return [$colors.light.error_100, $colors.light.error_800];
  } else if (status instanceof Neutral) {
    return [$colors.light.grey_200, $colors.light.grey_800];
  } else {
    return [$colors.light.blue_100, $colors.light.blue_800];
  }
}

function label_class() {
  let _pipe = $s.class$(
    toList([
      $s.padding_("4px 12px"),
      $s.border_radius(px(4)),
      $s.compose($text.sm($text.medium)),
      $s.text_align("center"),
      $s.white_space("nowrap"),
    ]),
  );
  return $s.memo(_pipe);
}

export function label(status, content) {
  let id = "label-" + $string.inspect(status);
  let $ = status_to_colors(status);
  let background = $[0];
  let color = $[1];
  return $el.memo_dynamic(
    "div",
    toList([]),
    toList([$h.text(content)]),
    id,
    toList([
      $s.compose(label_class()),
      $s.background(background),
      $s.color(color),
    ]),
  );
}

export function clickable_label(status, on_click, content) {
  let id = "clickable-label-" + $string.inspect(status);
  let $ = status_to_colors(status);
  let background = $[0];
  let color = $[1];
  return $el.memo_dynamic(
    "div",
    toList([$e.on_click(on_click)]),
    toList([$h.text(content)]),
    id,
    toList([
      $s.compose(label_class()),
      $s.flex("1"),
      $s.background(background),
      $s.color(color),
      $s.cursor("pointer"),
      $s.border("1px solid transparent"),
      $s.transition("border-color .3s"),
      $s.hover(toList([$s.property("border-color", color)])),
    ]),
  );
}
