/// <reference types="./user.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function user() {
  let content = "<g clip-path=\"url(#clip0_7773_4042)\">\n    <path d=\"M3.3335 18.3335C3.3335 16.5654 4.03588 14.8697 5.28612 13.6195C6.53636 12.3692 8.23205 11.6668 10.0002 11.6668C11.7683 11.6668 13.464 12.3692 14.7142 13.6195C15.9645 14.8697 16.6668 16.5654 16.6668 18.3335H15.0002C15.0002 17.0074 14.4734 15.7356 13.5357 14.798C12.598 13.8603 11.3262 13.3335 10.0002 13.3335C8.67408 13.3335 7.40231 13.8603 6.46463 14.798C5.52695 15.7356 5.00016 17.0074 5.00016 18.3335H3.3335ZM10.0002 10.8335C7.23766 10.8335 5.00016 8.596 5.00016 5.8335C5.00016 3.071 7.23766 0.833496 10.0002 0.833496C12.7627 0.833496 15.0002 3.071 15.0002 5.8335C15.0002 8.596 12.7627 10.8335 10.0002 10.8335ZM10.0002 9.16683C11.8418 9.16683 13.3335 7.67516 13.3335 5.8335C13.3335 3.99183 11.8418 2.50016 10.0002 2.50016C8.1585 2.50016 6.66683 3.99183 6.66683 5.8335C6.66683 7.67516 8.1585 9.16683 10.0002 9.16683Z\" fill=\"currentColor\"/>\n    </g>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 20 20"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
