/// <reference types="./element.d.mts" />
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $s from "../../sketch.mjs";

export function element(tag, attributes, children, styles) {
  let _pipe = $s.class$(styles);
  let _pipe$1 = $s.to_lustre(_pipe);
  let _pipe$2 = ((_capture) => { return $list.prepend(attributes, _capture); })(
    _pipe$1,
  );
  return ((_capture) => { return $element.element(tag, _capture, children); })(
    _pipe$2,
  );
}

export function memo(tag, attributes, children, styles) {
  let _pipe = $s.class$(styles);
  let _pipe$1 = $s.memo(_pipe);
  let _pipe$2 = $s.to_lustre(_pipe$1);
  let _pipe$3 = ((_capture) => { return $list.prepend(attributes, _capture); })(
    _pipe$2,
  );
  return ((_capture) => { return $element.element(tag, _capture, children); })(
    _pipe$3,
  );
}

export function dynamic(tag, attributes, children, id, styles) {
  let _pipe = $s.dynamic(id, styles);
  let _pipe$1 = $s.to_lustre(_pipe);
  let _pipe$2 = ((_capture) => { return $list.prepend(attributes, _capture); })(
    _pipe$1,
  );
  return ((_capture) => { return $element.element(tag, _capture, children); })(
    _pipe$2,
  );
}

export function memo_dynamic(tag, attributes, children, id, styles) {
  let _pipe = $s.dynamic(id, styles);
  let _pipe$1 = $s.to_lustre(_pipe);
  let _pipe$2 = ((_capture) => { return $list.prepend(attributes, _capture); })(
    _pipe$1,
  );
  return ((_capture) => { return $element.element(tag, _capture, children); })(
    _pipe$2,
  );
}
