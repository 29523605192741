/// <reference types="./effects.d.mts" />
import * as $promise from "../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $model from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";

export function require_access_token(model, next) {
  let $ = model.access_token;
  if ($ instanceof $option.Some) {
    let access_token = $[0];
    return next(access_token);
  } else {
    return $effect.none();
  }
}

export function require_org_id(model, next) {
  let $ = (() => {
    let _pipe = model.user;
    return $option.then$(_pipe, (u) => { return u.org_id; });
  })();
  if ($ instanceof $option.Some) {
    let org_id = $[0];
    return next(org_id);
  } else {
    return $effect.none();
  }
}

export function require_user_id(model, next) {
  let $ = (() => {
    let _pipe = model.user;
    return $option.map(_pipe, (u) => { return u.sub; });
  })();
  if ($ instanceof $option.Some) {
    let user_id = $[0];
    return next(user_id);
  } else {
    return $effect.none();
  }
}

export function from_promise(promise, next) {
  return $effect.from(
    (dispatch) => {
      let _pipe = promise;
      $promise.map(_pipe, (a) => { return next(dispatch, a); })
      return undefined;
    },
  );
}
