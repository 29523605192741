/// <reference types="./more.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function more() {
  let content = "<path d=\"M12 3.11475C10.9 3.11475 10 4.01475 10 5.11475C10 6.21475 10.9 7.11475 12 7.11475C13.1 7.11475 14 6.21475 14 5.11475C14 4.01475 13.1 3.11475 12 3.11475ZM12 17.1147C10.9 17.1147 10 18.0147 10 19.1147C10 20.2147 10.9 21.1147 12 21.1147C13.1 21.1147 14 20.2147 14 19.1147C14 18.0147 13.1 17.1147 12 17.1147ZM12 10.1147C10.9 10.1147 10 11.0147 10 12.1147C10 13.2147 10.9 14.1147 12 14.1147C13.1 14.1147 14 13.2147 14 12.1147C14 11.0147 13.1 10.1147 12 10.1147Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 24 25"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
